import React, { useState, useEffect } from 'react';
import useAuth from '../../../hooks/useAuth';
import { Storage } from 'aws-amplify';
import { Card, Form, Button, Input, Upload, Select } from 'antd';
import { SearchOutlined, UploadOutlined } from '@ant-design/icons';
import dtService from '../../../services/dtService';

export default function FormEnviarNotificaciones(record) {
  const { empresaID } = useAuth();
  const [form] = Form.useForm();
  const [buttonLoading, setButtonLoading] = useState(false);
  const [loadingSelect, setLoadingSelect] = useState(true);
  const [documento, setDocumento] = useState(null);
  const [carga, setCarga] = useState(null);
  const [disableInput, setDisableInput] = useState(false);
  const [trabajadores, setTrabajadores] = useState([]);

  const { TextArea } = Input;
  const resetFilters = () => {
    form.resetFields();
  };

  const handleSubmit = async (values) => {
    setButtonLoading(true);

    console.log(values);
    const file = values.documentoNotificacion[0].originFileObj;
    const oferta = `Chile#${values.oferta}`;

    try {
      if (file.size > 10485760) {
        throw new Error('El archivo no puede ser mayor a 10MB');
      }

      if (file.type !== 'application/pdf') {
        throw new Error('El Archivo debe ser =pdf');
      }

      const path = `Trabajador/${values.trabajador}/${
        empresaID.empresaId
      }/${oferta}/${values.tipoDocumento}#${oferta}#${values.trabajador}.${
        file.name.split('.')[1]
      }`;

      console.log(path);

      /*  const { key } = await Storage.put(path, file, {
        contentType: file.type,
      }); */

      //  console.log(key);
      setButtonLoading(false);
    } catch (error) {
      console.error(error);
      setButtonLoading(false);
    }
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  return (
    <Card
      title="Ingrese los datos de la notificación"
      extra={<Button onClick={() => resetFilters()}>Limpiar buscador</Button>}
      style={{ marginBottom: '10px' }}>
      <Form
        initialValues={{
          correoTrabajador: record.trabajador.correo,
          nombreTrabajador: record.trabajador.nombre,
          rutTrabajador: record.trabajador.rut,
        }}
        form={form}
        layout="horizontal"
        requiredMark={false}
        onFinish={handleSubmit}
        labelCol={{
          span: 5,
        }}
        wrapperCol={{
          span: 19,
        }}>
        <Card
          title={<strong>Formulario de Notificación a Trabajador</strong>}
          headStyle={{ textAlign: 'center' }}
          style={{ marginBottom: '20px' }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}>
            <Form.Item
              name="nombreTrabajador"
              id="nombreTrabajador"
              label="Nombre del trabajador"
              style={{ width: '80%' }}
              /* rules={[
                {
                  required: true,
                },
              ]} */
            >
              <Input
                style={{ width: '80%' }}
                id="nombreTrabajador"
                name="nombreTrabajador"
                type="text"
                disabled
                placeholder={record.trabajador.nombre}></Input>
            </Form.Item>
            <Form.Item
              name="correoTrabajador"
              id="correoTrabajador"
              label="Correo del trabajador"
              style={{ width: '80%' }}
              /*  rules={[
                {
                  required: true,
                },
              ]} */
            >
              <Input
                style={{ width: '80%' }}
                id="correoTrabajador"
                name="correoTrabajador"
                type="text"
                disabled
                value={record.trabajador.correo}
                placeholder={record.trabajador.correo}></Input>
            </Form.Item>
            <Form.Item
              name="rutTrabajador"
              id="rutTrabajador"
              label="Rut del trabajador"
              style={{ width: '80%' }}
              /*  rules={[
                {
                  required: true,
                },
              ]} */
            >
              <Input
                style={{ width: '80%' }}
                id="rutTrabajador"
                name="rutTrabajador"
                type="text"
                disabled
                value={record.trabajador.rut}
                placeholder={record.trabajador.rut}></Input>
            </Form.Item>
            {/*    <Form.Item
              name="trabajador"
              label="Elegir a un trabajador"
              style={{ width: '80%' }}
              rules={[
                {
                  required: true,
                  message: 'Por favor, seleccione un trabajador',
                },
              ]}>
              <Select
                style={{ width: '80%' }}
                showSearch
                placeholder="Seleccione al trabajador"
                loading={loadingSelect}
                allowClear={true}
                // onChange={handleSelectChange}
                filterOption={(input, option) =>
                  (option?.label ?? '')
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                options={trabajadores.map((trabajador) => ({
                  label: trabajador.nombre,
                  value: trabajador.correo,
                  name: 'trabajador',
                }))}
              />
            </Form.Item> */}
            <Form.Item
              name="tituloNotificacion"
              id="tituloNotificacion"
              label="Titulo de la Notificación"
              style={{ width: '80%' }}
              rules={[
                {
                  required: true,
                  message: 'Por favor, agregue un titulo a la notificación',
                },
              ]}>
              <Input
                style={{ width: '80%' }}
                id="tituloNotificacion"
                name="tituloNotificacion"
                type="text"
                placeholder="Ingrese el titulo de la notificación"
              />
            </Form.Item>
            <Form.Item
              name="mensajeNotificacion"
              id="mensajeNotificacion"
              label="Mensaje de la Notificación"
              style={{ width: '80%' }}
              rules={[
                {
                  required: true,
                  message: 'Por favor, agregue un mensaje a la notificación',
                },
              ]}>
              <TextArea
                style={{ width: '80%' }}
                id="mensajeNotificacion"
                name="mensajeNotificacion"
                type="text"
                placeholder="Escribe aqui el mensaje de la notificación"
              />
            </Form.Item>
            <Form.Item
              name="oferta"
              id="oferta"
              label="Nro Oferta"
              style={{ width: '80%' }}
              rules={[
                {
                  required: true,
                  message: 'Por favor, ingrese el número de oferta',
                },
              ]}>
              <Input
                style={{ width: '20%' }}
                id="oferta"
                name="oferta"
                type="number"
                placeholder="Ingrese el número de oferta"
              />
            </Form.Item>
            <Form.Item
              name="tipoDocumento"
              id="tipoDocumento"
              label="Tipo de Documento"
              style={{ width: '80%' }}
              rules={[
                {
                  required: true,
                  message: 'Por favor, seleccione un tipo de documento',
                },
              ]}>
              <Select
                name="tipoDocumento"
                id="tipoDocumento"
                style={{ width: '80%' }}
                showSearch
                placeholder="Seleccione un tipo de documento"
                allowClear={true}
                // onChange={handleSelectChange}
              >
                <Select.Option value="anexo">Anexos</Select.Option>
                <Select.Option value="pactoHoraExtra">
                  Pacto Horas Extra
                </Select.Option>
              </Select>
            </Form.Item>
            <Form.Item
              name="documentoNotificacion"
              id="documentoNotificacion"
              label="Adjuntar Documento"
              style={{ width: '80%' }}
              valuePropName="fileList" // Para manejar el archivo como un array
              getValueFromEvent={normFile} // Normaliza el archivo antes de enviarlo
              rules={[
                {
                  required: true,
                  message: 'Por favor, adjunte un documento',
                },
              ]}>
              <Upload beforeUpload={() => false} maxCount={1}>
                {/* maxCount={1} asegura que solo se pueda seleccionar un archivo */}
                <Button icon={<UploadOutlined />}>Seleccionar archivo</Button>
              </Upload>
            </Form.Item>
          </div>
        </Card>
        <div className="flex justify-end">
          <Button
            block
            style={{ marginBottom: '20px' }}
            type="primary"
            htmlType="submit"
            className="mr-2"
            icon={<SearchOutlined />}
            loading={buttonLoading}>
            Enviar Notificación
          </Button>
        </div>
      </Form>
    </Card>
  );
}
