import React, { useState, useEffect } from 'react';
import { Divider, Table } from 'antd';
import BuscadorReportes from '../../components/buscador/BuscadorReportes';
import TablaReporteFestivos from './components/TablaReporteFestivos';
import TablaFestivosInfo from './components/TablaFestivosInfo';
import BotonExportarPdf from '../../components/botones/BotonExportarPdf';
import BotonExportarWord from '../../components/botones/BotonExportarWord';
import BotonExportarExcel from '../../components/botones/BotonExportarExcel';

import TablaTrabajadorNoTieneFestivos from './components/TablaTrabajadorNoTieneFestivos';
import TablaNoExisteReporteFestivos from './components/TablaNoExisteReporteFestivos';
import GlosarioCardDomingosFestivos from './components/GlosarioCardDomingosFestivos';
// import FormatoReporteFestivos from "../../components/botones/components/FormatoReporteFestivos";
import HeaderReportes from '../../components/headers/HeaderReportes';
import TrabajadoresReportesColumns from '../../components/tablas/TrabajadoresReportesColumns';
import Labels from '../../utils/Labels.json';

export default function DomigosFestivos() {
  const [firstTable, setFirstTable] = useState([]);
  const [exportarWord, setexportarWord] = useState(false);
  const [exportarPdf, setexportarPdf] = useState(false);
  const [currentDate, setCurrentDate] = useState('');
  const [exportarExcel, setexportarExcel] = useState(false);
  const [jobsAreLoading, setJobsAreLoading] = useState(true);
  const [DatosSeleccionados, setDatosSeleccionados] = useState([]);
  const [reporteVisible, setReporteVisible] = useState(false);
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setDatosSeleccionados(selectedRows);
      setReporteVisible(true);
    },
  };

  useEffect(() => {
    let date = new Date();

    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();

    if (month < 10) {
      setCurrentDate(`${year}-0${month}-${day}`);
    } else {
      setCurrentDate(`${year}-${month}-${day}`);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <HeaderReportes
        name="Reporte de Domingos y/o Festivos"
        setexportarExcel={setexportarExcel}
        setexportarPdf={setexportarPdf}
        setexportarWord={setexportarWord}
        firstTable={DatosSeleccionados}
        DatosSeleccionados={DatosSeleccionados}
      />

      <BuscadorReportes
        setFirstTable={setFirstTable}
        tipoBuscador="festivos"
        jobsAreLoading={jobsAreLoading}
        setJobsAreLoading={setJobsAreLoading}
        setexportarExcel={setexportarExcel}
        setexportarPdf={setexportarPdf}
        setexportarWord={setexportarWord}
        setDatosSeleccionados={setDatosSeleccionados}
      />

      {exportarExcel && (
        <BotonExportarExcel
          dataArray={DatosSeleccionados}
          formato="festivos"
          name={`Reporte Domingos y festivos_${currentDate}`}
          setexportarExcel={setexportarExcel}
        />
      )}

      {exportarWord && (
        <BotonExportarWord
          dataArray={DatosSeleccionados}
          formato="festivos"
          name={`Reporte Domingos y festivos_${currentDate}`}
          setexportarWord={setexportarWord}
        />
      )}

      {exportarPdf && (
        <BotonExportarPdf
          dataArray={DatosSeleccionados}
          formato="festivos"
          name={`Reporte Domingos y festivos__${currentDate}`}
          setexportarpdf={setexportarPdf}
        />
      )}

      {jobsAreLoading ? (
        <div />
      ) : firstTable.length === 0 ? (
        <div className="mx-auto mt-1" style={{ maxWidth: '70rem' }}>
          <TablaNoExisteReporteFestivos />
        </div>
      ) : (
        <>
          <Table
            rowSelection={{
              type: 'checkbox',
              ...rowSelection,
            }}
            rowKey="rut"
            pagination={{ showSizeChanger: true }}
            locale={Labels['tableLabels']}
            dataSource={firstTable}
            columns={TrabajadoresReportesColumns(firstTable)}
            scroll={{ x: 'max-content' }}
          />
          {reporteVisible && DatosSeleccionados.length > 0 ? (
            <div>
              <div
                style={{
                  marginBottom: '10px',
                  display: 'flex',
                  justifyContent: 'center',
                }}>
                <GlosarioCardDomingosFestivos />
              </div>
              {DatosSeleccionados.map((record) => {
                return (
                  <div key={record.key} className="mx-auto mt-6">
                    <TablaFestivosInfo items={record} />

                    <div>
                      {record.reporte.length === 0 && (
                        <div
                          className="mx-auto mt-1"
                          style={{ maxWidth: '70rem' }}>
                          <TablaTrabajadorNoTieneFestivos />
                          <Divider />
                        </div>
                      )}

                      {record.reporte.length !== 0 && (
                        <div
                          className="mx-auto mt-1"
                          style={{ maxWidth: '70rem' }}>
                          <TablaReporteFestivos
                            items={record.reporte || []}
                            desde={firstTable.desde}
                            hasta={firstTable.hasta}
                          />
                          <Divider />
                        </div>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          ) : null}
        </>
      )}
    </div>
  );
}
