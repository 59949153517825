import React from 'react';
import FormatDateV2 from '../../../utils/FormatDateV2';
import dayjs from 'dayjs';
import TablainfoPersonas from './atoms/TablainfoPersonas';
import TablaGlosarioAsistencia from './atoms/TablaGlosarioAsistencia';
import styles from './jsonStyles.json';
import logoApp from '../../../assets/Xinerlink.png';

export default function FormatoReportesAsistencias({ dataArray }) {
  return (
    <div
      className="table-responsive"
      style={styles.tableReponsive}
      id="tablaPrincipal">
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <picture>
          <img
            src={logoApp}
            alt="logo App"
            style={{ maxWidth: '200px', height: 'auto', marginRight: '20px' }}
          />
        </picture>
        <br />
      </div>
      <h3 style={{ font: ' 8pt Arial' }}>
        <strong>Reporte de Asistencia</strong>
      </h3>
      <br />
      <div className="table-responsive">
        <TablaGlosarioAsistencia />
      </div>
      <br />

      {dataArray.map((item) => (
        <div id={item.key} key={item.key} className="table-responsive">
          <br />
          <br />
          <TablainfoPersonas item={item}></TablainfoPersonas>

          <table style={styles.table}>
            <thead>
              <tr
                style={{
                  backgroundColor: 'white',
                  color: 'blue',
                  height: '50px',
                  border: '1px solid black',
                }}>
                <th style={styles.td2}>Fecha</th>
                <th style={styles.td2}>Asistencia</th>
                <th style={styles.td2}>Ausencia</th>
                <th style={styles.td2}>Observaciones</th>
              </tr>
            </thead>
            <tbody>
              {item.reporte.map((task) => (
                <tr
                  key={task.jornada}
                  style={{
                    border: '1px solid black',
                    height: '50px',
                    padding: '2px',
                    font: ' 8pt Arial',
                  }}>
                  <td style={task.observaciones ? styles.tdRed : styles.td2}>
                    {FormatDateV2(task.key).split('T')[0] || ''}
                  </td>
                  <td style={task.observaciones ? styles.tdRed : styles.td2}>
                    {task.asistencia || ''}
                  </td>
                  <td style={task.observaciones ? styles.tdRed : styles.td2}>
                    {' '}
                    {task.ausencia || ''}
                  </td>
                  <td style={task.observaciones ? styles.tdRed : styles.td2}>
                    {task.observaciones || ''}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <hr />
        </div>
      ))}
    </div>
  );
}
