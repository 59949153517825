import React, { useEffect, useState } from "react";
import { Select } from "antd";
import dataHoraFin from "./components/dataHoraFin.json";
import dataHoraInicio from "./components/dataHoraInicio.json";
import dayjs from "dayjs";

export default function SelectorHoras({
    record,
    tipo = "entradaas",
    setValueHora,
}) {
    const [options, setOptions] = useState([]);

    const AjusteArrayFechaInicio = () => {
        let arrayHoras = [];

        if (tipo === "entrada") {
            for (var i = 0; i < dataHoraInicio.length; i++) {
                const horaMarcada = validarHoraEntrada();
                const newElem = dataHoraInicio[i];
                arrayHoras.push(newElem);
                if (dataHoraInicio[i].value === horaMarcada) {
                    break;
                }
            }
        } else {
            const horaMarcada = validarSalida();
            const pos = dataHoraFin.map((e) => e.value).indexOf(horaMarcada);
            if (pos === -1) {
                for (var x = 0; x < dataHoraFin.length; x++) {
                    const newElem = dataHoraFin[x];
                    arrayHoras.push(newElem);
                }
            } else {
                for (var y = pos; y < dataHoraFin.length; y++) {
                    const newElem = dataHoraFin[y];
                    arrayHoras.push(newElem);
                }
            }
        }

        setOptions(arrayHoras);
    };

    const validarHoraEntrada = () => {
        // console.log(`selected ${value}`);
        let dataHora = "00:00";
        if (record.fechaEntradaTrabajador === "") {
            dataHora = dayjs(`${record.entrada}Z`).format("HH:mm");
        } else {
            dataHora = redondearHora(
                dayjs(record.fechaEntradaTrabajador).format("HH:mm"),
                "entrada"
            );
        }
        // console.log(dataHora);
        return dataHora.trim();
    };

    const validarSalida = () => {
        // console.log(`selected ${value}`);
        let dataHora = "00:00";
        if (record.fechaSalidaTrabajador === "") {
            dataHora = dayjs(`${record.salida}Z`).format("HH:mm");
        } else {
            dataHora = redondearHora(
                dayjs(record.fechaSalidaTrabajador).format("HH:mm"),
                "salida"
            );
        }
        return dataHora.trim();
    };

    const onChange = (value) => {
        // console.log(`selected ${value}`);
        setValueHora(value);
    };
    const onSearch = (value) => {
        console.log("search:", value);
    };

    useEffect(() => {
        AjusteArrayFechaInicio();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const redondearHora = (hora, tipo) => {
        var partesHora = hora.split(":");
        var horas = parseInt(partesHora[0]);
        var minutos = parseInt(partesHora[1]);

        // Redondear los minutos según el tipo de hora (entrada o salida)
        if (tipo === "entrada") {
            minutos = Math.floor(minutos / 15) * 15;
        } else if (tipo === "salida") {
            minutos = Math.ceil(minutos / 15) * 15;
        }

        // Si los minutos son 60, avanzar a la siguiente hora y establecer los minutos en 0
        if (minutos === 60) {
            horas += 1;
            minutos = 0;
        }

        // Formatear la hora y los minutos con ceros a la izquierda si es necesario
        var horaRedondeada = horas.toString().padStart(2, "0");
        var minutosRedondeados = minutos.toString().padStart(2, "0");

        // Devolver la hora redondeada
        return horaRedondeada + ":" + minutosRedondeados;
    };

    return (
        <Select
            showSearch
            placeholder="Selecciona una hora"
            optionFilterProp="children"
            onChange={onChange}
            onSearch={onSearch}
            filterOption={(input, option) =>
                (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            options={options}
        />
    );
}
