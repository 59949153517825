import React from "react";
import fechasUtils from "../../../../utils/fechasUtils";
import FormatDateV2 from "../../../../utils/FormatDateV2";
import styles from "../jsonStyles.json";

export default function TablaReporteFestivosAgrupadosAnioMes({ reporte }) {

    const agrupadosAnioMes = fechasUtils.agruparPorAniosYMeses(reporte);


    return (
        Object.keys(agrupadosAnioMes).map((anio) =>
            Object.keys(agrupadosAnioMes[anio]).map((mes) => (
                <>
                    {agrupadosAnioMes[anio][mes].map((report) => (
                        <tr
                            key={`${anio}-${mes}-${report.beneficiario}`}
                            style={{
                                border: "1px solid",
                                height: "50px",
                                font: "11pt Arial",
                            }}
                        >
                            <td style={report.observaciones ? styles.tdRed : styles.td2}>{report.beneficiario}</td>
                            <td style={report.observaciones ? styles.tdRed : styles.td2}>
                                {FormatDateV2(report.fecha).split("T")[0] || ""}
                            </td>
                            <td style={report.observaciones ? styles.tdRed : styles.td2}>{report.asistencia}</td>
                            <td style={report.observaciones ? styles.tdRed : styles.td2}>{report.ausencia}</td>
                            <td style={report.observaciones ? styles.tdRed : styles.td2}>{report.observaciones}</td>
                        </tr>


                    ))}
                    < tr
                        key={`summary-${anio}-${mes}`}
                        style={{
                            border: "1px solid",
                            height: "50px",
                            font: "11pt Arial",
                        }
                        }
                    >
                        <td colSpan={2} style={styles.td2}>
                            Total domingos y festivos laborados del {fechasUtils.obtenerPrimerDiaDelMes(anio, mes)} al{' '}
                            {fechasUtils.obtenerUltimoDiaDelMes(anio, mes)}:
                        </td>
                        <td colSpan={3} style={styles.td2}>
                            {agrupadosAnioMes[anio][mes].length} Domingos y/o Festivos
                        </td>
                    </tr >

                </>
            ))
        )
    );
}



