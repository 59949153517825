import React from "react";
import dayjs from "dayjs";
import FormatDateV2 from "../../../utils/FormatDateV2";
import styles from "./jsonStyles.json";
import TablainfoPersonas from "./atoms/TablainfoPersonas";
import logoApp from '../../../assets/Xinerlink.png';


export default function FormatoReporteModificaciones({
  dataArray,
  widthTable = "800px",
}) {
  return (
    <div style={styles.tableReponsive}>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <picture>
          <img
            src={logoApp}
            alt="logo App"
            style={{ maxWidth: "200px", height: "auto", marginRight: "20px" }}
          />
        </picture>
        <br />
      </div>
      <h3 style={{ font: " 11pt Arial" }}>
        {" "}
        <strong>Reporte de Modificación de Asistencias</strong>
      </h3>
      <br />
      <p style={{ font: "11pt Arial", textAlign: "start" }}>Periodo consultado: {dayjs(dataArray.desde).format("DD-MM-YY")} al {dayjs(dataArray.hasta).format("DD-MM-YY")}</p>
      {dataArray.map((item) => (
        <div
          id={item.key}
          key={item.key}
          className="table-responsive"
        // style={{ margin: "20px" }}
        >
          <br />
          <br />
          <TablainfoPersonas item={item}></TablainfoPersonas>

          <table style={styles.table}>
            <tr
              style={{
                backgroundColor: "limeGreen",
                color: "black",
                border: "1px solid",
                height: "50px",
              }}
            >
              <th style={styles.td2}>Fecha Asignacion de Turno</th>
              <th style={styles.td2}>Hora turno</th>
              <th style={styles.td2}>Frecuencia (Tipo de turno)</th>
              <th style={styles.td2}>Fecha Asignacion de Nuevo turno</th>
              <th style={styles.td2}>Horario Nuevo Turno</th>
              <th style={styles.td2}>Solicitado</th>
            </tr>
            {item.reporte.map((task) => (
              <tr
                key={task.jornada}
                style={{
                  border: "1px solid",
                  height: "50px",
                  padding: "2px",
                }}
              >
                <td style={styles.td2}>
                  {FormatDateV2(task.fechaAsignacion).split("T")[0]}
                </td>
                <td style={styles.td2}>
                  <div className="aling-center">
                    <p>
                      {dayjs(`${task.jornadaAsignacion.entrada}Z`).format(
                        "HH:mm"
                      )}{" "}
                      -{" "}
                      {dayjs(`${task.jornadaAsignacion.salida}Z`).format(
                        "HH:mm"
                      )}
                    </p>
                  </div>
                </td>
                <td style={styles.td2}>{task.frecuencia || ""}</td>
                <td style={styles.td2}>
                  {FormatDateV2(task.fechaNuevaAsignacion).split("T")[0]}
                </td>
                <td style={styles.td2}>
                  <div className="aling-center">
                    <p>
                      {dayjs(`${task.jornadaNuevaAsignacion.entrada}Z`).format(
                        "HH:mm"
                      )}{" "}
                      -{" "}
                      {dayjs(`${task.jornadaNuevaAsignacion.salida}Z`).format(
                        "HH:mm"
                      )}
                    </p>
                  </div>
                </td>
                <td style={styles.td2}>{task.solicitado || ""}</td>
              </tr>
            ))}
          </table>
        </div>
      ))}
    </div>
  );
}
