import React from 'react';
import { Table, Space } from 'antd';
import dayjs from 'dayjs';
import FormatDateV2 from '../../../utils/FormatDateV2';

export default function TablaReporteModificaciones({ data = [] }) {
  const columns = [
    {
      title: <span style={{ color: 'blue' }}>Fecha Asignacion Turno</span>,
      dataIndex: 'fechaAsignacion',
      key: 'fechaAsignacion',
      width: 100,
      render: (text) => {
        return <Space>{FormatDateV2(text).split('T')[0]}</Space>;
      },
    },
    {
      title: <span style={{ color: 'blue' }}>Turno Asignado</span>,
      dataIndex: 'jornadaAsignacion',
      key: 'jornadaAsignacion',
      width: 100,
      render: (text) => {
        return (
          <Space>
            <div className="aling-center">
              <p>
                {dayjs(`${text.entrada}Z`).format('HH:mm')} -{' '}
                {dayjs(`${text.salida}Z`).format('HH:mm')}
              </p>
            </div>
          </Space>
        );
      },
    },
    {
      title: <span style={{ color: 'blue' }}>Extensión del turno</span>,
      dataIndex: 'frecuencia',
      key: 'frecuencia',
      width: 100,
    },
    {
      title: (
        <span style={{ color: 'blue' }}>Fecha Asignacion Nuevo Turno</span>
      ),
      dataIndex: 'fechaSolicitud',
      key: 'fechaSolicitud',
      width: 100,
      render: (text) => {
        return <Space>{FormatDateV2(text).split('T')[0]}</Space>;
      },
    },

    {
      title: <span style={{ color: 'blue' }}>Fecha de Nuevo Turno</span>,
      dataIndex: 'fechaNuevaAsignacion',
      key: 'fechaNuevaAsignacion',
      width: 100,
      render: (text) => {
        return <Space>{FormatDateV2(text).split('T')[0]}</Space>;
      },
    },
    {
      title: <span style={{ color: 'blue' }}>Nuevo Turno Asignado</span>,
      dataIndex: 'jornadaNuevaAsignacion',
      key: 'jornadaNuevaAsignacion',
      width: 100,
      render: (text) => {
        return (
          <Space>
            <div className="aling-center">
              <p>
                {dayjs(`${text.entrada}Z`).format('HH:mm')} -{' '}
                {dayjs(`${text.salida}Z`).format('HH:mm')}
              </p>
            </div>
          </Space>
        );
      },
    },
    {
      title: <span style={{ color: 'blue' }}>Extensión del Nuevo Turno</span>,
      dataIndex: 'frecuencia',
      key: 'frecuencia',
      width: 100,
    },
    {
      title: <span style={{ color: 'blue' }}>Solicitado</span>,
      dataIndex: 'solicitado',
      key: 'solicitado',
      width: 100,
      render: (text) => {
        return <Space>{text}</Space>;
      },
    },
    {
      title: <span style={{ color: 'blue' }}>Observaciones</span>,
      dataIndex: 'observaciones',
      key: 'observaciones',
      width: 100,
      render: (text) => {
        return <Space>{text}</Space>;
      },
    },
  ];

  return (
    <div>
      <Table columns={columns} dataSource={data} bordered pagination={false} />
    </div>
  );
}
