import React from 'react';
import styles from '../jsonStyles.json';
export default function TablaGlosarioJornada() {
  return (
    <div /* style={{ display: 'flex', justifyContent: 'center' }} */>
      <table style={{ ...styles.table, marginBottom: '20px' }}>
        <thead
          style={{
            backgroundColor: 'white',
            color: 'blue',
            height: '50px',
            border: '1px solid #ddd',
          }}>
          <tr>
            <th colSpan="2" style={styles.title}>
              Glosario de Abreviaturas
            </th>
          </tr>
        </thead>
        <tbody
          style={{
            backgroundColor: 'white',
            height: '60px',
            border: '1px solid #ddd',
          }}>
          <tr>
            <td style={styles.td3}>
              <strong>A.I.:</strong> Ausencia Injustificada
            </td>
            <td style={styles.td3}>
              <strong>A.J.:</strong> Ausencia Justificada
            </td>
          </tr>
          <tr>
            <td style={styles.td3}>
              <strong>P.G.R.:</strong> Permiso con goce de remuneraciones
            </td>
            <td style={styles.td3}>
              <strong>P.S.G.R.:</strong> Permiso sin goce de remuneraciones
            </td>
          </tr>
          <tr>
            <td style={styles.td3}>
              <strong>AT:</strong> Atraso
            </td>
            <td style={styles.td3}>
              <strong>SA:</strong> Salida Anticipada
            </td>
          </tr>
          <tr>
            <td style={styles.td3}>
              <strong>J.O.:</strong> Jornada Ordinaria
            </td>
          </tr>
        </tbody>
      </table>

      <table style={{ ...styles.table, height: '100px' }}>
        <thead
          style={{
            backgroundColor: 'white',
            color: 'blue',
            height: '50px',
            border: '1px solid #ddd',
          }}>
          <tr>
            <th style={styles.title}>Glosario de Colores</th>
          </tr>
        </thead>
        <tbody
          style={{
            backgroundColor: 'white',
            height: '20px',
            border: '1px solid #ddd',
            textAlign: 'center',
          }}>
          <tr>
            <td style={styles.td3}>🔴 Existen Modificaciones en la Marca</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
