import { Table } from 'antd';

export default function TablaNoExisteReporteFestivos() {
  const dataNoReporte = [
    {
      key: '1',
      descripcion:
        'No hay reportes de Domingos y Festivos de los trabajadores en el periodo seleccionado',
    },
  ];

  const columnsNoReporte = [
    {
      title: 'Descripción',
      dataIndex: 'descripcion',
      key: 'descripcion',
    },
  ];
  return (
    <Table
      bordered
      pagination={false}
      align="center"
      dataSource={dataNoReporte}
      columns={columnsNoReporte}
    />
  );
}
