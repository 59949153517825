import React, { useState } from 'react';
import { Button, Space, message } from 'antd';

import { Storage } from 'aws-amplify';
import { saveAs } from 'file-saver';
import JSZip from 'jszip';

import { DownloadOutlined } from '@ant-design/icons';

export default function BotonDescargarDocumento({ selectedRowKeys, tipo }) {
  const [loading, setLoading] = useState(false);

  const handleDownload = async (documento) => {
    await descargarDocumentos(documento);
  };

  const descargarDocumentos = async (array) => {
    setLoading(true);
    const zip = new JSZip();
    const promises = [];

    for (const documento of array) {
      const filePath = documento;
      const promise = Storage.get(filePath, { download: true })
        .then((file) => {
          const blob = new Blob([file.Body], { type: file.ContentType });
          zip.file(filePath.split('/').pop(), blob);
        })
        .catch((error) => {
          console.error('Error al Descargar Archivo:', error);
          message.error('Error al Descargar Archivo');
        });
      promises.push(promise);
    }

    try {
      await Promise.all(promises);
      const zipBlob = await zip.generateAsync({ type: 'blob' });
      saveAs(zipBlob, `${tipo}.zip`);
    } catch (error) {
      console.error('Error al crear el archivo ZIP:', error);
      message.error('Error al crear el archivo ZIP');
    } finally {
      setLoading(false);
    }

    setLoading(false);
  };

  return (
    <Space>
      {tipo === 'Documentos' && (
        <Button
          type="primary"
          style={{ marginRight: '10px' }}
          disabled={selectedRowKeys.length === 0}
          loading={loading}
          onClick={() => handleDownload(selectedRowKeys)}>
          Descargar Documentos
          <DownloadOutlined />
        </Button>
      )}
    </Space>
  );
}
