import React, { useEffect, useState } from 'react';
import { BellOutlined } from '@ant-design/icons';
import { Space, Form, Button, Input, Modal, message } from 'antd';
import dtService from '../../../../services/dtService';
export default function Notificar({ item }) {
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const { TextArea } = Input;
  const [form] = Form.useForm();

  let tipodeDocumento;
  if (item.tipodeDocumento === 'anexo') tipodeDocumento = 'Anexo';
  if (item.tipodeDocumento === 'pactoHE')
    tipodeDocumento = 'Pacto de Horas Extras';

  const handleCancel = () => {
    setShow(false);
    form.resetFields();
  };

  const handleSubmit = async (values) => {
    console.log('values', values);
    console.log(item);

    setLoading(true);

    try {
      const body = {
        trabajadorId: item.trabajadorId,
        empresaId: item.empresaId,
        fechaSolicitud: item.fechaSolicitud,
        fechaInicioDocumento: item.fechaInicioDocumento,
        fechaFinDocumento: item.fechaFinDocumento,
        //  pathDocumento: key,
        pathDocumento: item.pathDocumento,
        motivoDocumento: item.motivoDocumento,
        nombreTrabajador: item.nombreTrabajador,
        rutTrabajador: item.rutTrabajador,
        tipodeDocumento: item.tipodeDocumento,
        contratoId: item.contratoId,
        ubicacion: item.ubicacion,
        rutEmpleador: item.rutEmpleador,
        razonSocial: item.razonSocial,
        cargo: item.cargo,
        tituloNotificacion: values.tituloNotificacion,
        mensajeNotificacion: values.mensajeNotificacion,
        tipo: 'notificarDocumento',
      };

      await dtService.notificarDocumento(body);
      message.success('Notificación enviada con éxito');
      setLoading(false);
      setShow(false);
    } catch (error) {
      message.error('Error al notificar al trabajador');
      setLoading(false);
      setShow(false);
    }
  };

  const showModal = () => {
    setShow(true);
  };
  return (
    <>
      <Space onClick={showModal}>
        <BellOutlined />
        <spam>Notificar</spam>
      </Space>
      <Modal
        title="Notificar Trabajador"
        visible={show}
        // open={show}
        onCancel={handleCancel}
        destroyOnClose
        closable={!loading}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancelar
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={() => form.submit()}
            loading={loading}>
            Notificar
          </Button>,
        ]}>
        <p>
          ¿Está seguro de notificar al trabajador? Una vez notificado, el
          trabajador recibirá una notificación en su correo con la información
          del documento.
        </p>
        <Form layout="vertical" onFinish={handleSubmit} form={form}>
          <Form.Item
            name="ofertaTrabajador"
            id="ofertaTrabajador"
            label="Oferta - Trabajador"

            /* rules={[
                {
                  required: true,
                },
              ]} */
          >
            <Input
              id="ofertaTrabajador"
              name="ofertaTrabajador"
              type="text"
              disabled
              placeholder={`#${item.pais_consecutivoOferta} - ${item.nombreTrabajador}`}
            />
          </Form.Item>
          <Form.Item
            name="tipoDocumentoEstado"
            id="tipoDocumentoEstado"
            label="Tipo de Documento - Estado"

            /* rules={[
                {
                  required: true,
                },
              ]} */
          >
            <Input
              id="tipoDocumentoEstado"
              name="tipoDocumentoEstado"
              type="text"
              disabled
              placeholder={`${tipodeDocumento} - ${item.estadoActualDocumento}`}
            />
          </Form.Item>{' '}
          <Form.Item
            name="tituloNotificacion"
            id="tituloNotificacion"
            label="Titulo de la Notificación"
            rules={[
              {
                required: true,
                message: 'Por favor, agregue un titulo a la notificación',
              },
            ]}>
            <Input
              id="tituloNotificacion"
              name="tituloNotificacion"
              type="text"
              placeholder="Ingrese el titulo de la notificación"
            />
          </Form.Item>
          <Form.Item
            name="mensajeNotificacion"
            id="mensajeNotificacion"
            label="Mensaje de la Notificación"
            rules={[
              {
                required: true,
                message: 'Por favor, agregue un mensaje a la notificación',
              },
            ]}>
            <TextArea
              id="mensajeNotificacion"
              name="mensajeNotificacion"
              type="text"
              placeholder="Escribe aqui el mensaje de la notificación"
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}
