import React, { useState } from 'react';
import useAuth from '../../hooks/useAuth';
import { Storage } from 'aws-amplify';
import {
  Card,
  Form,
  Button,
  Input,
  Upload,
  DatePicker,
  Select,
  message,
} from 'antd';
import { SearchOutlined, UploadOutlined } from '@ant-design/icons';
// import dtService from '../../../services/dtService';

import locale from 'antd/es/date-picker/locale/es_ES';
import { useNavigate } from 'react-router-dom';

import dtService from '../../services/dtService';

export default function FormCrearDocumentos(record) {
  const { empresaID } = useAuth();
  const [form] = Form.useForm();
  const [buttonLoading, setButtonLoading] = useState(false);

  const { TextArea } = Input;
  const navigate = useNavigate();
  const resetFilters = () => {
    form.resetFields();
  };

  function toBase64(file) {
    console.log(`file`, file);
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result.split(',')[1]);
      reader.onerror = (error) => reject(error);
    });
  }
  /*  const configFecha = (event) => {
    const formatoFecha = dayjs(new Date(event)).format('YYYY-MM-DD');
    setFecha(formatoFecha);
  }; */

  function generarSerialUnico(fechaSolicitud) {
    const now = new Date(fechaSolicitud).getTime(); // Obtener la fecha y hora de la fecha ingresada en milisegundos
    const random = Math.floor(Math.random() * 1000000); // Generar un número aleatorio
    return `${now}-${random}`; // Combinar ambos valores en una cadena única
  }

  const handleSubmit = async (values) => {
    setButtonLoading(true);

    const fechaInicioDocumento = new Date(
      values.fechaInicioDocumento._d
    ).toISOString();
    const fechaFinDocumento = new Date(
      values.fechaFinDocumento._d
    ).toISOString();
    const fechaSolicitud = new Date(values.fechaSolicitud._d).toISOString();

    const file = values.documento[0].originFileObj;

    try {
      if (file.size > 10485760) {
        message.error('El Archivo no puede ser mayor a 10MB');
        setButtonLoading(false);
        return;
      }

      if (file.type !== 'application/pdf') {
        message.error('El Archivo debe ser pdf');
        setButtonLoading(false);
        return;
      }

      const base64File = await toBase64(file);

      console.log(`base64File`, base64File);
      const paisConsecutivoOferta = `Chile#${values.oferta}`;
      const tipo = 'consultarOferta';

      const lengthFile = file.name.split('.').length;

      const consultarTrabajaorOferta = await dtService.buscarTrabajadorOferta(
        empresaID.empresaId,
        tipo,
        values.correoTrabajador,
        paisConsecutivoOferta
      );

      const trabajadorOferta = consultarTrabajaorOferta[0].data.data;

      if (!trabajadorOferta.existe) {
        const messageError = consultarTrabajaorOferta[0].data.message;
        message.error(messageError);
        setButtonLoading(false);
        return;
      }

      const path = `Trabajador/${values.correoTrabajador}/${
        empresaID.empresaId
      }/${trabajadorOferta.registro.paisConsecutivoOferta}/${
        values.tipoDocumento
      }/${values.tipoDocumento}#${
        trabajadorOferta.registro.paisConsecutivoOferta
      }#${generarSerialUnico(fechaSolicitud)}#${values.correoTrabajador}.${
        file.name.split('.')[lengthFile - 1]
      }`;

      /*  const { key } = await Storage.put(path, file, {
        contentType: file.type,
      });
 */

      const body = {
        trabajadorId: values.correoTrabajador,
        fechaSolicitud_empresaId_pais_consecutivoOferta: `${fechaSolicitud}#${empresaID.empresaId}#${trabajadorOferta.registro.paisConsecutivoOferta}`,
        empresaId: empresaID.empresaId,
        fechaSolicitud: fechaSolicitud,
        pais_consecutivoOferta: trabajadorOferta.registro.paisConsecutivoOferta,
        fechaInicioDocumento: fechaInicioDocumento,
        fechaFinDocumento: fechaFinDocumento,
        //  pathDocumento: key,
        pathDocumento: path,
        motivoDocumento: values.motivoDocumento,
        nombreTrabajador: values.nombreTrabajador,
        rutTrabajador: values.rutTrabajador,
        tipodeDocumento: values.tipoDocumento,
        historicoEstadosDocumento: {
          DocumentoSinFirmar: new Date().toISOString(),
          DocumentoFirmadoXTrabajador: '',
          DocumentoFirmadoXRepLegal: '',
        },
        contratoId: trabajadorOferta.registro.contratoId,
        estadoActualDocumento: 'DocumentoSinFirmar',
        ubicacion: trabajadorOferta.registro.ubicacion,
        rutEmpleador: trabajadorOferta.registro.rutEmpleador,
        razonSocial: trabajadorOferta.registro.razonSocial,
        cargo: trabajadorOferta.registro.cargo,
        file: {
          uid: file.uid,
          lastModified: file.lastModified,
          lastModifiedDate: file.lastModifiedDate,
          name: file.name,
          size: file.size,
          type: file.type,
          base64: base64File,
        },
        tipo: 'crearDocumento',
      };

      await dtService.RegistrarDocumentosTrabajador(body);

      message.success('Documento subido con éxito!');
      setButtonLoading(false);
      navigate(`/documentos`, {
        replace: true,
      });
    } catch (error) {
      console.error(error);
      message.error(error);
      setButtonLoading(false);
    }
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  return (
    <Card
      title="Ingrese los datos del Documento"
      extra={<Button onClick={() => resetFilters()}>Limpiar buscador</Button>}
      style={{ marginBottom: '10px' }}>
      <Form
        initialValues={{
          correoTrabajador: record.trabajador.correo,
          nombreTrabajador: record.trabajador.nombre,
          rutTrabajador: record.trabajador.rut,
          tipoDocumento: record.tipo,
        }}
        form={form}
        layout="horizontal"
        requiredMark={false}
        onFinish={handleSubmit}
        labelCol={{
          span: 5,
        }}
        wrapperCol={{
          span: 19,
        }}>
        <Card
          title={<strong>{`Formulario ${record.titulo}`}</strong>}
          headStyle={{ textAlign: 'center' }}
          style={{ marginBottom: '20px' }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}>
            <Form.Item
              name="nombreTrabajador"
              id="nombreTrabajador"
              label="Nombre del trabajador"
              style={{ width: '80%' }}>
              <Input
                style={{ width: '60%' }}
                id="nombreTrabajador"
                name="nombreTrabajador"
                type="text"
                disabled
                placeholder={record.trabajador.nombre}></Input>
            </Form.Item>
            <Form.Item
              name="correoTrabajador"
              id="correoTrabajador"
              label="Correo del trabajador"
              style={{ width: '80%' }}>
              <Input
                style={{ width: '60%' }}
                id="correoTrabajador"
                name="correoTrabajador"
                type="text"
                disabled
                value={record.trabajador.correo}
                placeholder={record.trabajador.correo}></Input>
            </Form.Item>
            <Form.Item
              name="rutTrabajador"
              id="rutTrabajador"
              label="Rut del trabajador"
              style={{ width: '80%' }}>
              <Input
                style={{ width: '60%' }}
                id="rutTrabajador"
                name="rutTrabajador"
                type="text"
                disabled
                value={record.trabajador.rut}
                placeholder={record.trabajador.rut}></Input>
            </Form.Item>
            <Form.Item
              name="fechaSolicitud"
              id="fechaSolicitud"
              label="Fecha de Solicitud"
              style={{ width: '80%' }}
              rules={[
                {
                  required: true,
                  message: 'Por favor, ingrese la fecha de solicitud',
                },
              ]}>
              <DatePicker
                id="fechaSolicitud"
                name="fechaSolicitud"
                style={{ width: '40%' }}
                placeholder={'Fecha de Solicitud'}
                allowClear={true}
                // onChange={configFecha}
                format={'DD/MM/YY'}
                locale={locale}
              />
            </Form.Item>
            <Form.Item
              name="fechaInicioDocumento"
              id="fechaInicioDocumento"
              label="Fecha Inicio Documento"
              style={{ width: '80%' }}
              rules={[
                {
                  required: true,
                  message:
                    'Por favor, ingrese la fecha de inicio del documento',
                },
              ]}>
              <DatePicker
                id="fechaInicioDocumento"
                name="fechaInicioDocumento"
                style={{ width: '40%' }}
                placeholder={'Fecha de Inicio del Documento'}
                allowClear={true}
                // onChange={configFecha}
                format={'DD/MM/YY'}
                locale={locale}
              />
            </Form.Item>
            <Form.Item
              name="fechaFinDocumento"
              id="fechaFinDocumento"
              label="Fecha Término Documento"
              style={{ width: '80%' }}
              rules={[
                {
                  required: true,
                  message:
                    'Por favor, ingrese la fecha de termino del documento',
                },
              ]}>
              <DatePicker
                id="fechaFinDocumento"
                name="fechaFinDocumento"
                style={{ width: '40%' }}
                placeholder={'Fecha de Término del Documento'}
                allowClear={true}
                // onChange={configFecha}
                format={'DD/MM/YY'}
                locale={locale}
              />
            </Form.Item>

            <Form.Item
              name="motivoDocumento"
              id="motivoDocumento"
              label="Motivo del Documento"
              style={{ width: '80%' }}
              rules={[
                {
                  required: true,
                  message: 'Por favor, agregue el motivo del documento',
                },
              ]}>
              <TextArea
                style={{ width: '80%' }}
                id="motivoDocumento"
                name="motivoDocumento"
                type="text"
                placeholder="Escribe aquí el motivo del documento"
              />
            </Form.Item>
            <Form.Item
              name="oferta"
              id="oferta"
              label="Nro Oferta"
              style={{ width: '80%' }}
              rules={[
                {
                  required: true,
                  message: 'Por favor, ingrese el número de oferta',
                },
              ]}>
              <Input
                style={{ width: '20%' }}
                id="oferta"
                name="oferta"
                type="number"
                placeholder="Ingrese el número de oferta"
              />
            </Form.Item>
            <Form.Item
              name="tipoDocumento"
              id="tipoDocumento"
              label="Tipo de Documento"
              style={{ width: '80%', display: 'none' }}
              rules={[
                {
                  required: true,
                  message: 'Por favor, seleccione un tipo de documento',
                },
              ]}>
              <Select
                name="tipoDocumento"
                id="tipoDocumento"
                style={{ width: '80%' }}
                showSearch
                placeholder="Seleccione un tipo de documento"
                allowClear={true}
                // onChange={handleSelectChange}
              >
                <Select.Option value="anexo">Anexos</Select.Option>
                <Select.Option value="pactoHoraExtra">
                  Pacto Horas Extra
                </Select.Option>
              </Select>
            </Form.Item>
            <Form.Item
              name="documento"
              id="documento"
              label="Adjuntar Documento"
              style={{ width: '80%' }}
              valuePropName="fileList" // Para manejar el archivo como un array
              getValueFromEvent={normFile} // Normaliza el archivo antes de enviarlo
              rules={[
                {
                  required: true,
                  message: 'Por favor, adjunte un documento',
                },
              ]}>
              <Upload beforeUpload={() => false} maxCount={1}>
                {/* maxCount={1} asegura que solo se pueda seleccionar un archivo */}
                <Button icon={<UploadOutlined />}>Seleccionar archivo</Button>
              </Upload>
            </Form.Item>
          </div>
        </Card>
        <div className="flex justify-end">
          <Button
            block
            style={{ marginBottom: '20px' }}
            type="primary"
            htmlType="submit"
            className="mr-2"
            icon={<SearchOutlined />}
            loading={buttonLoading}>
            Cargar Documento
          </Button>
        </div>
      </Form>
      {/*  <Modal>
        <p>
          Se ha enviado una notificación al correo del trabajador con la
          creación del nuevo documento, el cual tendrá{' '}
        </p>
      </Modal> */}
    </Card>
  );
}
