import React from "react";
import AppRouter from "./routes/AppRouter";
import { ConfigProvider } from "antd";


ConfigProvider.config({
    theme: {
        primaryColor: "#B1C54F",
        // primaryColor: "#25b864",
    },
});

export default function App() {
    return (
        <ConfigProvider>
            <AppRouter />
        </ConfigProvider>
    );
}
