import React from 'react';
import { Space, Table } from 'antd';
import FormatDateV2 from '../../../utils/FormatDateV2';
import consultaJustificacion from '../../jornadaDiaria/funtions/consultaJustificacion';
import fechasUtils from '../../../utils/fechasUtils';

export default function TablaReporteFestivosMensuales({ data, desde, hasta }) {
  const getRowClassName = (record) => {
    return consultaJustificacion(record) === 'green'
      ? 'table-cell-green'
      : consultaJustificacion(record) === 'red'
      ? 'table-cell-red'
      : '';
  };

  const reportesAgrupadosPorAniosYMeses =
    fechasUtils.agruparPorAniosYMeses(data);

  const columns = [
    {
      title: <span style={{ color: 'blue' }}>T.C.B.D.D.A.</span>,
      dataIndex: 'beneficiario',
      aling: 'center',
      key: 'beneficiario',
      width: 100,
      align: 'center',
      render: (text, record) => {
        return (
          <Space>
            <span>{text}</span>
          </Space>
        );
      },
    },
    {
      title: <span style={{ color: 'blue' }}>Fecha</span>,
      dataIndex: 'fecha',
      key: 'fecha',
      width: 100,
      align: 'center',
      render: (text, record) => {
        return (
          <Space>
            <span>{FormatDateV2(text).split('T')[0]}</span>
          </Space>
        );
      },
    },
    {
      title: <span style={{ color: 'blue' }}>Asistencia</span>,
      dataIndex: 'asistencia',
      align: 'center',
      key: 'asistencia',
      width: 100,
      render: (text, record) => {
        return (
          <Space>
            <span>{text}</span>
          </Space>
        );
      },
    },
    {
      title: <span style={{ color: 'blue' }}>Ausencia</span>,
      dataIndex: 'ausencia',
      key: 'ausencia',
      width: 100,
      align: 'center',
      render: (text, record) => {
        return (
          <Space>
            <span>{text}</span>
          </Space>
        );
      },
    },
    {
      title: <span style={{ color: 'blue' }}>Observaciones</span>,
      dataIndex: 'observaciones',
      key: 'observaciones',
      width: 100,
      align: 'center',
      render: (text, record) => {
        return (
          <Space>
            <span>{text}</span>
          </Space>
        );
      },
    },
  ];
  return (
    <div className="mx-auto mt-1 mb-1" style={{ maxWidth: '70rem' }}>
      {Object.keys(reportesAgrupadosPorAniosYMeses).map((anio) =>
        Object.keys(reportesAgrupadosPorAniosYMeses[anio]).map((mes) => (
          <Table
            className="mx-auto mb-1"
            key={`${anio}-${mes}`}
            rowClassName={getRowClassName}
            align="center"
            columns={columns}
            dataSource={reportesAgrupadosPorAniosYMeses[anio][mes]}
            //    footer={footer}
            bordered
            pagination={false}
            summary={() => (
              <Table.Summary
                fixed={'bottom'} /* style={{ marginBottom: 20 }} */
              >
                <Table.Summary.Row>
                  <Table.Summary.Cell index={1} colSpan={3} align="start">
                    Total domingos y festivos laborados del{' '}
                    {fechasUtils.obtenerPrimerDiaDelMes(anio, mes)} al{' '}
                    {fechasUtils.obtenerUltimoDiaDelMes(anio, mes)}:
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={1} colSpan={3} align="center">
                    {reportesAgrupadosPorAniosYMeses[anio][mes].length} Domingos
                    y/o Festivos
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              </Table.Summary>
            )}
          />
        ))
      )}
    </div>
  );
}
