import React from 'react';
import getOptions from '../../../utils/getOptions';
import { Space, Table } from 'antd';
import FormatDateV2 from '../../../utils/FormatDateV2';
import consultaJustificacion from '../../jornadaDiaria/funtions/consultaJustificacion';

export default function TablaAsistenciaReporte({ items }) {
  const getRowClassName = (record) => {
    return consultaJustificacion(record) === 'green'
      ? 'table-cell-green'
      : consultaJustificacion(record) === 'red'
      ? 'table-cell-red'
      : '';
  };
  const customMessage = {
    filterSearchPlaceholder: 'Filtrar',
    filterReset: 'Limpiar',
  };

  const columns = [
    {
      title: <span style={{ color: 'blue' }}>Fecha</span>,
      dataIndex: 'key',
      key: 'key',
      filterSearch: true,
      align: 'center',
      width: 80,
      filters: getOptions(items, 'key'),
      onFilter: (v, record) => record.key.indexOf(v) === 0,
      render: (text, record) => (
        <Space>
          <span>{FormatDateV2(record.key).split('T')[0] || 'pendiente'}</span>
        </Space>
      ),
    },
    {
      title: <span style={{ color: 'blue' }}>Asistencia</span>,
      dataIndex: 'asistencia',
      key: 'asistencia',
      filterSearch: true,
      align: 'center',
      width: 80,
      filters: getOptions(items, 'asistencia'),
      onFilter: (v, record) => record.asistencia.indexOf(v) === 0,
      render: (text) => (
        <Space>
          <span>{text}</span>
        </Space>
      ),
    },
    {
      title: <span style={{ color: 'blue' }}>Ausencia</span>,
      dataIndex: 'ausencia',
      key: 'ausencia',
      filterSearch: true,
      align: 'center',
      width: 80,
      filters: getOptions(items, 'ausencia'),
      onFilter: (v, record) => record.ausencia.indexOf(v) === 0,
      render: (text) => (
        <Space>
          <span>{text}</span>
        </Space>
      ),
    },
    {
      title: <span style={{ color: 'blue' }}>Observaciones</span>,
      dataIndex: 'observaciones',
      key: 'observaciones',
      filterSearch: true,
      align: 'center',
      width: 120,
      filters: getOptions(items, 'observaciones'),
      onFilter: (v, record) => record.observaciones.indexOf(v) === 0,
      render: (text) => (
        <Space>
          <span>{text}</span>
        </Space>
      ),
    },
  ];

  return (
    <Table
      rowClassName={getRowClassName}
      align="center"
      bordered
      columns={columns}
      locale={customMessage}
      dataSource={items}
      pagination={false}
    />
  );
}
