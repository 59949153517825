import React from 'react';
import getOptions from '../../../utils/getOptions';
import { Space } from 'antd';

import FormatDateV2 from '../../../utils/FormatDateV2';

export default function TablaJornadaDiariaReporte(record) {
  return [
    {
      title: <span style={{ color: 'blue' }}>Fecha</span>,
      dataIndex: 'fecha',
      key: 'fecha',
      filterSearch: true,
      align: 'center',
      // width: 80,
      filters: getOptions(record, 'fecha'),
      onFilter: (v, record) => record.fecha.indexOf(v) === 0,
      render: (text, record) => (
        <Space>
          <span>{FormatDateV2(record.key).split('T')[0]}</span>
        </Space>
      ),
    },
    {
      title: <span style={{ color: 'blue' }}>J. O. Pactada</span>,
      dataIndex: 'jornada',
      key: 'jornada',
      filterSearch: true,
      align: 'center',

      filters: getOptions(record, 'jornada'),
      onFilter: (v, record) => record.jornada.indexOf(v) === 0,
      render: (text, record) => (
        <Space>
          <div>
            {text !== null ? (
              <p>
                {
                  FormatDateV2(text.entrada, 'jornada').split('T')[1]
                  /* .slice(0, 5) */
                }{' '}
                -{' '}
                {
                  FormatDateV2(text.salida, 'jornada').split('T')[1]
                  /* .slice(0, 5) */
                }
              </p>
            ) : (
              <p> - </p>
            )}
          </div>
        </Space>
      ),
    },
    {
      title: <span style={{ color: 'blue' }}>Marcaciones Jornada</span>,
      dataIndex: 'jornadaReal',
      key: 'jornadaReal',
      filterSearch: true,
      align: 'center',

      filters: getOptions(record, 'jornadaReal'),
      onFilter: (v, record) => record.jornadaReal.indexOf(v) === 0,
      render: (text, record) => (
        <Space>
          <div>
            <p>
              {FormatDateV2(text.entrada, 'marca').split('T')[1]} -{' '}
              {FormatDateV2(text.salida, 'marca').split('T')[1]}
            </p>
          </div>
        </Space>
      ),
    },
    {
      title: <span style={{ color: 'blue' }}>Colación</span>,
      dataIndex: 'colacion',
      key: 'colacion',
      filterSearch: true,
      align: 'center',
      // width: 80,
      filters: getOptions(record, 'colacion'),
      onFilter: (v, record) => record.colacion.indexOf(v) === 0,
      render: (text, record) => (
        <Space>
          {record.colacion === 'No aplica' ? (
            <div>
              <p>{record.colacion}</p>
            </div>
          ) : (
            <div>
              <p>
                {FormatDateV2(text.entradaColacion, 'marca').split('T')[1]} -{' '}
                {FormatDateV2(text.salidaColacion, 'marca').split('T')[1]}
              </p>
            </div>
          )}
        </Space>
      ),
    },
    {
      title: <span style={{ color: 'blue' }}>Marcaciones Colación</span>,
      dataIndex: 'colacionReal',
      key: 'colacionReal',
      filterSearch: true,
      align: 'center',
      // width: 80,
      filters: getOptions(record, 'colacionReal'),
      onFilter: (v, record) => record.colacionReal.indexOf(v) === 0,
      render: (text, record) => (
        <Space>
          {record.colacionReal === 'No aplica' ? (
            <div>
              <p>{record.colacionReal}</p>
            </div>
          ) : (
            <div>
              <p>
                {FormatDateV2(text.entradaColacion, 'marca').split('T')[1]} -{' '}
                {FormatDateV2(text.salidaColacion, 'marca').split('T')[1]}
              </p>
            </div>
          )}
        </Space>
      ),
    },
    {
      title: <span style={{ color: 'blue' }}>Tiempo Faltante</span>,
      dataIndex: 'anomalias',
      key: 'anomalias',
      filterSearch: true,
      align: 'center',
      // width: 120,
      filters: getOptions(record, 'anomalias'),
      onFilter: (v, record) => record.anomalias.indexOf(v) === 0,
      render: (text, record) => (
        <Space>
          {text.totalAnomalias === '00:00:00' ? (
            <div>
              <p></p>
            </div>
          ) : (
            <p> -{text.totalAnomalias}</p>
          )}
        </Space>

        /* <Space>
          {text.totalAnomalias === '00:00:00' ? (
            <div>
              <p>No se registran anomalías</p>
            </div>
          ) : (
            <div>
              {text.atrasoEntrada ? (
                <p>
                  AT Inició J.O.:{' '}
                  {FormatDateV2(text.atrasoEntrada.split(' ')[1]).split('T')[1]}
                </p>
              ) : (
                <p> </p>
              )}
              {text.salidaAnticipadaColacion ? (
                <p>
                  SA Colación:{' '}
                  {
                    FormatDateV2(
                      text.salidaAnticipadaColacion.split(' ')[1]
                    ).split('T')[1]
                  }
                </p>
              ) : (
                <p> </p>
              )}
              {text.atrasoColacion ? (
                <p>
                  AT retorno Colación:{' '}
                  {
                    FormatDateV2(text.atrasoColacion.split(' ')[1]).split(
                      'T'
                    )[1]
                  }
                </p>
              ) : (
                <p> </p>
              )}
              {text.salidaAnticipada ? (
                <p>
                  SA terminó J.O.:{' '}
                  {
                    FormatDateV2(text.salidaAnticipada.split(' ')[1]).split(
                      'T'
                    )[1]
                  }
                </p>
              ) : (
                <p> </p>
              )}
            </div>
          )}
        </Space> */
      ),
    },
    {
      title: <span style={{ color: 'blue' }}>Tiempo Extra</span>,
      dataIndex: 'horasExtraordinarias',
      key: 'horasExtraordinarias',
      filterSearch: true,
      align: 'center',
      // width: 80,
      filters: getOptions(record, 'horasExtraordinarias'),
      onFilter: (v, record) => record.horasExtraordinarias.indexOf(v) === 0,
      render: (text, record) => (
        <Space>
          {record.horasExtraordinarias !== '00:00:00' ? (
            <span> +{text} </span>
          ) : (
            <span></span>
          )}
        </Space>
      ),
    },
    {
      title: <span style={{ color: 'blue' }}>Otras Marcaciones</span>,
      dataIndex: 'otrasMarcas',
      key: 'otrasMarcas',
      filterSearch: true,
      align: 'center',
      //  width: 80,
      filters: getOptions(record, 'horasExtraordinarias'),
      onFilter: (v, record) => record.horasExtraordinarias.indexOf(v) === 0,
      render: (text, record) => (
        <Space>
          {record.horasExtraordinarias !== null ? (
            <span> {text} </span>
          ) : (
            <span> 00:00:00 </span>
          )}
        </Space>
      ),
    },
    {
      title: <span style={{ color: 'blue' }}>Observaciones</span>,
      dataIndex: 'comentarios',
      key: 'comentarios',
      // filterSearch: true,
      align: 'center',
      // width: 120,
      filters: getOptions(record, 'comentarios'),
      onFilter: (v, record) => record.comentarios.indexOf(v) === 0,
      render: (text, record) => (
        <Space>
          <span> {text} </span>
        </Space>
      ),
    },
    {
      title: (
        <span style={{ color: 'blue' }}>
          Autorización de Distribución Excepcional de Jornada
        </span>
      ),
      dataIndex: 'autorizacionExcepcional',
      key: 'autorizacionExcepcional',
      // filterSearch: true,
      align: 'center',
      //  width: 120,
      filters: getOptions(record, 'comentarios'),
      onFilter: (v, record) => record.comentarios.indexOf(v) === 0,
      render: (text, record) => (
        <Space>
          <span> {text} </span>
        </Space>
      ),
    },
  ];
}
