import Amplify, { API } from 'aws-amplify';
import awsconfig from '../aws-exports';

import createParams from '../lib/createApiParams';
import customFetch from '../lib/customFetch';

const apiName = 'dt';
Amplify.configure(awsconfig);

const BuscarTrabajadores = async (
  // /** @type {any} */ userToken,
  /** @type {string} */ tipo,
  /**  @type {string} */ empresaId = '',

  /** @type {string} */ nombre,
  /** @type {string} */ primerApellido,
  /** @type {string} */ segundoApellido,
  /** @type {string} */ rut,
  /** @type {string} */ desde,
  /** @type {string} */ hasta,
  /** @type {string} */ ubicacion,
  /** @type {string} */ cargo,
  /** @type {string} */ correos,
  /** @type {string} */ tipoBusqueda,
  /** @type {string} */ jornada,
  /** @type {string} */ turno,
  /** @type {string} */ est

) => {
  try {
    const path = '/buscarReportes';
    const myInit = createParams();

    myInit.queryStringParameters = {
      // userToken: await getToken(),
      tipo,
      empresaId,
      nombre,
      primerApellido,
      segundoApellido,
      rut,
      desde,
      hasta,
      ubicacion,
      cargo,
      correos,
      tipoBusqueda,
      jornada,
      turno,
      est
    };
    // console.log("🚀 ~ file: dtService.js ~ line 43 ~ myInit", myInit);
    const res = customFetch(() => API.get(apiName, path, myInit));
    return res;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const BuscarHash = async (
  /** @type {string} */ hash,
  /**  @type {string} */ empresaId = ''
) => {
  const path = '/validarHash';
  const myInit = createParams();
  myInit.queryStringParameters = {
    hash,
    empresaId,
  };
  try {
    const res = customFetch(() => API.get(apiName, path, myInit));
    return res;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
// const ObtenerRepositorioTrabajadores = async (
//   /** @type {any} */ userToken,
//   /** @type {any} */ fechaInicio,
//   /** @type {any} */ fechaFin
// ) => {
//   const path = "/obtenerTrabajadores";
//   const myInit = createParams();
//   myInit.queryStringParameters = {
//     userToken: await getToken(),
//     fechaInicio,
//     fechaFin,
//   };
//   const res = customFetch(() => API.get(apiName, path, myInit));
//   return res;
// };

const ValidationIP = async () => {
  const path = '/ip-validation';
  const myInit = createParams();
  try {
    const res = await customFetch(() => API.get(apiName, path, myInit));

    return res;
  } catch (error) {
    console.error('Error en la validación de la IP:', error);
    throw error;
  }
};

// consultaUbicaciones
const consultarinformacionBuscador = async (tipo, empresaId = '', correo = '') => {
  const path = '/ubicacionesCargos';
  const myInit = createParams();
  try {
    myInit.queryStringParameters = {
      tipo,
      empresaId,
      correo
    };

    const res = await customFetch(() => API.get(apiName, path, myInit));
    return res;
  } catch (error) {
    console.error('Error al consultar información del buscador:', error);
    throw error;
  }
};

const RegisterNewPassword = async (
  /** @type {any} */ email,
  /** @type {any} */ password
) => {
  const path = '/register';
  const myInit = createParams();
  myInit.body = {
    email,
    password,
  };
  try {
    const res = customFetch(() => API.post(apiName, path, myInit));
    return res;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const AuthenticateUser = async (/** @type {any} */ password) => {
  const path = '/authentication';
  const myInit = createParams();
  myInit.body = { password };
  try {
    const res = customFetch(() => API.post(apiName, path, myInit));
    return res;
  } catch (error) {
    console.error(error);
  }
};

const RequestTemporaryPassword = async (/** @type {String} */ email, /** @type {String} */  tipo, /** @type {String} */ password) => {
  const path = '/user';
  const myInit = createParams();
  myInit.body = { email, tipo, password };
  try {
    const res = customFetch(() => API.post(apiName, path, myInit));
    return res;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const BuscarAsistenciaTrabajadores = async (/** @type {String} */ correo, /** @type {String} */  fechaInicio, /** @type {String} */ fechaFin, /**  @type {String} */ empresaId) => {
  const path = '/buscarAsistencia';

  const myInit = createParams();
  myInit.queryStringParameters = { correo, fechaInicio, fechaFin, empresaId };

  try {
    const res = customFetch(() => API.get(apiName, path, myInit));
    return res;
  } catch (error) {
    console.error(error);
    throw error;
  }

};

const MarcarAsistencia = async (registro) => {
  const path = '/buscarAsistencia';
  const myInit = createParams();
  myInit.body = {
    registro,
  };
  const res = customFetch(() => API.post(apiName, path, myInit));
  return res;
};

const NotificarFiscalizacionEmpresa = async (registro) => {

  const path = '/notificar-empresa';
  const myInit = createParams();
  myInit.body = registro;

  const res = customFetch(() => API.post(apiName, path, myInit));
  return res;
}

const RegistrarInicioSesionFiscalizador = async (registro) => {

  const path = '/registro-sesiones-fiscalizadores';
  const myInit = createParams();
  myInit.body = registro;

  const res = customFetch(() => API.post(apiName, path, myInit));
  return res;
}

const BuscarIncidentesTecnicos = async (
  /** @type {string} */ desde,
  /**  @type {string} */ hasta,
) => {
  const path = '/buscarIncidentesTecnicos';
  const myInit = createParams();
  myInit.queryStringParameters = {
    desde,
    hasta,
  };
  try {
    const res = customFetch(() => API.get(apiName, path, myInit));
    return res;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const RegistrarDocumentosTrabajador = async (registro) => {

  const path = '/manejar-documentos';
  const myInit = createParams();
  myInit.body = registro;

  const res = customFetch(() => API.post(apiName, path, myInit));
  return res;
}



const buscarDocumentosTrabajadorByEmpresaId = async (/**  @type {String} */ empresaId,/**  @type {String} */ tipo = 'consultarDocumentos') => {
  const path = '/manejar-documentos';

  const myInit = createParams();
  myInit.queryStringParameters = { empresaId, tipo };

  try {
    const res = customFetch(() => API.get(apiName, path, myInit));
    return res;
  } catch (error) {
    console.error(error);
    throw error;
  }

};

const buscarTrabajadorOferta = async (/**  @type {String} */ empresaId,/**  @type {String} */ tipo = 'consultarOferta',/**  @type {String} */ trabajadorId,/**  @type {String} */ paisConsecutivoOferta) => {

  const path = '/manejar-documentos';

  const myInit = createParams();
  myInit.queryStringParameters = { empresaId, tipo, trabajadorId, paisConsecutivoOferta };

  try {
    const res = customFetch(() => API.get(apiName, path, myInit));
    return res;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

const notificarDocumento = async (registro) => {
  const path = '/manejar-documentos';

  const myInit = createParams();
  myInit.body = registro;

  try {
    const res = await customFetch(() => API.post(apiName, path, myInit));
    return res;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
const dtService = {
  BuscarTrabajadores,
  // ObtenerRepositorioTrabajadores,
  ValidationIP,
  RegisterNewPassword,
  AuthenticateUser,
  RequestTemporaryPassword,
  consultarinformacionBuscador,
  BuscarHash,
  BuscarAsistenciaTrabajadores,
  MarcarAsistencia,
  NotificarFiscalizacionEmpresa,
  RegistrarInicioSesionFiscalizador,
  BuscarIncidentesTecnicos,
  RegistrarDocumentosTrabajador,
  buscarDocumentosTrabajadorByEmpresaId,
  buscarTrabajadorOferta,
  notificarDocumento
};

export default dtService;
