import { Table } from 'antd';

export default function TablaTotalFestivos({ descripcion, total }) {
  const columns = [
    {
      title: descripcion,
      dataIndex: 'descripcion',
      key: 'descripcion',
    },
  ];
  const data = [
    {
      key: '1',
      descripcion: total,
    },
  ];
  return (
    <Table
      bordered
      pagination={false}
      align="center"
      dataSource={data}
      columns={columns}
    />
  );
}
