import React from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';
// import Home from "../pages/home/Home";
import LoginPage from '../pages/auth/LoginPage';
import SelectCompanies from '../pages/auth/SelectCompanies';
// import RestorePasswordPage from '../pages/auth/RestorePasswordPage';
import PrivateLayout from '../components/layouts/PrivateLayout';
import PublicLayout from '../components/layouts/PublicLayout';
import AuthProvider from '../context/AuthProvider';
import Asistencia from '../pages/asistencia/Asistencia';
import JornadaDiaria from '../pages/jornadaDiaria/JornadaDiaria';
import DomigosFestivos from '../pages/domigosFestivos/DomigosFestivos';
import ModificacionesAlteraciones from '../pages/modificaciones/ModificacionesAlteraciones';
import ValidadorHash from '../pages/validadorHash/ValidadorHash';
// import CrearIncidencias from '../pages/incidencias/CrearIncidencia';
import ConfirmNewPasswordPage from '../pages/auth/ConfirmNewPasswordPage';
import Home from '../pages/home/Home';
// import FiscalizacionDT from "../pages/auth/FiscalizacionDT";
import LoginSelector from '../pages/auth/LoginSelector';
// import ReporteIncidencias from '../pages/incidencias/ReporteIncidencias';
import JustificarMarca from '../pages/justificar/JustificarMarca';

import IncidentesTecnicos from '../pages/incidencias/IncidentesTecnicos';
import AnexosContrato from '../pages/anexos/AnexosContrato';
import PactoHorasExtra from '../pages/pactoHorasExtra/PactoHorasExtra';
import Notificaciones from '../pages/notificaciones/Notificaciones';
import Documentos from '../pages/documentos/Documentos';

export default function AppRouter() {
  return (
    <Router>
      <AuthProvider>
        <Routes>
          <Route path="/auth/selector" element={<PublicLayout />}>
            <Route index element={<LoginSelector />} />
          </Route>
          {/* <Route path="/auth" element={<PublicLayout />}>
            <Route index element={<LoginPage />} />
          </Route> */}
          <Route path="/auth" element={<PublicLayout />}>
            <Route index element={<LoginPage />} />
            <Route path="*" element={<Navigate to="/auth" replace />} />
          </Route>
          {/* <Route path="/recuperar" element={<PublicLayout />}>
            <Route index element={<RestorePasswordPage />} />
          </Route> */}
          {/*   <Route path="/validacion-dt" element={<PublicLayout />}>
            <Route index element={<FiscalizacionDT />} />
          </Route> */}

          <Route path="/seleccionar-empresa" element={<PublicLayout />}>
            <Route index element={<SelectCompanies />} />
          </Route>
          <Route path="/confirmar/:email" element={<PublicLayout />}>
            <Route index element={<ConfirmNewPasswordPage />} />
          </Route>
          <Route path="/" element={<PrivateLayout />}>
            <Route index element={<Home />} />
            <Route path="*" element={<Navigate to="/" replace />} />
          </Route>
          <Route path="/reportes/asistencia" element={<PrivateLayout />}>
            <Route index element={<Asistencia />} />
          </Route>
          <Route path="/reportes/jornada-diaria" element={<PrivateLayout />}>
            <Route index element={<JornadaDiaria />} />
          </Route>
          <Route path="/reportes/domingos-festivos" element={<PrivateLayout />}>
            <Route index element={<DomigosFestivos />} />
          </Route>
          <Route path="/reportes/modificaciones" element={<PrivateLayout />}>
            <Route index element={<ModificacionesAlteraciones />} />
          </Route>
          <Route path="/reportes/validarHash" element={<PrivateLayout />}>
            <Route index element={<ValidadorHash />} />
          </Route>
          {/*  <Route
            path="/incidencias/crear-incidencia"
            element={<PrivateLayout />}>
            <Route index element={<CrearIncidencias />} />
          </Route>

          <Route
            path="/incidencias/ver-incidencias"
            element={<PrivateLayout />}>
            <Route index element={<ReporteIncidencias />} />
          </Route> */}
          <Route
            path="/incidencias/buscar-incidentes-tecnicos"
            element={<PrivateLayout />}>
            <Route index element={<IncidentesTecnicos />} />
          </Route>
          <Route path="/justificar-marca" element={<PrivateLayout />}>
            <Route index element={<JustificarMarca />} />
          </Route>
          <Route path="/documentos" element={<PrivateLayout />}>
            <Route index element={<Documentos />} />
          </Route>
          <Route
            path="/documentos/crear-anexo/:correo"
            element={<PrivateLayout />}>
            <Route index element={<AnexosContrato />} />
          </Route>
          <Route
            path="/documentos/crear-pacto-extra/:correo"
            element={<PrivateLayout />}>
            <Route index element={<PactoHorasExtra />} />
          </Route>
          <Route
            path="/documentos/enviar-notificaciones/:correo"
            element={<PrivateLayout />}>
            <Route index element={<Notificaciones />} />
          </Route>
        </Routes>
      </AuthProvider>
    </Router>
  );
}
