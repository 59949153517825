import React, { useState, useEffect } from 'react';
import useAuth from '../../../hooks/useAuth';
import dtService from '../../../services/dtService';
import { useNavigate } from 'react-router-dom';
import { Alert, Button, Form, Input, message, Spin, Modal } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { API } from 'aws-amplify';
import { getUsers /* listUsers  */ } from '../../../graphql/queries';

export default function LoginForm({ currentUserRole }) {
  const userDefaultDt = require('../defaultUserAccess.json').session;
  const [tempPassword, setTempPassword] = useState(false);
  const [loadingTempPassword, setLoadingTempPassword] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [isDTUser, setIsDTUser] = useState(false);
  const [emailUser, setEmailUser] = useState('');
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alert, setAlert] = useState(null);
  const { loginUser, logoutUser } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    currentUserRole !== 'dt' ? setIsDTUser(true) : setIsDTUser(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // modal
  const [isModalOpen, setIsModalOpen] = useState(false);

  const arrayDomains = [
    /@nuatechnology\.com$/,
    /@xinerlink\.cl$/,
    /@dt\.gob\.cl$/,
    /@xinerlinkexpress\.cl$/,
    /@veltec\.cl$/,
  ];

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = (/** @type {object} */ values) => {
    values.tipo = 'fiscalizadorDT';
    onClickPasswordGeneration(values.correo, values.tipo, '');
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const onChangeEmailListener = (
    /** @type {React.ChangeEvent<HTMLInputElement>} */ event
  ) => {
    if (currentUserRole === 'dt') {
      const emailValue = event.target.value;

      const isValid = arrayDomains.some((domain) => {
        return domain.test(emailValue);
      });

      setEmailUser(emailValue);
      setIsDTUser(isValid);
    } else {
      setIsDTUser(true);
    }
  };

  const onClickPasswordGeneration = async (email, tipo, password) => {
    setLoadingTempPassword(true);
    try {
      const [err] = await dtService.RequestTemporaryPassword(
        email,
        tipo,
        password
      );
      if (err) {
        throw new Error(err);
      }

      setTempPassword(true);
      message.info('Se ha enviado la clave temporal a tu correo');
    } catch (error) {
      // message.error('Ha ocurrido un error generando la contraseña temporal');
      console.error(error.message);
    }
    message.info('Se ha enviado la clave temporal a tu correo');
    setLoadingTempPassword(false);
    setIsModalOpen(false);
  };

  const onClickUserValidation = async (email, tipo, password) => {
    try {
      const response = await dtService.RequestTemporaryPassword(
        email,
        tipo,
        password
      );

      if (!response[0].data) {
        message.error('Ha ocurrido un error');
      } else {
        return response[0].data;
      }

      return;
    } catch (error) {
      // message.error('Ha ocurrido un error');
      console.error(error.message);
    }
    // message.info('Se ha enviado la clave temporal a tu correo');
  };
  const onFinish = async (/** @type {null} */ values) => {
    if (currentUserRole === 'dt') {
      if (values.username !== userDefaultDt.username) {
        const diffDays = await check5DaysPassword(values.username);
        if (diffDays > 5) {
          message.error(
            'Han pasado más de 5 días desde que se generó la contraseña temporal, por favor solicite una nueva'
          );
          return;
        }
        if (diffDays === null) {
          message.error(
            'El usuario no existe. Por favor, genere una contraseña temporal'
          );
        } else {
          initiateLoginDt(values);
        }
      } else {
        initiateLoginDt(values);
      }
    } else if (
      currentUserRole === 'empresa' ||
      currentUserRole === 'trabajador' ||
      currentUserRole === 'est'
    ) {
      setIsSubmit(true);
      values.tipo = currentUserRole;

      const responseUser = await onClickUserValidation(
        values.username,
        values.tipo,
        values.password
      );

      if (
        responseUser.response.existe &&
        responseUser.response.passwordCorrecta
      ) {
        values.empresaId = responseUser.response.registro?.empresaId;
        values.nombre =
          currentUserRole === 'empresa' || currentUserRole === 'est'
            ? responseUser.response.registro?.nombre
            : `${responseUser.response.registro?.nombre} ${responseUser.response.registro?.primerApellido}`;

        values.estSubcontratista =
          currentUserRole === 'empresa' || currentUserRole === 'est'
            ? responseUser.response.registro?.estSubcontratista
            : null;

        if (currentUserRole === 'trabajador') {
          initiateLoginDt(values);
        } else if (currentUserRole === 'empresa' && values.estSubcontratista) {
          message.error(
            'Por favor, utilice el ingreso designado para empresas subcontratistas.'
          );
          setIsSubmit(false);
        } else if (currentUserRole === 'est' && !values.estSubcontratista) {
          message.error(
            'Por favor, utilice el ingreso designado para empresas.'
          );
          setIsSubmit(false);
        } else {
          initiateLoginDt(values);
        }
      } else {
        setIsSubmit(false);
        setShowAlert(true);
        setAlert('Usuario o contraseña incorrectos');
      }
    }
  };

  const logoutRol = () => {
    logoutUser();
  };

  const initiateLoginDt = async (/** @type {object} */ values) => {
    try {
      setIsSubmit(true);
      setAlert(null);
      await loginUser(values);

      message.success('Bienvenido al Portal Fiscalización DT');
      navigate('/');
      //  await registerInspectorLogin(values.username);
    } catch (error) {
      console.error(error);
      message.error('Ocurrió un error');
      setShowAlert(true);
      setAlert('Usuario o contraseña incorrectos');
    } finally {
      setIsSubmit(false);
    }
  };

  /*  const registerInspectorLogin = async (emailInspector) => {
    const correoFiscalizador = emailInspector;

    await dtService.RegistrarInicioSesionFiscalizador({
      correoFiscalizador: correoFiscalizador,
    });
  }; */
  const check5DaysPassword = async (email) => {
    const request = API.graphql({
      query: getUsers,
      variables: { email: email },
    });

    const { data } = await request;

    if (data.getUsers === null) {
      return null;
    } else {
      const hoy = new Date();
      const lastUpdate = new Date(data.getUsers.updatedAt);

      const diffTime = Math.abs(hoy - lastUpdate);

      const diffDays = diffTime / (1000 * 60 * 60 * 24);

      return diffDays;
    }
  };

  if (isSubmit)
    return (
      <div className="p-10 text-center">
        <Spin />
        <p className="mt-2">Ingresando...</p>
      </div>
    );

  return (
    <Form
      name="normal_login"
      initialValues={{
        remember: true,
      }}
      onFinish={onFinish}
      size={'large'}>
      <Form.Item
        name="username"
        onChange={(event) => {
          onChangeEmailListener(event);
        }}
        rules={[
          {
            required: true,
            message: 'Ingrese un correo',
          },
        ]}>
        <Input prefix={<UserOutlined />} placeholder="Correo de usuario" />
      </Form.Item>
      <Form.Item
        name="password"
        rules={[
          {
            required: true,
            message: 'Ingrese una contraseña',
          },
        ]}>
        <Input.Password
          prefix={<LockOutlined />}
          type="password"
          placeholder="Contraseña"
          visibilityToggle={{
            visible: passwordVisible,
            onVisibleChange: setPasswordVisible,
          }}
        />
      </Form.Item>

      <div>
        {currentUserRole === 'dt' ? (
          <Alert
            showIcon
            message="Solicitar clave DT"
            type="info"
            action={
              <Button
                size="small"
                className="underline"
                loading={loadingTempPassword}
                type="text"
                onClick={() => {
                  showModal();
                }}>
                <span className="underline-bold">Generar</span>
                <br />
              </Button>
            }
          />
        ) : (
          <div></div>
        )}

        <Modal
          title="Confirmación de datos para clave temporal"
          visible={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
          footer={false}>
          <Form
            name="modal-confirm"
            onFinish={handleOk}
            initialValues={{ correo: emailUser }}>
            <Form.Item
              name="correo"
              onChange={(event) => {
                onChangeEmailListener(event);
              }}
              rules={[
                {
                  required: true,
                  message: 'Ingrese correo',
                },
              ]}>
              <Input placeholder="correo" />
            </Form.Item>

            {/*  <Form.Item
              name="nombre"
              rules={[
                {
                  required: true,

                  message: 'Ingrese nombre',
                },
              ]}>
              <Input placeholder="Nombre de usuario" />
            </Form.Item> */}

            <Button
              type="primary"
              htmlType="submit"
              disabled={!isDTUser}
              block
              loading={loadingTempPassword}>
              Generar
            </Button>
          </Form>
        </Modal>
      </div>

      <p>
        {tempPassword &&
          `Tu contraseña temporal se ha enviado a tu correo electrónico.`}
      </p>
      {/* <Form.Item>
        <Link to='/recuperar'>¿Olvidaste la contraseña?</Link>
      </Form.Item> */}
      {showAlert && (
        <div className="mb-4">
          <Alert
            message={alert}
            type="error"
            showIcon
            onClose={() => setShowAlert(false)}></Alert>
        </div>
      )}
      <Form.Item>
        <Button type="primary" disabled={!isDTUser} htmlType="submit">
          Iniciar sesión
        </Button>
      </Form.Item>
      <div style={{ textAlign: 'center' }}>
        <span>
          ¿Deseas
          <Button
            size="small"
            className="underline"
            type="text"
            onClick={() => {
              logoutRol();
            }}>
            <span className="underline-bold">cambiar de Rol?</span>
          </Button>
        </span>
      </div>
    </Form>
  );
}
