// import React from 'react';
import { Table } from 'antd';

export default function TablaHash(props) {
  console.log(props);
  const columns = [
    {
      title: 'Nombres',
      dataIndex: 'nombres',
      key: 'nombres',
      filterSearch: true,
      align: 'center',
      width: 80,
    },
    {
      title: 'Primer Apellido',
      dataIndex: 'primerApellido',
      key: 'primerApellido',
      align: 'center',
      width: 80,
    },

    {
      title: 'Segundo Apellido',
      dataIndex: 'segundoApellido',
      key: 'segundoApellido',
      //  filterSearch: true,
      align: 'center',
      width: 80,
    },
    {
      title: 'correo',
      dataIndex: 'correo',
      key: 'correo',
      align: 'center',
      width: 80,
    },
    {
      title: 'cargo',
      dataIndex: 'cargo',
      key: 'cargo',
      align: 'center',
      width: 80,
    },
  ];
  return (
    <Table pagination={false} columns={columns} dataSource={props.record} />
  );
}
