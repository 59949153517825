import getOptions from '../../../utils/getOptions';
import dayjs from 'dayjs';
import { Card, Descriptions } from 'antd';
import Asistencia from '../Asistencia';

export default function TablaAsistenciaInfo({ items }) {
  /*  items = items.map((item) => {
    item.nombreCompleto = `${item.nombres} ${item.primerApellido} ${item.segundoApellido}`;
    return item;
  });
 */

  const rutEmpleador = items.empresaRut.rutEmpresa;
  const razonSocial = items.empresaRut.razonSocial;
  const ubicacion = items.ubicacion;
  const desde = items.fechaConsultaReporte.desde;
  const hasta = items.fechaConsultaReporte.hasta;
  const rut = items.rut;
  const cargo = items.cargo;
  const nombreCompleto = `${items.nombres} ${items.primerApellido} ${items.segundoApellido}`;

  return (
    <Card
      title={<strong>Reporte de Asistencia</strong>}
      style={{
        marginBottom: '20px',
      }}
      headStyle={{ textAlign: 'center' }}>
      <Descriptions column={1}>
        <Descriptions.Item label="Razón Social del Empleador">
          {razonSocial}
        </Descriptions.Item>
        <Descriptions.Item label="RUT del Empleador">
          {rutEmpleador}
        </Descriptions.Item>
        <Descriptions.Item label="Nombre del Trabajador">
          {nombreCompleto}
        </Descriptions.Item>
        <Descriptions.Item label="RUT">{rut}</Descriptions.Item>
        <Descriptions.Item label="Cargo">{cargo}</Descriptions.Item>
        <Descriptions.Item label="Lugar de Prestación de los Servicios">
          {ubicacion}
        </Descriptions.Item>
        <Descriptions.Item label="Periodo Consultado Del">
          {dayjs(desde).format('DD/MM/YY')} al {dayjs(hasta).format('DD/MM/YY')}
        </Descriptions.Item>
      </Descriptions>
    </Card>
  );

  /*   return [
    {
      title: 'Nombre Trabajador',
      dataIndex: 'nombreCompleto',
      key: 'nombreCompleto',
      align: 'center',
      width: 180,
      filterSearch: true,
      // filters: getOptions(items, 'nombreCompleto'),
      onFilter: (v, record) => record.nombreCompleto.indexOf(v) === 0,
    },
    {
      title: 'Rut',
      dataIndex: 'rut',
      key: 'rut',
      align: 'center',
      width: 80,
      // filterSearch: true,
      // filters: getOptions(items, 'rut'),
      onFilter: (v, record) => record.rut.indexOf(v) === 0,
    },
    {
      title: 'Empresa - Rut',
      dataIndex: 'empresaRut',
      key: 'empresaRut',
      align: 'center',
      width: 100,
      // filterSearch: true,
      // filters: getOptions(items, 'empresaRut'),
      onFilter: (v, record) => record.empresaRut.indexOf(v) === 0,
    },
    {
      title: 'Cargo',
      dataIndex: 'cargo',
      key: 'cargo',
      align: 'center',
      width: 100,
      // filterSearch: true,
      // filters: getOptions(items, 'cargo'),
      onFilter: (v, record) => record.cargo.indexOf(v) === 0,
    },
  ]; */
}
