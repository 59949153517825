export default function consultaJustificacion(record) {
  // const justificacionEntrada = record?.justificacionEntrada || null;
  // const justificacionSalida = record?.justificacionSalida || null;
  /*  if (justificacionEntrada !== null || justificacionSalida !== null) {
    return false;
  } else {
    return true;
  } */

  if (record.comentarios === '' || record.observaciones === '') {
    return true;
  } else if (record.comentarios === 'Marcación automatica por el sistema' || record.observaciones === 'Marcación automatica por el sistema') {
    return "green";
  } else {
    return "red"
  }
}
