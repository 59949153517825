import React from 'react';
import { Button, Dropdown, Menu } from 'antd';
import { MoreOutlined, FileAddOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';

export default function MenuAccionesCrear({ record }) {
  const navigate = useNavigate();

  const items = [
    {
      key: 0,
      label: (
        <Button
          size="small"
          type="text"
          onClick={() => {
            navigate(`/documentos/crear-anexo/${record.correo}`, {
              replace: true,
              state: {
                trabajador: {
                  correo: record.correo,
                  rut: record.rut,
                  nombre: record.nombre,
                },
              },
            });
          }}>
          <FileAddOutlined />
          Crear Anexo de Contrato
        </Button>
      ),
    },
    {
      key: 1,
      label: (
        <Button
          size="small"
          type="text"
          onClick={() => {
            navigate(`/documentos/crear-pacto-extra/${record.correo}`, {
              replace: true,
              state: {
                trabajador: {
                  correo: record.correo,
                  rut: record.rut,
                  nombre: record.nombre,
                },
              },
            });
          }}>
          <FileAddOutlined />
          Crear Pacto Hora Extra
        </Button>
      ),
    },
  ];

  const menu = <Menu items={items} />;
  return (
    <Dropdown overlay={menu} trigger={['hover']}>
      <Button type="text" icon={<MoreOutlined />} />
    </Dropdown>
  );
}
