import React, { useEffect } from 'react';
import FormatoReportesAsistencias from './components/FormatoReportesAsistencias';
import FormatoReporteJornada from './components/FormatoReporteJornada';
import FormatoReporteFestivos from './components/FormatoReporteFestivos';
import FormatoReporteModificaciones from './components/FormatoReporteModificaciones';

export default function BotonExportarWord({
  name = 'jornada',
  formato = 'jornada',
  dataArray = [],
  setexportarWord,
}) {
  const Export2Word = (element, filename = '') => {
    const contentElement = document.getElementById(element);

    // Apply some global styles to the document
    const applyInlineStyles = (el) => {
      el.style.fontFamily = 'Arial';
      el.style.fontSize = '10pt';
    };
    const applyStylesRecursively = (parent) => {
      applyInlineStyles(parent);

      for (let i = 0; i < parent.children.length; i++) {
        applyStylesRecursively(parent.children[i]);
      }
    };
    applyStylesRecursively(contentElement);

    const preHtml =
      "<html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:w='urn:schemas-microsoft-com:office:word' xmlns='http://www.w3.org/TR/REC-html40'><head><meta charset='utf-8'><title>Export HTML To Doc</title></head><body>";
    const postHtml = '</body></html>';
    const html = preHtml + contentElement.innerHTML + postHtml;
    const blob = new Blob(['\ufeff', html], {
      type: 'application/msword',
    });

    // Specify link url
    let url =
      'data:application/vnd.ms-word;charset=utf-8,' + encodeURIComponent(html);

    // Specify file name
    filename = filename ? filename + '.doc' : 'document.doc';

    // Create download link element
    const downloadLink = document.createElement('a');

    document.body.appendChild(downloadLink);

    if (navigator.msSaveOrOpenBlob) {
      navigator.msSaveOrOpenBlob(blob, filename);
    } else {
      // Create a link to the file
      downloadLink.href = url;

      // Setting the file name
      downloadLink.download = filename;

      //triggering the function
      downloadLink.click();
    }

    document.body.removeChild(downloadLink);

    setexportarWord(false);
  };

  // const datos =
  useEffect(() => {
    // let filter = { validadoXEST: { eq: true } };
    Export2Word('exportContent', name);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (formato === 'jornada') {
    return (
      <div
        id="exportContent"
        style={{
          width: '21cm',

          margin: '30mm 45mm 30mm 45mm',
        }}
      >
        <FormatoReporteJornada dataArray={dataArray} widthTable="600px" />
      </div>
    );
  } else if (formato === 'asistencia') {
    return (
      <div
        id="exportContent"
        style={{
          width: '21cm',

          margin: '30mm 45mm 30mm 45mm',
        }}
      >
        <FormatoReportesAsistencias dataArray={dataArray} widthTable="600px" />
      </div>
    );
  } else if (formato === 'festivos') {
    return (
      <div
        id="exportContent"
        style={{
          width: '21cm',

          margin: '30mm 45mm 30mm 45mm',
        }}
      >
        <FormatoReporteFestivos dataArray={dataArray} widthTable="600px" />
      </div>
    );
  } else if (formato === 'modificaciones') {
    return (
      <div
        id="exportContent"
        style={{
          width: '21cm',

          margin: '30mm 45mm 30mm 45mm',
        }}
      >
        <FormatoReporteModificaciones
          dataArray={dataArray}
          widthTable="600px"
        />
      </div>
    );
  } else <div style={{ display: 'none' }}></div>;
}
