import { React, useState } from 'react';

import { Card, Typography, Button, message, Spin, Image } from 'antd';
import useAuth from '../../hooks/useAuth';
import CosultaResolucion from '../../utils/CosultaResolucion';
import dtService from '../../services/dtService';
import BotonSalir from '../../components/botones/BotonSalir';
import logoApp from '../../assets/Xinerlink.png';

const { Title, Paragraph } = Typography;

export default function FiscalizacionDT({ userDefault }) {
  const [isLoading, setIsLoading] = useState(false);
  const { loginUser } = useAuth();
  console.log(userDefault);

  const titleSize = CosultaResolucion ? 4 : 3;

  const handleLogin = (userDefault) => {
    authUser(userDefault.username, userDefault.password);
    /* loginUser({
      username: userDefaultDt.username,
      password: userDefaultDt.password,
    }); */
  };

  const authUser = async (username, password) => {
    let passwordDecrypted;
    setIsLoading(true);
    try {
      const [res, err] = await dtService.AuthenticateUser(password);
      if (err) {
        message.error('Error autenticando usuario');
        console.error(err);
      }
      // console.log(res);
      passwordDecrypted = res?.data?.message?.registro?.password;
      console.log(typeof passwordDecrypted);

      await loginUser({
        username: username,
        password: passwordDecrypted,
      });

      // navigate('/');
      setIsLoading(false);
    } catch (error) {
      message.error('Error autenticando usuario');
      console.error(error);
      setIsLoading(false);
    }
  };

  return (
    <div>
      <div className="site-card-wrapper">
        <Card>
          <div className="flex justify-end mb-4">
            <Button
              type="primary"
              className="mr-4 mt-4"
              onClick={() => handleLogin(userDefault)}
              style={{ display: isLoading ? 'none' : 'block' }}>
              Fiscalización DT
            </Button>
          </div>
          <Card>
            <div className="w-56 mx-auto mb-4 ">
              <Image src={logoApp} preview={false} alt="Logo App" />
            </div>
            {isLoading ? (
              <div className="flex justify-center p-10 text-center flex-col items-center">
                <Spin />
                <Paragraph className="mt-2">Ingresando...</Paragraph>
              </div>
            ) : (
              <div>
                <div className="my-16 text-center">
                  <Title level={titleSize}>
                    Bienvenido al Portal Fiscalización DT
                  </Title>
                </div>
                <div className="flex justify-center">
                  <BotonSalir />
                </div>
              </div>
            )}
          </Card>

          <div className="text-center mt-4">
            <Paragraph>
              "Recuerda que para acceder al sistema debes utilizar el botón
              'Fiscalización DT'. Para salir del sistema, utiliza el botón
              'Salir'."
            </Paragraph>
          </div>
        </Card>
      </div>
    </div>
  );
}
