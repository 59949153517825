import React from 'react';
import { Card, Row, Col, Typography } from 'antd';
const { Paragraph } = Typography;

export default function GlosarioCardJornadaDiaria() {
  const CircleSvgRed = () => (
    <svg width="1em" height="1em" fill="#FA8072" viewBox="0 0 1024 1024">
      <circle cx="512" cy="512" r="512" />
    </svg>
  );
  const CircleSvgGreen = () => (
    <svg width="1em" height="1em" fill="#92FD70" viewBox="0 0 1024 1024">
      <circle cx="512" cy="512" r="512" />
    </svg>
  );

  const abbreviations = [
    /*   { label: 'AT', description: 'Atraso' },
    { label: 'SA', description: 'Salida Anticipada' }, */
    { label: 'A.I.', description: 'Ausencia Injustificada' },
    { label: 'A.J.', description: 'Ausencia Justificada' },
    { label: 'J.O.', description: 'Jornada Ordinaria' },
    { label: 'P.G.R.', description: 'Permiso con goce de remuneraciones' },
    { label: 'P.S.G.R.', description: 'Permiso sin goce de remuneraciones' },
  ];

  return (
    <>
      <Row>
        <Card
          style={
            {
              /*  borderRadius: '8px',  */
              // textAlign: 'center',
            }
          }
          title={
            <span style={{ color: 'blue' }}>Glosario de Abreviaturas</span>
          }>
          <div>
            {abbreviations.map((item, index) => (
              <Row>
                <Col key={index} span={12}>
                  <Paragraph>
                    <strong>{item.label}</strong>
                  </Paragraph>
                </Col>
                <Col key={index} span={12}>
                  <Paragraph>{item.description}</Paragraph>
                </Col>
              </Row>
            ))}
          </div>
        </Card>
        <Card
          style={{
            /* borderRadius: '8px', */
            // textAlign: 'center',
            //  marginTop: '20px',
            marginLeft: '100px',
          }}
          title={<span style={{ color: 'blue' }}>Glosario de Colores</span>}>
          <Row>
            <Col span={12}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  //  textAlign: 'center',
                }}>
                <CircleSvgRed />
                <Paragraph style={{ margin: '8px 0 0 8px' }}>
                  Alteraciones en Marcaciones
                </Paragraph>
              </div>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}>
                <CircleSvgGreen />
                <Paragraph style={{ margin: '8px 0 0 8px' }}>
                  Marca Completada Automáticamente
                </Paragraph>
              </div>
            </Col>
          </Row>
        </Card>
      </Row>
    </>
  );
}
