import React, { useState, useEffect } from 'react';
import { Table, Divider } from 'antd';
import BuscadorReportes from '../../components/buscador/BuscadorReportes';
import TablaReporteModificacionesInfo from './components/TablaReporteModificacionesInfo';
import TablaReporteModificaciones from './components/TablaReporteModificaciones';
import BotonExportarPdf from '../../components/botones/BotonExportarPdf';
import BotonExportarWord from '../../components/botones/BotonExportarWord';
import HeaderReportes from '../../components/headers/HeaderReportes';
import BotonExportarExcel from '../../components/botones/BotonExportarExcel';
import Labels from '../../utils/Labels.json';
import TablaNoExisteReporteTurnos from './components/TablaNoExisteReporteTurnos';
import TrabajadoresReportesColumns from '../../components/tablas/TrabajadoresReportesColumns';

export default function ModificacionesAlteraciones() {
  const [firstTable, setFirstTable] = useState([]);
  const [jobsAreLoading, setJobsAreLoading] = useState(true);
  const [exportarWord, setexportarWord] = useState(false);
  const [exportarPdf, setexportarPdf] = useState(false);
  const [currentDate, setCurrentDate] = useState('');
  const [exportarExcel, setexportarExcel] = useState(false);
  const [DatosSeleccionados, setDatosSeleccionados] = useState([]);
  const [reporteVisible, setReporteVisible] = useState(false);

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setDatosSeleccionados(selectedRows);
      setReporteVisible(true);
    },
  };

  useEffect(() => {
    let date = new Date();

    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();

    if (month < 10) {
      setCurrentDate(`${year}-0${month}-${day}`);
    } else {
      setCurrentDate(`${year}-${month}-${day}`);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <HeaderReportes
        name="Reporte de Modificaciones y/o Alteraciones De Turnos"
        setexportarExcel={setexportarExcel}
        setexportarPdf={setexportarPdf}
        setexportarWord={setexportarWord}
        firstTable={DatosSeleccionados}
        DatosSeleccionados={DatosSeleccionados}
      />

      <BuscadorReportes
        setFirstTable={setFirstTable}
        tipoBuscador="modificaciones"
        jobsAreLoading={jobsAreLoading}
        setJobsAreLoading={setJobsAreLoading}
        setDatosSeleccionados={setDatosSeleccionados}
      />

      {exportarExcel && (
        <BotonExportarExcel
          dataArray={DatosSeleccionados}
          formato="modificaciones"
          name={`reporte Modificacion de turnos_${currentDate}`}
          setexportarExcel={setexportarExcel}
        />
      )}

      {exportarWord && (
        <BotonExportarWord
          dataArray={DatosSeleccionados}
          formato="modificaciones"
          name={`reporte Modificacion de turnos_${currentDate}`}
          setexportarWord={setexportarWord}
        />
      )}

      {exportarPdf && (
        <BotonExportarPdf
          dataArray={DatosSeleccionados}
          formato="modificaciones"
          name={`reporte Modificacion de Turno_${currentDate}`}
          setexportarpdf={setexportarPdf}
        />
      )}

      {jobsAreLoading ? (
        <div />
      ) : firstTable.length === 0 ? (
        <div className="mx-auto mt-1" style={{ maxWidth: '70rem' }}>
          <TablaNoExisteReporteTurnos />
        </div>
      ) : (
        <>
          <Table
            rowSelection={{
              type: 'checkbox',
              ...rowSelection,
            }}
            rowKey="rut"
            pagination={{ showSizeChanger: true }}
            locale={Labels['tableLabels']}
            dataSource={firstTable}
            columns={TrabajadoresReportesColumns(firstTable)}
            scroll={{ x: 'max-content' }}
          />

          {reporteVisible && DatosSeleccionados.length > 0
            ? DatosSeleccionados.map((record) => {
                return (
                  <div key={record.key} className="mx-auto mt-6">
                    {record.length !== 0 && (
                      <div>
                        <TablaReporteModificacionesInfo items={record} />
                        <div
                          className="mx-auto mt-1"
                          style={{ maxWidth: '70rem' }}>
                          <TablaReporteModificaciones
                            data={record.reporte || []}
                          />
                        </div>
                        <Divider />
                      </div>
                    )}
                  </div>
                );
              })
            : null}
        </>
      )}
    </div>
  );
}

/* <Table
rowSelection={{
  type: 'checkbox',
  ...rowSelection,
}}
columns={TablaAsistenciaInfo(firstTable)}
expandable={{
  expandedRowRender: (record) => (
    <TablaReporteModificaciones data={record.reporte || []} />
  ),
  rowExpandable: (record) => record.name !== 'Not Expandable',
}}
dataSource={firstTable}
scroll={{ x: true }}
locale={Labels['tableLabels ']}
/> */
