import React from 'react';
import { Button } from 'antd';
import { RollbackOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';

export default function BotonGoBack(path) {
  const navigate = useNavigate();

  return (
    <Button
      icon={<RollbackOutlined />}
      className="mb-4"
      onClick={() => {
        navigate(`/documentos`, {
          replace: true,
        });
      }}>
      Volver
    </Button>
  );
}
