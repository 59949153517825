import React, { useState, useEffect } from 'react';
import { Table, Divider, Typography } from 'antd';

import TablaJornadaDiariaInfo from './components/TablaJornadaDiariaInfo';
import BuscadorReportes from '../../components/buscador/BuscadorReportes';
import BotonExportarWord from '../../components/botones/BotonExportarWord';
import BotonExportarPdf from '../../components/botones/BotonExportarPdf';
import HeaderReportes from '../../components/headers/HeaderReportes';
import BotonExportarExcel from '../../components/botones/BotonExportarExcel';
import CartaRegistrosSemanales from './components/CartaRegistrosSemanales';
import sumarRestarHorasExtraordinarias from '../../utils/sumarRestarHorasExtraordinarias';
import GlosarioCardJornadaDiaria from './components/GlosarioCardJornadaDiaria';
import TablaNoExisteReporteJornadaDiaria from './components/TablaNoExisteReporteJornadaDiaria';
import TrabajadoresReportesColumns from '../../components/tablas/TrabajadoresReportesColumns';
import Labels from '../../utils/Labels.json';

export default function JornadaDiaria() {
  const [firstTable, setFirstTable] = useState([]);
  const [jobsAreLoading, setJobsAreLoading] = useState(true);
  const [exportarWord, setexportarWord] = useState(false);
  const [exportarPdf, setexportarPdf] = useState(false);
  const [currentDate, setCurrentDate] = useState('');
  const [exportarExcel, setexportarExcel] = useState(false);
  const [DatosSeleccionados, setDatosSeleccionados] = useState([]);
  const [reporteVisible, setReporteVisible] = useState(false);

  const { Paragraph } = Typography;

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setDatosSeleccionados(selectedRows);
      setReporteVisible(true);
    },
  };

  const customMessage = {
    filterSearchPlaceholder: 'Filtrar',
    filterReset: 'Limpiar',
  };

  const expandedRowRender = (record) => {
    const arrayreportes = [];
    if (record.reporte.length !== 0) {
      record.reporte.forEach((obj) => {
        const arrayreportesemana = [];

        if (obj.length !== 0) {
          obj.forEach((item) => {
            const nexElement = item;

            if (
              item.jornadaReal.entrada !== null &&
              item.jornadaReal.salida !== null
            ) {
              let h1 = item.jornadaReal.salida.split('T')[1].trim();
              let h2 = item.jornadaReal.entrada.split('T')[1].trim();

              let totalHora = sumarRestarHorasExtraordinarias.restarHoras(
                h1,
                h2
              );

              if (totalHora.charAt(0) === '-') {
                nexElement.diferenciaHoras =
                  sumarRestarHorasExtraordinarias.sumarHoras(
                    totalHora,
                    '23:59:99'
                  );
              } else {
                nexElement.diferenciaHoras = totalHora;
              }
            } else {
              nexElement.diferenciaHoras = '00:00:00';
            }

            arrayreportesemana.push(nexElement);
          });
        }

        arrayreportes.push(arrayreportesemana);
      });
    }

    return (
      <div>
        {arrayreportes.map((item) => (
          <CartaRegistrosSemanales record={item} />
        ))}
      </div>
    );
  };

  useEffect(() => {
    let date = new Date();

    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();

    if (month < 10) {
      setCurrentDate(`${year}-0${month}-${day}`);
    } else {
      setCurrentDate(`${year}-${month}-${day}`);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <HeaderReportes
        name="Reporte de Jornada Diaria"
        setexportarExcel={setexportarExcel}
        setexportarPdf={setexportarPdf}
        setexportarWord={setexportarWord}
        firstTable={firstTable}
        DatosSeleccionados={DatosSeleccionados}
      />

      <BuscadorReportes
        setFirstTable={setFirstTable}
        tipoBuscador="jornada"
        jobsAreLoading={jobsAreLoading}
        setJobsAreLoading={setJobsAreLoading}
        setDatosSeleccionados={setDatosSeleccionados}
      />

      {exportarExcel && (
        <BotonExportarExcel
          dataArray={DatosSeleccionados}
          formato="jornada"
          name={`reporte Jornada Diaria_${currentDate}`}
          setexportarExcel={setexportarExcel}
        />
      )}

      {exportarWord && (
        <BotonExportarWord
          dataArray={DatosSeleccionados}
          formato="jornada"
          name={`reporte Jornada Diaria_${currentDate}`}
          setexportarWord={setexportarWord}
        />
      )}
      {exportarPdf && (
        <BotonExportarPdf
          dataArray={DatosSeleccionados}
          formato="jornada"
          name={`reporte Jornada Diaria_${currentDate}`}
          setexportarpdf={setexportarPdf}
        />
      )}

      {jobsAreLoading ? (
        <div />
      ) : firstTable.length === 0 ? (
        <div className="mx-auto mt-1" style={{ maxWidth: '70rem' }}>
          <TablaNoExisteReporteJornadaDiaria />
        </div>
      ) : (
        <>
          <Table
            rowSelection={{
              type: 'checkbox',
              ...rowSelection,
            }}
            rowKey="rut"
            pagination={{ showSizeChanger: true }}
            locale={Labels['tableLabels']}
            dataSource={firstTable}
            columns={TrabajadoresReportesColumns(firstTable)}
            scroll={{ x: 'max-content' }}
          />
          {reporteVisible && DatosSeleccionados.length > 0 ? (
            <div>
              <div
                style={{
                  marginBottom: '10px',
                  display: 'flex',
                  justifyContent: 'center',
                }}>
                <div>
                  <GlosarioCardJornadaDiaria />
                </div>
              </div>
              {DatosSeleccionados.map((record) => {
                return (
                  <div
                    key={record.key}
                    style={{ textAlign: 'center', marginTop: '10px' }}>
                    <TablaJornadaDiariaInfo items={record} />
                    {expandedRowRender(record)}
                    <Divider />
                  </div>
                );
              })}
            </div>
          ) : null}
        </>
      )}
    </div>
  );
}
