import React from 'react';
import styles from './jsonStyles.json';
import TablainfoPersonas from './atoms/TablainfoPersonas';
import sumarRestarHorasExtraordinarias from '../../../utils/sumarRestarHorasExtraordinarias';
import FormatDateV2 from '../../../utils/FormatDateV2';
import dayjs from 'dayjs';
import logoApp from '../../../assets/Xinerlink.png';
import TablaGlosarioJornada from './atoms/TablaGlosarioJornada';

export default function FormatoReporteJornada({ dataArray }) {
  return (
    <div style={styles.tableReponsive}>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <picture>
          <img
            src={logoApp}
            alt="logo App"
            style={{ maxWidth: '200px', height: 'auto', marginRight: '20px' }}
          />
        </picture>
        <br />
      </div>
      <h3 style={{ font: ' 8pt Arial' }}>
        <strong>Reporte de Jornada Diaria</strong>
      </h3>
      <br />
      <div className="table-responsive">
        <TablaGlosarioJornada />
      </div>
      <br />
      <p style={{ font: '8pt Arial', textAlign: 'start' }}>
        Periodo consultado: {dayjs(dataArray.desde).format('DD-MM-YY')} al{' '}
        {dayjs(dataArray.hasta).format('DD-MM-YY')}
      </p>
      {dataArray.map((item) => (
        <div key={item.key} id={item.key} className="table-responsive">
          <br />
          <br />
          <TablainfoPersonas item={item}></TablainfoPersonas>

          {item.reporte.map((task) => (
            <table style={styles.table}>
              <tr
                style={{
                  backgroundColor: 'white',
                  color: 'blue',
                  border: '1px solid',
                  height: '50px',
                  font: ' 8pt Arial',
                }}>
                <th style={styles.td2}>Fecha</th>
                <th style={styles.td2}>J.O. Pactada</th>
                <th style={styles.td2}>J.O. Real</th>
                <th style={styles.td2}>Colación Pactada</th>
                <th style={styles.td2}>Colación Real</th>
                <th style={styles.td2}>Anomalias</th>
                <th style={styles.td2}>Horas Extraordinarias</th>
                <th style={styles.td2}>Observaciones</th>
              </tr>
              {task.map((element, index) => (
                <tr
                  key={index}
                  style={{
                    border: '1px solid',
                    height: 'auto',
                    font: ' 8pt Arial',
                  }}>
                  <td style={element.comentarios ? styles.tdRed : styles.td2}>
                    {FormatDateV2(element.key).split('T')[0]}
                  </td>
                  <td style={element.comentarios ? styles.tdRed : styles.td2}>
                    <div className="aling-center">
                      <div>
                        {element.jornada !== null ? (
                          <p>
                            {
                              FormatDateV2(
                                element.jornada.entrada,
                                'jornada'
                              ).split('T')[1]
                              /* .slice(0, 5) */
                            }{' '}
                            -{' '}
                            {
                              FormatDateV2(
                                element.jornada.salida,
                                'jornada'
                              ).split('T')[1]
                              /* .slice(0, 5) */
                            }
                          </p>
                        ) : (
                          <p> - </p>
                        )}
                      </div>
                    </div>
                  </td>
                  <td style={element.comentarios ? styles.tdRed : styles.td2}>
                    <div className="aling-center">
                      <p>
                        {
                          FormatDateV2(
                            element.jornadaReal.entrada,
                            'marca'
                          ).split('T')[1]
                        }{' '}
                        -{' '}
                        {
                          FormatDateV2(
                            element.jornadaReal.salida,
                            'marca'
                          ).split('T')[1]
                        }
                      </p>
                    </div>
                  </td>
                  <td style={element.comentarios ? styles.tdRed : styles.td2}>
                    {' '}
                    {element.colacion === 'No aplica' ? (
                      <div>
                        <p>{element.colacion}</p>
                      </div>
                    ) : (
                      <div>
                        <p>
                          {
                            FormatDateV2(
                              element.colacion.entradaColacion,
                              'marca'
                            ).split('T')[1]
                          }{' '}
                          -{' '}
                          {
                            FormatDateV2(
                              element.colacion.salidaColacion,
                              'marca'
                            ).split('T')[1]
                          }
                        </p>
                      </div>
                    )}
                  </td>
                  <td style={element.comentarios ? styles.tdRed : styles.td2}>
                    {' '}
                    {element.colacionReal === 'No aplica' ? (
                      <div>
                        <p>{element.colacionReal}</p>
                      </div>
                    ) : (
                      <div>
                        <p>
                          {
                            FormatDateV2(
                              element.colacionReal.entradaColacion,
                              'marca'
                            ).split('T')[1]
                          }{' '}
                          -{' '}
                          {
                            FormatDateV2(
                              element.colacionReal.salidaColacion,
                              'marca'
                            ).split('T')[1]
                          }
                        </p>
                      </div>
                    )}
                  </td>
                  <td style={element.comentarios ? styles.tdRed : styles.td2}>
                    <div className="aling-center">
                      {element.anomalias.totalAnomalias === '00:00:00' ? (
                        <div>
                          <p>No se registran anomalías</p>
                        </div>
                      ) : (
                        <div>
                          {element.anomalias.atrasoEntrada ? (
                            <p>
                              AT Inició J.O.:{' '}
                              {
                                FormatDateV2(
                                  element.anomalias.atrasoEntrada.split(' ')[1]
                                ).split('T')[1]
                              }
                            </p>
                          ) : (
                            <p> </p>
                          )}
                          {element.anomalias.salidaAnticipadaColacion ? (
                            <p>
                              SA Colación:{' '}
                              {
                                FormatDateV2(
                                  element.anomalias.salidaAnticipadaColacion.split(
                                    ' '
                                  )[1]
                                ).split('T')[1]
                              }
                            </p>
                          ) : (
                            <p> </p>
                          )}
                          {element.anomalias.atrasoColacion ? (
                            <p>
                              AT retorno Colación:{' '}
                              {
                                FormatDateV2(
                                  element.anomalias.atrasoColacion.split(' ')[1]
                                ).split('T')[1]
                              }
                            </p>
                          ) : (
                            <p> </p>
                          )}
                          {element.anomalias.salidaAnticipada ? (
                            <p>
                              SA terminó J.O.:{' '}
                              {
                                FormatDateV2(
                                  element.anomalias.salidaAnticipada.split(
                                    ' '
                                  )[1]
                                ).split('T')[1]
                              }
                            </p>
                          ) : (
                            <p> </p>
                          )}
                        </div>
                      )}
                    </div>
                  </td>
                  <td style={element.comentarios ? styles.tdRed : styles.td2}>
                    {element.horasExtraordinarias !== null ? (
                      <span> {element.horasExtraordinarias} </span>
                    ) : (
                      <span> 00:00:00 </span>
                    )}
                  </td>
                  <td style={element.comentarios ? styles.tdRed : styles.td2}>
                    {element.comentarios}
                  </td>
                </tr>
              ))}

              <tr style={{ height: '60px' }}>
                <td style={styles.tdtotales}>Total Semanales:</td>

                <td style={styles.td2}>
                  {' '}
                  {sumarRestarHorasExtraordinarias.sumarHorasJOPactada(task)}
                </td>

                <td style={styles.td2}>
                  {' '}
                  {sumarRestarHorasExtraordinarias.sumarHorasJOReal(task)}
                </td>
                <td style={styles.td2}>
                  {' '}
                  {sumarRestarHorasExtraordinarias.sumarHorasColacionTotales(
                    task
                  )}
                </td>
                <td style={styles.td2}>
                  {' '}
                  {sumarRestarHorasExtraordinarias.sumarHorasColacionRealesTotales(
                    task
                  )}
                </td>
                <td style={styles.td2}>
                  {' '}
                  {sumarRestarHorasExtraordinarias.sumarHorasAnomaliasTotales(
                    task
                  )}
                </td>
                <td style={styles.td2}>
                  {' '}
                  {sumarRestarHorasExtraordinarias.sumarHorasExtrasTotales(
                    task
                  )}
                </td>
                <td style={styles.td2}>
                  {' '}
                  {sumarRestarHorasExtraordinarias.calcularCompensacion(
                    task
                  )[0] === 'A'
                    ? `Anomalías significativas: ${sumarRestarHorasExtraordinarias
                        .calcularCompensacion(task)
                        .substring(1)}`
                    : sumarRestarHorasExtraordinarias.calcularCompensacion(
                        task
                      ) === '00:00:00'
                    ? `No hay compensación: ${sumarRestarHorasExtraordinarias.calcularCompensacion(
                        task
                      )}`
                    : `Compensación: ${sumarRestarHorasExtraordinarias.calcularCompensacion(
                        task
                      )}`}
                </td>
              </tr>
            </table>
          ))}
        </div>
      ))}
    </div>
  );
}
