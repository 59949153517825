import React from 'react';
import { Col, Card /*, Image*/ } from 'antd';
import { Typography } from 'antd';
// import svgDocumentos from '../../../assets/document.svg';
import dayjs from 'dayjs';

const { Text } = Typography;
export default function CartaHash({ marca, title, justificacion }) {
  // console.log(marca);
  /* if (!marca) {
    return (
      <Col span={10}>
        <Card title={title} bordered={false}>
          <Image width={100} height={50} src={svgDocumentos} preview={false} />
          <p>El Hash no se encuentra Registrado</p>
        </Card>
      </Col>
    ); 
  } else {*/
  return (
    <Col span={10} offset={5} style={{ marginTop: '20px' }}>
      <Card
        title={title}
        bordered={false}
        style={{ maxWidth: '400px' }}
        headStyle={{ textAlign: 'center' }}>
        <p>
          <b>Fecha Marcaje: </b>
          {dayjs(`${marca.fechaMarcaje}Z`).format('DD/MM/YY HH:mm:ss')}
        </p>
        <p>
          <b>Hash: </b>
          <Text>{marca.hash || ''}</Text>
        </p>
        <p>
          <b>Ubicación: </b>
          {justificacion.ubicacion || ''}
        </p>
        <p>
          <b>Ubicación Marcada: </b>
          {marca.ubicacionMarcada || ''}
        </p>
        <p>
          <b>Justificación: </b>
          {justificacion.justificacion || 'sin Justificación'}
        </p>
      </Card>
    </Col>
  );
  //}
}
