import {
    AlertOutlined,
    AppstoreAddOutlined,
    CheckCircleOutlined,
    FileDoneOutlined,
    FileSearchOutlined,
    FileOutlined,
    BellOutlined,
    AuditOutlined,
    CopyOutlined
} from '@ant-design/icons';


const reportesDT = [

    {
        key: '/reportes/asistencia',
        icon: <FileSearchOutlined />,
        label: <> <ul style={{ lineHeight: '1.5' }}>Reporte de</ul>
            <ul style={{ lineHeight: '1.5' }}>asistencia</ul>
        </>
    },
    {
        key: '/reportes/jornada-diaria',
        icon: <FileSearchOutlined />,
        label: (
            <>
                <ul style={{ lineHeight: '1.5' }}>Reporte de</ul>
                <ul style={{ lineHeight: '1.5' }}>jornada díaria</ul>
            </>
        ),
    },
    {
        key: '/reportes/domingos-festivos',
        icon: <FileSearchOutlined />,
        label: (
            <>
                <ul style={{ lineHeight: '1.5' }}>Reporte de</ul>
                <ul style={{ lineHeight: '1.5' }}>domingos</ul>
                <ul style={{ lineHeight: '1.5' }}>y/o festivos</ul>
            </>
        ),
        style: { height: '100%' },
    },
    {
        key: '/reportes/modificaciones',
        icon: <FileSearchOutlined />,
        label: (
            <>
                <ul style={{ lineHeight: '1.5' }}>Reporte de</ul>
                <ul style={{ lineHeight: '1.5' }}>modificaciones y/o</ul>
                <ul style={{ lineHeight: '1.5' }}>alteraciones</ul>
                <ul style={{ lineHeight: '1.5' }}>de turnos </ul>
            </>
        ),
        style: { height: '100%', fontSize: '' },
    },
    {
        key: '/reportes/validarHash',
        icon: <CheckCircleOutlined />,
        label: (
            <>
                <ul style={{ lineHeight: '1.5' }}>Validador</ul>
                <ul style={{ lineHeight: '1.5' }}>de Hash</ul>
            </>
        ),
        style: { height: '100%' },
    },
];

/* const incidenciasDT = [
    {
        key: 'incidencias',
        icon: <AlertOutlined />,
        label: 'Incidencias',
        children: [
            {
                key: '/incidencias/crear-incidencia',
                icon: < EditOutlined />,
                label: <><ul style={{ lineHeight: '1.5' }}>Crear nueva</ul>
                    <ul style={{ lineHeight: '1.5' }}>Incidencia</ul></>
            },
            {
                key: '/incidencias/ver-incidencias',
                icon: <InfoCircleOutlined />,
                label: <>
                    <ul style={{ lineHeight: '1.5' }}>Consultar</ul>
                    <ul style={{ lineHeight: '1.5' }}>Incidencias</ul>
                </>

            },
        ],
    },
]; */
const incidenciasDT = [
    {
        key: '/incidencias/buscar-incidentes-tecnicos',
        icon: <AlertOutlined />,
        label:
            <>
                <ul style={{ lineHeight: '1.5' }}>Reporte de</ul>
                <ul style={{ lineHeight: '1.5' }}>Incidentes</ul>
                <ul style={{ lineHeight: '1.5' }}>Técnicos</ul>
            </>,
        style: { height: '100%', fontSize: '' },
    },
];

const justificacionDT = [
    {
        key: 'justificar',
        icon: <AppstoreAddOutlined />,
        label: 'Justificaciones',
        children: [
            {
                key: '/justificar-marca',
                icon: <CheckCircleOutlined />,
                label: <>
                    <ul style={{ lineHeight: '1.5' }}>Justificar</ul>
                    <ul style={{ lineHeight: '1.5' }}>Asistencia</ul>
                </>,
                style: { height: '100%', fontSize: '' },
            },
        ],
    },
];

const documentos = [{
    key: '/documentos',
    icon: <FileOutlined />,
    label: <ul style={{ lineHeight: '1.5' }}>Documentos</ul>
},

]


const solicitudes = [
    {
        key: 'solicitudes',
        icon: <FileDoneOutlined />,
        label: 'Solicitudes',
        children: [
            {
                key: '/solicitudes/feriado',
                icon: <AuditOutlined />,
                label: <>
                    <ul style={{ lineHeight: '1.5' }}>Uso</ul>
                    <ul style={{ lineHeight: '1.5' }}>de feriados</ul>
                </>,
                style: { height: '100%', fontSize: '' },
            },
            {
                key: '/solicitudes/permisos',
                icon: <AuditOutlined />,
                label: <>
                    <ul style={{ lineHeight: '1.5' }}>Permisos</ul>

                </>,
                style: { height: '100%', fontSize: '' },
            },
            {
                key: '/documentos/compensacion-dias',
                icon: <AuditOutlined />,
                label: <>
                    <ul style={{ lineHeight: '1.5' }}>Compensación de</ul>
                    <ul style={{ lineHeight: '1.5' }}>días</ul>
                </>,
                style: { height: '100%', fontSize: '' },
            },
        ],
    },
];
const menu = [reportesDT, incidenciasDT, justificacionDT, documentos, solicitudes];

export default menu;


