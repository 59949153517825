/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getUsers = /* GraphQL */ `
  query GetUsers($email: String!) {
    getUsers(email: $email) {
      email
      password
      lastLogin
      typeRole
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $email: String
    $filter: ModelUsersFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listUsers(
      email: $email
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        email
        password
        lastLogin
        typeRole
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getRegistroIncidencias = /* GraphQL */ `
  query GetRegistroIncidencias($incidenciaId: String!) {
    getRegistroIncidencias(incidenciaId: $incidenciaId) {
      incidenciaId
      fecha
      trabajadorId
      empresaId
      tipoIncidencia
      pais
      descripcion
      estadoIncidencia
      tipoMarca
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listRegistroIncidencias = /* GraphQL */ `
  query ListRegistroIncidencias(
    $incidenciaId: String
    $filter: ModelRegistroIncidenciasFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listRegistroIncidencias(
      incidenciaId: $incidenciaId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        incidenciaId
        fecha
        trabajadorId
        empresaId
        tipoIncidencia
        pais
        descripcion
        estadoIncidencia
        tipoMarca
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
