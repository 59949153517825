// @ts-nocheck
import React, { createContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import Loading from '../components/loading/Loading';

export const AuthContext = createContext();

export default function AuthProvider({ children }) {
  const [user, setUser] = useState(null);
  const [empresaID, setEmpresaID] = useState('');
  const navigate = useNavigate();
  const [isCheckToken, setIsCheckToken] = useState(true);
  const [currentUserRole, setCurrentUserRole] = useState(null);


  const [userDefault, setUserDefault] = useState({
    username: '',
    password: '',
  });

  useEffect(() => {
    checkToken();
    //eslint-disable-next-line
  }, []);

  /**
   * It checks if the user is logged in, if so, it sets the user's
   * session token in cookie and sets the user's data in the state
   */
  const checkToken = async () => {
    try {
      const cookieName = 'sessionCookie';
      const cookieValue = getCookieValue(cookieName);
      if (cookieValue) {
        const cookieData = JSON.parse(cookieValue);
        setUser(cookieData);
        setCurrentUserRole(cookieData.rol);
      } else {
        logoutUser();
      }
    } catch (error) {
      console.error(error);
      logoutUser();
    } finally {
      setTimeout(() => {
        setIsCheckToken(false);
      }, 3000);

    }
  };

  /**
   * This function takes in a username and password, and returns
   * a user object.
   * @param values - { username: string; password: string; }
   * @returns The user object.
   */
  const loginUser = async (
    /** @type {{ username: string; password: string; }} */ values
  ) => {
    const login = await Auth.signIn(values.username, values.password);
    const token = login.signInUserSession.accessToken.jwtToken;


    if (values.typeRole === 'dt' || currentUserRole === 'dt') {

      if (values.typeRole) {
        setCookie(token, login.attributes?.email, values.typeRole, '', 'Fiscalizador DT', false);
      } else {
        setCookie(token, login.attributes?.email, currentUserRole, '', 'Fiscalizador DT', false);
      }
    }
    if (currentUserRole === 'empresa' || currentUserRole === 'est') {
      setCookie(token, login.attributes?.email, currentUserRole, values.empresaId, values.nombre, values.estSubcontratista);
    }
    if (currentUserRole === 'trabajador') {
      setCookie(token, login.attributes?.email, currentUserRole, '', values.nombre, false);
    }

    const cookieName = 'sessionCookie';
    const cookieValue = getCookieValue(cookieName);
    const cookieData = JSON.parse(cookieValue);


    setUser(cookieData);

    return user;
  };

  /**
   * It takes an object with an email property, converts the email
   * to lowercase, and then calls the Auth.forgotPassword() method
   * @param values - The values of the form.
   * @returns The return value is a promise.
   */
  const requestNewPassword = async (
    /** @type {{ email: string; }} */ values
  ) => {
    const username = values.email.toLowerCase();
    return await Auth.forgotPassword(username);
  };

  /**
   * This function takes in an object with three properties, email, code,
   * and password, and returns a promise that resolves to the result of
   * the Auth.forgotPasswordSubmit function.
   * @param values - { email: string; code: any; password: any; }
   * @returns The return value is a promise.
   */
  const confirmNewPassword = async (
    /** @type {{ email: string; code: any; password: any; }} */ values
  ) => {
    const username = values.email.toLowerCase();
    const code = values.code;
    const password = values.password;
    return await Auth.forgotPasswordSubmit(username, code, password);
  };

  /**
   * This function takes the email address from the form and sends
   * a confirmation code to the user.
   * @param values - The values of the form.
   * @returns The resendSignUp method returns a promise.
   */
  const resendConfirmation = async (values) => {
    const username = values.email.toLowerCase();
    return await Auth.resendSignUp(username);
  };

  /**
   * This function logs out the user and redirects them
   * to the login page.
   */
  const logoutUser = async () => {
    await Auth.signOut();
    setCurrentUserRole(null);
    goToLogin();
  };

  /**
   * It removes the sessionToken from localStorage
   * and navigates to the /LoginSelector route.
   */
  const goToLogin = () => {
    deleteCookie();
    setUser(null);
    setCurrentUserRole(null);
    navigate('/auth');
  };

  /**
   * This function sets a cookie in the user's browser 
   * with specific information, such as a session token,
   * email address, role, company ID, and name.
   * @param {*} token 
   * @param {*} correo 
   * @param {*} rol 
   * @param {*} empresaId 
   * @param {*} nombre 
   */
  const setCookie = (token, correo, rol, empresaId, nombre, estSubcontratista) => {

    const cookieData = {
      sessionToken: token,
      empresaId: empresaId,
      correo: correo,
      rol: rol,
      nombre: nombre,
      estSubcontratista: estSubcontratista
    };

    const cookieValue = JSON.stringify(cookieData);
    const expirationMinutes = 60;
    const expirationDate = new Date();
    expirationDate.setTime(expirationDate.getTime() + (expirationMinutes * 60 * 1000));
    const formattedExpiration = expirationDate.toUTCString();
    document.cookie = `sessionCookie=${encodeURIComponent(cookieValue)}; expires=${formattedExpiration}; path=/`;
  }

  /**
   * This function retrieves the value of a specific cookie by its name from the user's browser
   * @param {*} cookieName 
   * @returns 
   */
  const getCookieValue = (cookieName) => {
    const cookies = document.cookie.split(';');


    const foundCookie = cookies.map(cookie => {
      const trimmedCookie = cookie.trim();

      if (trimmedCookie.startsWith(`${cookieName}=`)) {

        const cookieValue = trimmedCookie.substring(cookieName.length + 1);
        return decodeURIComponent(cookieValue);
      }
      return null;
    }).find(cookie => cookie !== null);

    return foundCookie;
  }
  /**
   * This function removes a specific cookie from the user's browser. 
   */
  const deleteCookie = () => {
    const cookieName = 'sessionCookie';
    document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
  }

  const contextValue = {
    user,
    loginUser,
    logoutUser,
    requestNewPassword,
    confirmNewPassword,
    resendConfirmation,
    empresaID,
    setEmpresaID,
    currentUserRole,
    setCurrentUserRole,
    userDefault,
    setUserDefault,
    sessionToken: localStorage.getItem('sessionToken'),
  };

  return (
    <AuthContext.Provider value={contextValue}>
      {isCheckToken ? <Loading /> : children}
    </AuthContext.Provider>
  );
}
