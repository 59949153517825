import React, { useEffect, useState } from 'react';
import PageHeaderReportes from '../../components/headers/PageHeaderReportes';
import { Select, Card, Form, Button, message, DatePicker } from 'antd';

import TablaJustificacionAsistencias from './components/TablaJustificacionAsistencias';
// import FormarDateLocalTimeZone from '../../altUtils/FormarDateLocalTimeZone';
import useAuth from '../../hooks/useAuth';
import dtService from '../../services/dtService';
import dayjs from 'dayjs';
import locale from 'antd/es/date-picker/locale/es_ES';

export default function JustificarMarca() {
  const { user } = useAuth();
  const [resgistroAsistencias, setRegistroAsistencias] = useState([]);
  const [loading, setLoading] = useState(true);
  const [form] = Form.useForm();
  const [trabajadores, setTrabajadores] = useState([]);
  const [trabajadorLoading, setTrabajadorLoading] = useState(true);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [correo, setCorreo] = useState('');
  const [fechaFin, setFechaFin] = useState(null);
  const [fechaInicio, setFechaInicio] = useState(null);

  const AjusteArray = (data) => {
    const arraySolucion = [];
    if (data.length !== 0) {
      data.forEach((obj) => {
        const nexElement = obj;
        nexElement.entradaUtc = obj.entrada;
        nexElement.salidaUtc = obj.salida;
        nexElement.nombreCompleto = `${obj.nombre} ${obj.primerApellido} ${obj.segundoApellido}`;
        nexElement.fechaEntradaTrabajador = obj?.fechaEntradaTrabajador || '';
        nexElement.fechaSalidaTrabajador = obj?.fechaSalidaTrabajador || '';
        nexElement.correoEmpresa = user.correo;
        arraySolucion.push(nexElement);
      });
      setRegistroAsistencias(arraySolucion);
    }
  };

  const verAsitencias = async (correo, fechaIncio, fechaFin) => {
    const [res, err] = await dtService.BuscarAsistenciaTrabajadores(
      correo,
      fechaIncio,
      fechaFin,
      user.empresaId
    );

    if (!err) {
      if (res.data.existe) {
        if (res.data.registro.length !== 0) {
          AjusteArray(res.data.registro);
        }
      }
    } else {
      console.log(err);
      message.error('Error en la solicitud');
    }
    setLoading(false);
    setButtonLoading(false);
  };

  const handleSelectChange = (value) => {
    setCorreo(value);
  };

  useEffect(() => {
    //  let filter = { activo: { eq: true } };

    GetTrabajadores();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const GetTrabajadores = async () => {
    const [res, err] = await dtService.consultarinformacionBuscador(
      'cliente',
      user.empresaId
    );
    if (err) return message.error('Error al obtener los trabajadores');
    else {
      setTrabajadores(res.data.trabajadores);
      setTrabajadorLoading(false);
    }
  };

  const resetFilters = () => {
    form.resetFields();
  };

  const buscar = (v) => {
    setLoading(true);
    setRegistroAsistencias([]);
    setButtonLoading(true);
    const firstDayOfYear = dayjs().startOf('year').format();
    const lastDayOfYear = dayjs().endOf('year').format();
    verAsitencias(
      correo,
      fechaInicio
        ? dayjs(fechaInicio).format('YYYY-MM-DD') + 'T00:00:00'
        : firstDayOfYear,
      fechaFin
        ? dayjs(fechaFin).format('YYYY-MM-DD') + 'T23:59:59'
        : lastDayOfYear
    );
  };

  const fechas = (event) => {
    const dates = event.map((item) => {
      const fecha = new Date(item);
      return fecha;
    });
    const desde = dates[0].toISOString().split('T')[0];
    const hasta = dates[1].toISOString().split('T')[0];
    setFechaInicio(desde);
    setFechaFin(hasta);
  };

  return (
    <div className="p-10">
      <PageHeaderReportes title="Justificar Asistencia " head="" />

      <Card
        title="Ingrese los datos del trabajador al que desea justificar la asistencia"
        extra={<Button onClick={() => resetFilters()}>Limpiar buscador</Button>}
        style={{ marginBottom: '10px' }}>
        <Form
          labelAlign="vertical"
          requiredMark={false}
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 14 }}
          form={form}
          onFinish={buscar}>
          <Form.Item
            name="correo"
            label="Correos"
            rules={[
              {
                required: true,
                message: 'Debe seleccionar al menos un trabajador',
              },
            ]}>
            <Select
              showSearch
              placeholder="Correo"
              size="medium"
              allowClear={true}
              loading={trabajadorLoading}
              onChange={handleSelectChange}
              filterOption={(input, option) =>
                (option?.label ?? '')
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              options={trabajadores.map((trabajador) => ({
                label: trabajador.correo,
                value: trabajador.correo,
                name: 'correo',
              }))}
            />
          </Form.Item>

          <Form.Item name="fechas" label="Fechas" size="medium">
            <DatePicker.RangePicker
              id="fecha"
              name="fecha"
              style={{ width: '100%' }}
              placeholder={['Fecha inicio', 'Fecha fin']}
              onChange={fechas}
              allowClear={true}
              format={'DD/MM/YYYY'}
              locale={locale}
            />
          </Form.Item>

          <Button
            type="primary"
            htmlType="submit"
            block
            loading={buttonLoading}>
            Consultar
          </Button>
        </Form>
      </Card>

      {loading ? (
        <div></div>
      ) : (
        <div>
          <TablaJustificacionAsistencias
            items={resgistroAsistencias}
            loading={loading}
            buscar={buscar}
          />{' '}
        </div>
      )}
    </div>
  );
}
