import React, { useEffect, useState } from 'react';
import { Table } from 'antd';
import TablaJornadaDiariaReporte from './TablaJornadaDiariaReporte';
import consultaJustificacion from '../funtions/consultaJustificacion';
import sumarRestarHorasExtraordinarias from '../../../utils/sumarRestarHorasExtraordinarias';
import Labels from '../../../utils/Labels.json';

export default function CartaRegistrosSemanales({ record }) {
  const getRowClassName = (record) => {
    return consultaJustificacion(record) === 'green'
      ? 'table-cell-green'
      : consultaJustificacion(record) === 'red'
      ? 'table-cell-red'
      : '';
  };

  const [totalHorasJOPactada, setTotalHorasJOPactada] = useState('00:00:00');
  const [totalHorasJOReal, setTotalHorasJOReal] = useState('00:00:00');
  const [totalHorasColacion, setTotalHorasColacion] = useState('00:00:00');
  const [totalHorasColacionReal, setTotalHorasColacionReal] =
    useState('00:00:00');
  const [totalHorasAnomalias, setTotalHorasAnomalias] = useState('00:00:00');
  const [totalHorasExtras, setTotalHorasExtras] = useState('00:00:00');
  const [totalCompensacion, setTotalCompensacion] = useState('00:00:00');
  // console.log(record);
  useEffect(() => {
    setTotalHorasAnomalias(
      sumarRestarHorasExtraordinarias.sumarHorasAnomaliasTotales(record)
    );
    setTotalHorasExtras(
      sumarRestarHorasExtraordinarias.sumarHorasExtrasTotales(record)
    );
    setTotalHorasJOPactada(
      sumarRestarHorasExtraordinarias.sumarHorasJOPactada(record)
    );
    setTotalHorasJOReal(
      sumarRestarHorasExtraordinarias.sumarHorasJOReal(record)
    );
    setTotalHorasColacion(
      sumarRestarHorasExtraordinarias.sumarHorasColacionTotales(record)
    );
    setTotalHorasColacionReal(
      sumarRestarHorasExtraordinarias.sumarHorasColacionRealesTotales(record)
    );
    setTotalCompensacion(
      sumarRestarHorasExtraordinarias.calcularCompensacion(record)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="mx-auto mt-3">
      <Table
        rowClassName={getRowClassName}
        bordered
        columns={TablaJornadaDiariaReporte(record)}
        locale={Labels['tableLabels ']}
        /*  expandable={{
        expandedRowRender,
      }} */
        dataSource={record}
        pagination={false}
        summary={() => (
          <Table.Summary fixed={'bottom'} style={{ marginBottom: 20 }}>
            <Table.Summary.Row>
              <Table.Summary.Cell index={1} colSpan={1} align="center">
                Total
              </Table.Summary.Cell>
              <Table.Summary.Cell index={2} colSpan={1} align="center">
                {totalHorasJOPactada}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={3} colSpan={1} align="center">
                {totalHorasJOReal}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={4} colSpan={1} align="center">
                {totalHorasColacion}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={5} colSpan={1} align="center">
                {totalHorasColacionReal}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={6} colSpan={1} align="center">
                {totalHorasAnomalias === '00:00:00'
                  ? `${totalHorasAnomalias}`
                  : `-${totalHorasAnomalias}`}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={7} colSpan={1} align="center">
                {totalHorasExtras === '00:00:00'
                  ? `${totalHorasExtras}`
                  : `+${totalHorasExtras}`}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={8} colSpan={1} align="center">
                Total marcaciones pasivas: 00:00:00
              </Table.Summary.Cell>
              <Table.Summary.Cell index={9} colSpan={1} align="center">
                {totalCompensacion[0] === 'A'
                  ? `Compensación: -${totalCompensacion.substring(1)}`
                  : totalCompensacion === '00:00:00'
                  ? `Compensación: ${totalCompensacion}`
                  : `Compensación: +${totalCompensacion}`}
              </Table.Summary.Cell>
              <Table.Summary.Cell
                index={10}
                colSpan={1}
                align="center"></Table.Summary.Cell>
            </Table.Summary.Row>
          </Table.Summary>
        )}
      />
    </div>
  );
}
