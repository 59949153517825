
import { Card, Image } from 'antd';
import PageHeader from '../../components/headers/PageHeader';
import logoApp from '../../assets/Xinerlink.png';
import LoginForm from './components/LoginForm';

export default function LoginPage({ currentUserRole }) {

  const role = {
    dt: 'Fiscalizador DT',
    empresa: 'Empresa',
    trabajador: 'Trabajador',
    est: 'EST / Subcontratista',
  };

  const verifyRoleType = (currentUserRole) => {
    return role[currentUserRole];
  };

  return (
    <div className="max-w-md mx-auto">
      <div className="w-56 mx-auto mb-4">
        <Image src={logoApp} preview={false} alt="Logo App" />
      </div>
      <Card>
        <PageHeader
          children={''}
          head="Plataforma para la dirección de trabajo"
          title={`Iniciar Sesión como ${verifyRoleType(currentUserRole)}`}
        />
        <LoginForm currentUserRole={currentUserRole} />
      </Card>
    </div>
  );
}
