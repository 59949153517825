function sumarRestarHorasExtraordinariasArray(array) {



  let totalHoras = array[0].diferenciaHoras;

  for (let i = 1; i < array.length; i++) {
    let diferenciaHoras = array[i].diferenciaHoras;

    if (diferenciaHoras.charAt(0) === '-') {

      totalHoras = restarHoras(totalHoras, diferenciaHoras.substring(1));
    } else {

      totalHoras = sumarHoras(totalHoras, diferenciaHoras);
    }


  }

  return totalHoras;
}

function sumarHorasJOPactada(item) {
  let totalHorasJO = '00:00:00';

  item.forEach((element) => {
    if (!element.jornada) {
      totalHorasJO = sumarHoras(totalHorasJO, '00:00:00');
    } else {
      totalHorasJO = sumarHoras(totalHorasJO, element.jornada.horasJOPactada);
    }
  });
  return totalHorasJO;
}

function sumarHorasJOReal(item) {
  let totalHorasJOReal = '00:00:00';

  item.forEach((element) => {
    if (!element.jornadaReal) {
      totalHorasJOReal = sumarHoras(totalHorasJOReal, '00:00:00');
    } else if (!element.jornadaReal.horasJornadaReal) {
      totalHorasJOReal = sumarHoras(totalHorasJOReal, '00:00:00');
    } else {
      totalHorasJOReal = sumarHoras(
        totalHorasJOReal,
        element.jornadaReal.horasJornadaReal
      );
    }
  });
  return totalHorasJOReal;
}

function sumarHorasColacionTotales(item) {
  let totalHorasColacion = '00:00:00';

  item.forEach((element) => {
    if (element.colacion === 'No aplica') {
      totalHorasColacion = sumarHoras(totalHorasColacion, '00:00:00');
    } else {
      totalHorasColacion = sumarHoras(
        totalHorasColacion,
        element.colacion.horasColacionPactada
      );
    }
  });
  return totalHorasColacion;
}

function sumarHorasColacionRealesTotales(item) {
  let totalHorasColacionReal = '00:00:00';

  item.forEach((element) => {
    if (element.colacionReal === 'No aplica') {
      totalHorasColacionReal = sumarHoras(totalHorasColacionReal, '00:00:00');
    } else {
      totalHorasColacionReal = sumarHoras(
        totalHorasColacionReal,
        element.colacionReal.horasColacionReal
      );
    }
  });
  return totalHorasColacionReal;
}

function sumarHorasAnomaliasTotales(item) {
  let totalHorasAnomalias = '00:00:00';
  item.forEach((element) => {
    if (element.anomalias.totalAnomalias === null) {
      totalHorasAnomalias = sumarHoras(totalHorasAnomalias, '00:00:00');
    } else {
      totalHorasAnomalias = sumarHoras(
        totalHorasAnomalias,
        element.anomalias.totalAnomalias
      );
    }
  });
  return totalHorasAnomalias;
}

function sumarHorasExtrasTotales(item) {
  let totalHorasExtra = '00:00:00';
  item.forEach((element) => {
    if (!element.horasExtraordinarias) {
      totalHorasExtra = sumarHoras(totalHorasExtra, '00:00:00');
    } else {
      totalHorasExtra = sumarHoras(
        totalHorasExtra,
        element.horasExtraordinarias
      );
    }
  });

  return totalHorasExtra;
}

function calcularCompensacion(item) {
  const totalAnomalias = sumarHorasAnomaliasTotales(item);
  const totalHorasExtra = sumarHorasExtrasTotales(item);

  const totalCompensacion = restarHoras(totalHorasExtra, totalAnomalias);
  return totalCompensacion;
}

function sumarHoras(hora1, hora2) {
  let [h1, m1, s1] = hora1.split(':').map(Number);
  let [h2, m2, s2] = hora2.split(':').map(Number);
  let segundos = s1 + s2;
  let minutos = m1 + m2;
  let horas = h1 + h2;
  if (segundos >= 60) {
    minutos++;
    segundos -= 60;
  }
  if (minutos >= 60) {
    horas++;
    minutos -= 60;
  }
  return formatoHora(horas, minutos, segundos);
}
function restarHoras(hora1, hora2) {
  const date1 = new Date(`2024-02-09T${hora1}`);
  const date2 = new Date(`2024-02-09T${hora2}`);
  let horaFormateada = '';
  let diferenciaHoras;

  if (date1 > date2) {
    diferenciaHoras = date1.getTime() - date2.getTime();

    horaFormateada = msAHHMMSS(diferenciaHoras);
  } else if (date1 === date2) {
    horaFormateada = msAHHMMSS(0);
  } else {
    diferenciaHoras = date2.getTime() - date1.getTime();

    if (diferenciaHoras === 0) {
      horaFormateada = msAHHMMSS(0);
    } else {
      horaFormateada = msAHHMMSS(diferenciaHoras);
      horaFormateada = `A${horaFormateada}`;
    }
  }
  return horaFormateada;
}
function msAHHMMSS(milisegundos) {
  const segundos = Math.floor(milisegundos / 1000);

  const horas = Math.floor(segundos / 3600);
  const minutos = Math.floor((segundos % 3600) / 60);
  const segundosRestantes = segundos % 60;

  const formatoHora = horas.toString().padStart(2, '0');
  const formatoMinuto = minutos.toString().padStart(2, '0');
  const formatoSegundo = segundosRestantes.toString().padStart(2, '0');

  return formatoHora + ':' + formatoMinuto + ':' + formatoSegundo;
}


function formatoHora(horas, minutos, segundos) {
  return `${formatoNumero(horas)}:${formatoNumero(minutos)}:${formatoNumero(
    segundos
  )}`;
}

function formatoNumero(numero) {
  return Math.abs(numero) < 10 ? `0${numero}` : `${numero}`;
}

const sumarRestarHorasExtraordinarias = {
  sumarRestarHorasExtraordinariasArray,
  sumarHoras,
  restarHoras,
  formatoHora,
  sumarHorasJOPactada,
  sumarHorasJOReal,
  sumarHorasColacionTotales,
  sumarHorasColacionRealesTotales,
  sumarHorasAnomaliasTotales,
  sumarHorasExtrasTotales,
  calcularCompensacion,
};

export default sumarRestarHorasExtraordinarias;
