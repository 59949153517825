import React, { useEffect, useState } from 'react';
import { Row, Col, Form, Input, DatePicker, Button, Table } from 'antd';
import Labels from '../../../utils/Labels';
import TrabajadoresColumns from './TrabajadoresColumns';
import useAuth from '../../../hooks/useAuth';
import dtService from '../../../services/dtService';
import PageHeader2 from '../../../components/headers/PageHeader2';

export default function TablaTrabajadores() {
  const [loading, setLoading] = useState(false);
  const [trabajadores, setTrabajadores] = React.useState([]);
  const { empresaID } = useAuth();

  const Verobjetos = async () => {
    setLoading(true);
    const [res, err] = await dtService.consultarinformacionBuscador(
      'cliente',
      empresaID?.empresaId || ''
    );

    if (!err) {
      const datosTrabajador = res.data.trabajadores || [];

      if (datosTrabajador.length > 0) {
        const trabajadoresObj = datosTrabajador.map((trabajador) => {
          return {
            correo: trabajador.correo,
            nombre: trabajador.nombre.split(' - ')[0],
            rut: trabajador.nombre.split(' - ')[1],
          };
        });
        setTrabajadores(trabajadoresObj);
        setLoading(false);
      } else {
        setTrabajadores([]);
        setLoading(false);
      }
    } else {
      console.log(err);
      setLoading(false);
    }
  };

  useEffect(() => {
    Verobjetos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <PageHeader2 head="" title="Crear Documentos" />
      <Table
        loading={loading}
        rowKey="rut"
        pagination={{ showSizeChanger: true }}
        locale={Labels['tableLabels']}
        dataSource={trabajadores}
        columns={TrabajadoresColumns(trabajadores)}
        scroll={{ x: 'max-content' }}
      />
    </div>
  );
}
