import React from 'react';
import getOptions from '../../../utils/getOptions';
import { Space } from 'antd';
import dayjs from 'dayjs';
import MenuAccionesFirmar from '../../../components/accionesDocumentos/MenuAccionesFirmar.jsx';

// import MenuAcciones from '../Xinerlink/MenuAcciones';

export default function DocumentosTrabajadoresColumns(
  items = [],
  openedRecord,
  setOpenedRecord
) {
  const ordenados = Array.isArray(items)
    ? items.sort(
        (a, b) =>
          parseInt(a.pais_consecutivoOferta) -
          parseInt(b.pais_consecutivoOferta)
      )
    : [];

  return [
    {
      fixed: 'left',
      title: 'Nro Oferta',
      width: 100,
      dataIndex: 'pais_consecutivoOferta',
      key: 'pais_consecutivoOferta',
      filterSearch: true,
      filters: getOptions(ordenados, 'pais_consecutivoOferta'),
      onFilter: (v, record) => record.pais_consecutivoOferta.indexOf(v) === 0,
    },
    {
      title: 'Nombre del trabajador',
      dataIndex: 'nombreTrabajador',
      key: 'nombreTrabajador',
      align: 'center',
      filterSearch: true,
      filters: getOptions(ordenados, 'nombreTrabajador'),
      onFilter: (v, record) => record.nombreTrabajador.indexOf(v) === 0,
    },
    {
      title: 'RUT',
      dataIndex: 'rutTrabajador',
      key: 'rutTrabajador',
      align: 'center',
      filterSearch: true,
      filters: getOptions(ordenados, 'rutTrabajador'),
      onFilter: (v, record) => record.rutTrabajador.indexOf(v) === 0,
    },
    {
      title: 'Correo',
      dataIndex: 'trabajadorId',
      key: 'trabajadorId',
      align: 'center',
      filterSearch: true,
      filters: getOptions(ordenados, 'trabajadorId'),
      onFilter: (v, record) => record.trabajadorId.indexOf(v) === 0,
    },
    {
      title: 'Tipo de Documento',
      dataIndex: 'tipodeDocumento',
      key: 'tipodeDocumento',
      align: 'center',
      filterSearch: true,
      filters: getOptions(ordenados, 'tipodeDocumento'),
      onFilter: (v, record) => record.tipodeDocumento.indexOf(v) === 0,
      render: (text, record) =>
        text === 'anexo' ? (
          <Space>Anexo</Space>
        ) : text === 'pactoHE' ? (
          <Space>Pacto Hora Extra</Space>
        ) : (
          <Space></Space>
        ),
    },
    {
      title: 'ID Contrado Asociado',
      dataIndex: 'contratoId',
      key: 'contratoId',
      align: 'center',
      filterSearch: true,
      filters: getOptions(ordenados, 'contratoId'),
      onFilter: (v, record) => record.contratoId.indexOf(v) === 0,
    },
    {
      title: 'Cargo',
      dataIndex: 'cargo',
      key: 'cargo',
      align: 'center',
      filterSearch: true,
      filters: getOptions(ordenados, 'cargo'),
      onFilter: (v, record) => record.cargo.indexOf(v) === 0,
    },
    {
      title: 'Ubicación',
      dataIndex: 'ubicacion',
      key: 'ubicacion',
      align: 'center',
      filterSearch: true,
      filters: getOptions(ordenados, 'ubicacion'),
      onFilter: (v, record) => record.ubicacion.indexOf(v) === 0,
    },
    {
      title: 'Fecha Solicitud',
      dataIndex: 'fechaSolicitud',
      align: 'center',
      key: 'fechaSolicitud',
      filterSearch: true,
      filters: getOptions(ordenados, 'fechaSolicitud'),
      onFilter: (v, record) => record.fechaSolicitud.indexOf(v) === 0,
      render: (text, record) => (
        <Space>{dayjs(new Date(text)).format('DD/MM/YY')}</Space>
      ),
    },
    {
      title: 'Fecha Inicio Documento',
      dataIndex: 'fechaInicioDocumento',
      align: 'center',
      key: 'fechaInicioDocumento',
      filterSearch: true,
      filters: getOptions(ordenados, 'fechaInicioDocumento'),
      onFilter: (v, record) => record.fechaInicioDocumento.indexOf(v) === 0,
      render: (text, record) => (
        <Space>{dayjs(new Date(text)).format('DD/MM/YY')}</Space>
      ),
    },
    {
      title: 'Fecha Fin Documento',
      dataIndex: 'fechaFinDocumento',
      align: 'center',
      key: 'fechaFinDocumento',
      filterSearch: true,
      filters: getOptions(ordenados, 'fechaFinDocumento'),
      onFilter: (v, record) => record.fechaFinDocumento.indexOf(v) === 0,
      render: (text, record) => (
        <Space>{dayjs(new Date(text)).format('DD/MM/YY')}</Space>
      ),
    },
    {
      title: 'Motivo del Documento',
      dataIndex: 'motivoDocumento',
      align: 'center',
      key: 'motivoDocumento',
      filterSearch: true,
      filters: getOptions(ordenados, 'motivoDocumento'),
      onFilter: (v, record) => record.motivoDocumento.indexOf(v) === 0,
    },
    {
      title: 'Estado de Firma',
      dataIndex: 'estadoActualDocumento',
      key: 'estadoActualDocumento',
      align: 'center',
      filterSearch: true,
      filters: getOptions(ordenados, 'estadoActualDocumento'),
      onFilter: (v, record) => record.estadoActualDocumento.indexOf(v) === 0,
    },
    {
      title: 'Acciones',
      align: 'center',
      fixed: 'right',

      render: (text, record) => (
        <Space>
          <MenuAccionesFirmar record={record} />
        </Space>
      ),
    },
  ];
}
