import React from 'react';
import { useState } from 'react';
import { Button } from 'antd';
import PageHeader from '../../components/headers/PageHeader';
import FormEnviarNotificaciones from './components/FormEnviarNotificaciones';
import { useLocation, useNavigate } from 'react-router-dom';
import BotonGoBack from '../../components/botones/BotonGoBack';
export default function Notificaciones() {
  const [hashTable, setHashTable] = useState([]);
  const [hashAreLoading, setHashAreLoading] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const { trabajador } = location.state || {};

  console.log('trabajador', trabajador);
  return (
    <div>
      <PageHeader head="" title="Notificaciones" />
      <BotonGoBack path={`/documentos`} />

      <FormEnviarNotificaciones trabajador={trabajador} />
    </div>
  );
}
