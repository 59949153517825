import React, { useState, useEffect } from 'react';
import {
  Form,
  Input,
  /*  Select,
  Radio,
  DatePicker, */
  Button,
  message,
  Card,
} from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import dtService from '../../services/dtService';
import useAuth from '../../hooks/useAuth';

export default function BuscadorPorHash(props) {
  const { empresaID } = useAuth();
  const [form] = Form.useForm();
  const [buttonLoading, setButtonLoading] = useState(false);

  const buscadorPorHash = async (hash) => {
    try {
      const [res, err] = await dtService.BuscarHash(
        hash,
        empresaID?.empresaId || ''
      );
      if (!err) {
        const respuesta = res.data;
        return respuesta;
      } else {
        props.setHashAreLoading(true);
      }
    } catch (err) {
      message.error('Error en la función buscadorPorHash:', err);
    }
  };

  const handleSubmit = async (values) => {
    setButtonLoading(true);
    props.setHashAreLoading(true);
    try {
      if (!values.hash) {
        message.error('Ingrese un hash que desea validar y consultar');
        setButtonLoading(false);
      } else {
        let respuesta = await buscadorPorHash(values.hash);
        // console.log(respuesta);
        if (
          typeof respuesta === 'object' &&
          Object.keys(respuesta).length === 0
        ) {
          props.setHashTable(null);
          props.setHashAreLoading(false);
        } else {
          props.setHashTable([respuesta]);
          props.setHashAreLoading(false);
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setButtonLoading(false);
    }
  };
  const resetFilters = () => {
    form.resetFields();
  };
  return (
    <Card
      title="Ingrese el hash que desea validar y consultar"
      extra={<Button onClick={() => resetFilters()}>Limpiar buscador</Button>}
      style={{ marginBottom: '10px' }}>
      <Form
        form={form}
        layout="horizontal"
        requiredMark={false}
        onFinish={handleSubmit}
        labelCol={{
          span: 5,
        }}
        wrapperCol={{
          span: 19,
        }}>
        <Card
          title={<strong>Validar Hash</strong>}
          headStyle={{ textAlign: 'center' }}
          style={{ marginBottom: '20px' }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}>
            <Form.Item
              //     hidden={!CosultaResolucion(window.outerWidth) || false}
              name="hash"
              label="Hash"
              style={{ width: '80%' }}>
              <Input
                style={{ width: '80%' }}
                id="hash"
                name="hash"
                type="text"
                placeholder="hash"
              />
            </Form.Item>
          </div>
        </Card>
        <div className="flex justify-end">
          <Button
            block
            style={{ marginBottom: '20px' }}
            type="primary"
            htmlType="submit"
            className="mr-2"
            icon={<SearchOutlined />}
            loading={buttonLoading}>
            Validar Hash
          </Button>
        </div>
      </Form>
    </Card>
  );
}
