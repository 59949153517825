import dayjs from 'dayjs';

function agruparPorAniosYMeses(datos) {
  const reportes = {};
  const datosOrdenados = ordenarPorFecha(datos);
  datosOrdenados.forEach((item) => {
    const anio = new Date(item.fecha).getFullYear();
    const mes = new Date(item.fecha).getMonth() + 1;

    if (!reportes[anio]) {
      reportes[anio] = {};
    }

    if (!reportes[anio][mes]) {
      reportes[anio][mes] = [];
    }

    reportes[anio][mes].push(item);
  });
  Object.keys(reportes).forEach((anio) => {
    reportes[anio] = Object.fromEntries(Object.entries(reportes[anio]).sort());
  });

  return reportes;
}

function ordenarPorFecha(data) {
  const compararFechas = (a, b) => new Date(a.fecha) - new Date(b.fecha);

  // Ordenar por meses
  const datosOrdenados = data.sort(compararFechas);
  return datosOrdenados;
}
function obtenerUltimoDiaDelMes(anio, mes) {
  let ultimoDia = new Date(anio, mes, 0);
  ultimoDia = dayjs(ultimoDia).format('DD/MM/YY');
  return ultimoDia;
}
function obtenerPrimerDiaDelMes(anio, mes) {
  let primerDia = new Date(anio, mes - 1, 1);
  primerDia = dayjs(primerDia).format('DD/MM/YY');
  return primerDia;
}

const fechasUtils = {
  agruparPorAniosYMeses,
  ordenarPorFecha,
  obtenerUltimoDiaDelMes,
  obtenerPrimerDiaDelMes,
};
export default fechasUtils;
