import getOptions from '../../../utils/getOptions';
import dayjs from 'dayjs';
import { Card, Descriptions } from 'antd';

export default function TablaReporteModificacionesInfo({ items }) {
  const rutEmpleador = items.empresaRut.rutEmpresa;
  const razonSocial = items.empresaRut.razonSocial;
  const ubicacion = items.ubicacion;
  const desde = items.fechaConsultaReporte.desde;
  const hasta = items.fechaConsultaReporte.hasta;
  const rut = items.rut;
  const cargo = items.cargo;
  const nombreCompleto = `${items.nombres} ${items.primerApellido} ${items.segundoApellido}`;

  return (
    <Card
      title={
        <strong>Reporte de Modificaciones y/o alteraciones de turno</strong>
      }
      style={{
        marginBottom: '20px',
      }}
      headStyle={{ textAlign: 'center' }}>
      <Descriptions column={1}>
        <Descriptions.Item label="Razón Social del Empleador">
          {razonSocial}
        </Descriptions.Item>
        <Descriptions.Item label="RUT del Empleador">
          {rutEmpleador}
        </Descriptions.Item>
        <Descriptions.Item label="Nombre del Trabajador">
          {nombreCompleto}
        </Descriptions.Item>
        <Descriptions.Item label="RUT">{rut}</Descriptions.Item>
        <Descriptions.Item label="Cargo">{cargo}</Descriptions.Item>
        <Descriptions.Item label="Lugar de Prestación de los Servicios">
          {ubicacion}
        </Descriptions.Item>
        <Descriptions.Item label="Periodo Consultado Del">
          {dayjs(desde).format('DD/MM/YY')} al {dayjs(hasta).format('DD/MM/YY')}
        </Descriptions.Item>
      </Descriptions>
    </Card>
  );
}
