import React, { useState } from 'react';
import { Form, Button, message, Card, DatePicker } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import locale from 'antd/es/date-picker/locale/es_ES';
import dtService from '../../services/dtService';
import dayjs from 'dayjs';

export default function BuscarIncidentesTecnicos(props) {
  const [form] = Form.useForm();
  const [buttonLoading, setButtonLoading] = useState(false);
  const hoy = new Date();
  const semana = new Date();
  const [desde, setDesde] = useState(semana.toISOString().split('T')[0]);
  const [hasta, setHasta] = useState(hoy.toISOString().split('T')[0]);

  const fechas = (event) => {
    const dates = event.map((item) => {
      const fecha = dayjs(new Date(item)).format('YYYY-MM-DD');
      return fecha;
    });
    const desde = dates[0];
    const hasta = dates[1];
    setDesde(desde);
    setHasta(hasta);
  };

  const resetFilters = () => {
    form.resetFields();
  };

  const buscadorDeIncidentesTecnicos = async (fechaDesde, fechaHasta) => {
    try {
      const [res, err] = await dtService.BuscarIncidentesTecnicos(
        fechaDesde,
        fechaHasta
      );
      if (!err) {
        const respuesta = res.data;
        return respuesta;
      } else {
        props.setIncidenteAreLoading(true);
      }
    } catch (err) {
      message.error('Error en la función buscadorDeIncidentesTecnicos:', err);
    }
  };

  const handleSubmit = async (values) => {
    setButtonLoading(true);

    props.setIncidenteAreLoading(true);
    try {
      if (!values.fecha) {
        message.error(
          'Ingrese un rango de fechas que desea validar y consultar'
        );
        setButtonLoading(false);
        props.setIncidenteAreLoading(false);
        props.setIncidenteTable([]);
      } else {
        let respuesta = await buscadorDeIncidentesTecnicos(desde, hasta);

        if (!respuesta.data.existe) {
          props.setIncidenteTable(null);
          props.setIncidenteAreLoading(false);
        } else {
          props.setIncidenteTable(respuesta.data.registro);
          props.setIncidenteAreLoading(false);
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setButtonLoading(false);
    }
  };

  return (
    <Card
      title="Ingrese el rango de fechas de los incidentes técnicos que desea consultar"
      extra={<Button onClick={() => resetFilters()}>Limpiar buscador</Button>}
      style={{ marginBottom: '10px' }}>
      <Form
        form={form}
        layout="horizontal"
        requiredMark={false}
        onFinish={handleSubmit}
        labelCol={{
          span: 5,
        }}
        wrapperCol={{
          span: 19,
        }}>
        <Card
          title={<strong>Buscar incidentes técnicos</strong>}
          headStyle={{ textAlign: 'center' }}
          style={{ marginBottom: '20px' }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}>
            <Form.Item
              name="fecha"
              label={<strong>Fechas</strong>}
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
              }}>
              <DatePicker.RangePicker
                id="fecha"
                name="fecha"
                style={{ width: '100%' }}
                placeholder={['Fecha Desde', 'Fecha Hasta']}
                allowClear={true}
                onChange={fechas}
                format={'DD/MM/YY'}
                locale={locale}
              />
            </Form.Item>
          </div>
        </Card>
        <div className="flex justify-end">
          <Button
            block
            style={{ marginBottom: '20px' }}
            type="primary"
            htmlType="submit"
            className="mr-2"
            icon={<SearchOutlined />}
            loading={buttonLoading}>
            Buscar
          </Button>
        </div>
      </Form>
    </Card>
  );
}
