import React from "react";
import FormatDateV2 from "../../../utils/FormatDateV2";
import TablainfoPersonas from "./atoms/TablainfoPersonas";
import styles from "./jsonStyles.json";
import logoApp from '../../../assets/Xinerlink.png';
import TablaGlosarioDomingos from "./atoms/TablaGlosarioDomingos";
import dayjs from "dayjs";
import fechasUtils from "../../../utils/fechasUtils";
import TablaReporteFestivosAgrupadosAnioMes from "./atoms/TablaReporteFestivosAgrupadosAnioMes";
import TablaSumaryTotalesFestivos from "./atoms/TablaSumaryTotalesFestivos";
export default function FormatoReporteFestivos({
  dataArray,
  desde,
  hasta,
  widthTable = "500px",
}) {

  // const [agrupadosAnioYmes, setAgrupadosAnioYmes] = useState([]);
  const agrupadosAnioYmes = [];

  // const total = `${calcularSumaLongitudArrays(items)} Domingos y/o Festivos`;

  const descripcion = `Total domingos y/o festivos laborados del ${dayjs(
    desde
  ).format('DD/MM/YY')} al ${dayjs(hasta).format('DD/MM/YY')}`;

  function calcularSumaLongitudArrays(reportes) {
    return reportes
      .map((reporteActual) =>
        Array.isArray(reporteActual)
          ? reporteActual.filter((item) => item.asistencia)
          : []
      )
      .flat().length;
  }

  const sumatoriaTotales = (arrayDeArrays) => {
    let sumaLongitud = 0;

    for (let i = 0; i < arrayDeArrays.length; i++) {
      const arrayActual = arrayDeArrays[i];

      if (Array.isArray(arrayActual)) {
        const asistencias = arrayActual.filter(
          (item) => item.asistencia === "SI"
        );
        sumaLongitud += asistencias.length;
      }
    }




    return sumaLongitud;
  };

  return (
    <div
      className="table-responsive"
      style={styles.tableReponsive}
      id="tablaPrincipal"
    ><div style={{ display: "flex", justifyContent: "flex-end" }}>
        <picture>
          <img
            src={logoApp}
            alt="logo App"
            style={{ maxWidth: "200px", height: "auto", marginRight: "20px" }}
          />
        </picture>
        <br />
      </div>
      <h3 style={{ font: " 11pt Arial" }}><strong> Reporte de Domingos y Festivos</strong></h3>
      <br />
      <div className="table-responsive">
        <TablaGlosarioDomingos />
      </div>
      <br />
      <p style={{ font: "11pt Arial", textAlign: "start" }}>Periodo consultado: {dayjs(desde).format("DD-MM-YY")} al {dayjs(hasta).format("DD-MM-YY")}</p>

      {
        dataArray.map((item) => (
          <div id={item.key} key={item.key} className="table-responsive">
            <br />
            <br />
            <TablainfoPersonas item={item}></TablainfoPersonas>

            <div>
              {item.reporte.length === 0 && (
                <div className="mx-auto mt-1" style={{ maxWidth: "70rem" }}>
                  <p style={styles.td2}>
                    La jornada de este trabajador no incluye domingos y festivos
                  </p>
                </div>
              )}

              {item.reporte.length !== 0 && (
                <>
                  {item.reporte.map((task) => (

                    <>
                      < table style={styles.table} >
                        <tr

                          style={{
                            backgroundColor: "limeGreen",
                            color: "black",
                            border: "1px solid",
                            font: "11pt Arial",
                          }}
                        >


                          <th style={styles.td2}>
                            T.C.B.D.D.A.
                          </th>
                          <th style={styles.td2}>Fecha</th>
                          <th style={styles.td2}>Asistencia</th>
                          <th style={styles.td2}>Ausencia</th>
                          <th style={styles.td2}>Observaciones</th>
                        </tr>

                        <TablaReporteFestivosAgrupadosAnioMes reporte={task} />
                      </table>

                      <table style={styles.table}>
                        <tr>
                          <td colspan="3" style={styles.tdtotales}>
                            {descripcion}
                          </td>

                          <td colspan="2" style={styles.td2}>
                            {" "}
                            {calcularSumaLongitudArrays(item.reporte)} Domingos y/o Festivos
                          </td>
                        </tr>
                      </table> </>

                  ))
                  }
                </>
              )}
            </div >

          </div >
        ))
      }

    </div >
  );
}
