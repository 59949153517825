import React from 'react';
import dayjs from 'dayjs';
export default function TablainfoPersonas({ item }) {
  console.log(item);
  const styles = {
    tableReponsive: {
      maxWidth: '100%',
      marginLeft: '20px',
      marginRight: '20px',
      textAlign: 'center',
    },
    table: {
      width: '100%',
      borderCollapse: 'collapse',
      borderSpacig: '0',
      font: ' 8pt Arial',
    },
    td: {
      padding: '8px',
      textAlign: 'left',
      verticalAlign: 'middle',
      border: '1px solid #ddd',
      font: ' 8pt Arial',
    },
    p: {
      padding: '8px',
      textAlign: 'left',
      verticalAlign: 'middle',
      // border: '1px solid #ddd',
      font: ' 8pt Arial',
    },
    td2: {
      padding: '8px',
      textAlign: 'center',
      verticalAlign: 'middle',
      border: '1px solid #ddd',
      font: ' 8pt Arial',
    },
  };
  return (
    <div style={styles.td}>
      <p style={styles.p}>
        <strong>Razón Social del Empleador:</strong>{' '}
        {item.empresaRut.razonSocial}
      </p>
      <p style={styles.p}>
        <strong>Rut Empleador:</strong> {item.empresaRut.rutEmpresa}
      </p>
      <p style={styles.p}>
        <strong>Nombre del Trabajador:</strong> {item.nombres}{' '}
        {item.primerApellido} {item.segundoApellido}
      </p>
      <p style={styles.p}>
        <strong>Rut:</strong> {item.rut}
      </p>
      <p style={styles.p}>
        <strong>Cargo:</strong> {item.cargo}
      </p>
      <p style={styles.p}>
        <strong>Lugar de prestación de los Servicios:</strong> {item.ubicacion}
      </p>
      <p style={styles.p}>
        <strong>Periodo Consultado del:</strong>{' '}
        {dayjs(item.fechaConsultaReporte.desde).format('DD/MM/YY')}{' '}
        <strong>al</strong>{' '}
        {dayjs(item.fechaConsultaReporte.hasta).format('DD/MM/YY')}
      </p>

      {/*   <table style={styles.table}>
        <thead
          style={{
            backgroundColor: 'DARKBlue',
            color: 'white',
            height: '50px',
            border: '1px solid',
          }}>
          <tr>
            <th style={styles.td}>Razón Social del Empleador</th>
            <th style={styles.td}>Rut Empleador</th>
            <th style={styles.td}>Nombre del Trabajador</th>
            <th style={styles.td}>Rut</th>
            <th style={styles.td}>Cargo</th>
            <th style={styles.td}>Lugar de prestación de los Servicios</th>
            <th style={styles.td}>Periodo Consultado del</th>
          </tr>
        </thead>
        <tbody
          style={{
            backgroundColor: 'white',
            height: '60px',
          }}>
          <tr>
            <td style={styles.td}>
              {item.nombres} {item.primerApellido} {item.segundoApellido}
            </td>
            <td style={styles.td}>{item.rut}</td>
            <td style={styles.td}>{item.cargo}</td>
            <td style={styles.td}>{item.empresaRut}</td>
          </tr>
        </tbody>
      </table> */}
    </div>
  );
}
