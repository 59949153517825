/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { Navigate, Outlet, useNavigate, useLocation } from 'react-router-dom';
import { Layout, Menu, Image, Avatar, Space, Dropdown, Typography } from 'antd';
import CosultaResolucion from '../../utils/CosultaResolucion';
import SelectCompanies from '../../pages/auth/SelectCompanies';

import {
  MoreOutlined,
  LogoutOutlined,
  UserOutlined,
  MenuOutlined,
  SyncOutlined,
} from '@ant-design/icons';
import logoApp from '../../assets/Xinerlink.png';
import useAuth from '../../hooks/useAuth';
import { Link } from 'react-router-dom';
import PageFooter from '../footer/PageFooter';
import menuItems from '../menu/menuItems';

const { Content, Sider } = Layout;
const { Text } = Typography;

export default function PrivateLayout() {
  const [collapsed, setCollapsed] = useState(false);
  const [selectCompanies, setSelectCompanies] = useState(true);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const { user, logoutUser, empresaID, currentUserRole } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  if (!user) return <Navigate to="/auth" replace />;

  const filterMenuItemsByRole = () => {
    if (currentUserRole === 'dt') {
      return [...menuItems[0], ...menuItems[1]];
      /*  return [
        ...menuItems[0],
        ...menuItems[1], // DT
        ...menuItems[2],
        ...menuItems[3],
        ...menuItems[4],
      ]; */
    } else if (currentUserRole === 'empresa' || currentUserRole === 'est') {
      return [
        ...menuItems[0],
        //    ...menuItems[1], // DT
        ...menuItems[2],
        ...menuItems[3],
        ...menuItems[4],
      ];
    } else if (currentUserRole === 'trabajador') {
      return [...menuItems[0], ...menuItems[3], ...menuItems[4]];
    } else {
      return [];
    }
  };

  const MenuResponsive = () => {
    return (
      <Menu
        mode="inline"
        onClick={(e) => {
          setDropdownVisible(false);
          navigate(e.key);
        }}
        items={filterMenuItemsByRole()}
      />
    );
  };

  const consulUserNameMenu = () => {
    if (user.rol !== 'empresa' && user.rol !== 'est') {
      return (
        <Menu.Item
          key="/"
          icon={<SyncOutlined />}
          onClick={() => setSelectCompanies(true)}>
          Cambiar empresa
        </Menu.Item>
      );
    }
  };
  const handleDropdownVisibleChange = (visible) => {
    setDropdownVisible(visible);
  };

  const UserNameMenu = () => {
    if (!user) return <Navigate to="/auth" replace />;
    return (
      <div>
        <br />
        <Menu>
          {consulUserNameMenu()}
          <Menu.Item
            key="/auth"
            icon={<LogoutOutlined />}
            onClick={() => logoutUser()}>
            Salir
          </Menu.Item>
        </Menu>
      </div>
    );
  };

  const UserNameMenuSM = () => {
    if (!user) return <Navigate to="/auth" replace />;
    return (
      <div>
        <br />

        {MenuResponsive(menuItems)}
      </div>
    );
  };

  return (
    <div>
      {selectCompanies ? (
        <SelectCompanies setSelectCompanies={setSelectCompanies} />
      ) : (
        <Layout style={{ minHeight: '100vh' }}>
          <Sider
            hidden={CosultaResolucion(window.outerWidth) || false}
            className="d-none d-sm-block"
            width={200}
            theme="light"
            collapsible
            collapsed={collapsed}
            onCollapse={() => setCollapsed(!collapsed)}>
            <div className="logo">
              <Link to="/">
                <Image src={logoApp} preview={false} alt="Logo App" />
              </Link>
            </div>
            <Menu
              mode="inline"
              theme="light"
              style={{ marginTop: '20px' }}
              onClick={(e) => navigate(e.key)}
              items={filterMenuItemsByRole()}
              selectedKeys={[location.pathname]}
            />
          </Sider>
          <Layout>
            <div className="container header">
              <div style={{ width: '50%' }}>
                <Space
                  hidden={!CosultaResolucion(window.outerWidth) || false}
                  style={{ marginRight: '280px' }}>
                  <Dropdown
                    overlay={UserNameMenuSM}
                    trigger={['click']}
                    placement="bottomRight"
                    visible={dropdownVisible}
                    onVisibleChange={handleDropdownVisibleChange}>
                    <Typography.Link onClick={() => setDropdownVisible(true)}>
                      <Space>
                        <MenuOutlined />
                      </Space>
                    </Typography.Link>
                  </Dropdown>
                </Space>
              </div>
              <div className="flex justify-end" style={{ width: '50%' }} hi>
                <Space>
                  <Dropdown
                    overlay={UserNameMenu}
                    trigger={['click']}
                    placement="bottomRight">
                    {CosultaResolucion(window.outerWidth) ? (
                      <Typography.Link>
                        <Space>
                          <div>
                            <div>
                              <Text>{user?.nombre || 'Desconocido'}</Text>
                            </div>
                            <div>
                              <Text type="secondary">
                                {' '}
                                {empresaID?.razonSocial || 'Empresa'}
                              </Text>
                            </div>
                          </div>
                          <Avatar icon={<UserOutlined />} />
                          <MoreOutlined />
                        </Space>
                      </Typography.Link>
                    ) : (
                      <Typography.Link>
                        <Space>
                          <Avatar icon={<UserOutlined />} />
                          <div
                            hidden={
                              CosultaResolucion(window.outerWidth) || false
                            }>
                            <div>
                              <Text>{user?.nombre || 'Desconocido'}</Text>
                            </div>
                            <div>
                              <Text type="secondary">
                                {' '}
                                {empresaID?.razonSocial || 'Empresa'}
                              </Text>
                            </div>
                          </div>

                          <MoreOutlined />
                        </Space>
                      </Typography.Link>
                    )}
                  </Dropdown>
                </Space>
              </div>
            </div>
            <Content
              className="container"
              hidden={CosultaResolucion(window.outerWidth) || false}>
              <Outlet />
            </Content>
            <Content
              className="container"
              hidden={!CosultaResolucion(window.outerWidth) || false}>
              <Outlet />
            </Content>
            <PageFooter />
          </Layout>
        </Layout>
      )}
    </div>
  );
}
