import React from 'react';
import getOptions from '../../../utils/getOptions';
import { Space } from 'antd';
import MenuAccionesCrear from '../../../components/accionesDocumentos/MenuAccionesCrear';
import dayjs from 'dayjs';
// import MenuAcciones from '../Xinerlink/MenuAcciones';

export default function TrabajadoresColumns(
  items,
  openedRecord,
  setOpenedRecord
) {
  const ordenados = items.sort(
    (a, b) => parseInt(a.idOferta) - parseInt(b.idOferta)
  );

  return [
    {
      title: 'Nombre del trabajador',
      dataIndex: 'nombre',
      key: 'nombre',
      filterSearch: true,
      filters: getOptions(ordenados, 'nombre'),
      onFilter: (v, record) => record.nombre.indexOf(v) === 0,
    },
    {
      title: 'RUT',
      dataIndex: 'rut',
      key: 'rut',
      filterSearch: true,
      filters: getOptions(ordenados, 'rut'),
      onFilter: (v, record) => record.rut.indexOf(v) === 0,
    },
    {
      title: 'Correo',
      dataIndex: 'correo',
      key: 'correo',
      filterSearch: true,
      filters: getOptions(ordenados, 'correo'),
      onFilter: (v, record) => record.correo.indexOf(v) === 0,
    },
    {
      title: 'Acciones',
      align: 'center',
      fixed: 'right',
      render: (text, record) => (
        <Space>
          <MenuAccionesCrear record={record} />
        </Space>
      ),
    },
  ];
}
