import React, { useEffect, useState } from 'react';
import { HighlightOutlined } from '@ant-design/icons';
import { Space, Form, Button, Input, Modal, message } from 'antd';
import dtService from '../../../../services/dtService';
import useAuth from '../../../../hooks/useAuth';

export default function FirmarDocumentos({ item }) {
  const { empresaID } = useAuth();
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const { TextArea } = Input;
  const [form] = Form.useForm();
  let tipodeDocumento;
  if (item.tipodeDocumento === 'anexo') tipodeDocumento = 'Anexo';
  if (item.tipodeDocumento === 'pactoHE')
    tipodeDocumento = 'Pacto de Horas Extras';

  const handleCancel = () => {
    setShow(false);
    form.resetFields();
  };

  const handleSubmit = async (values) => {
    console.log('values', values);
    console.log(item);

    setLoading(true);

    try {
      const body = {
        trabajadorId: item.correoTrabajador,
        empresaId: empresaID?.empresaId,
        fechaSolicitud_empresaId_pais_consecutivoOferta:
          item.fechaSolicitud_empresaId_pais_consecutivoOferta,
        pathDocumento: item.pathDocumento,
        motivoDocumento: item.motivoDocumento,
        nombreRepresentanteLegal: values.representanteLegal,
        rutRepresentanteLegal: values.rutRepresentanteLegal,
        correoRepresentanteLegal: values.correoRepresentanteLegal,
        telefonoRepresentanteLegal: values.telefonoRepresentanteLegal,
        tipo: 'firmarDocumento',
      };

      //  await dtService.RegistrarDocumentosTrabajador(body);
      message.success('Documento firmado con éxito');
      setLoading(false);
      setShow(false);
    } catch (error) {
      message.error('Error al firmar el documento');
      setLoading(false);
      setShow(false);
    }
  };

  const showModal = () => {
    setShow(true);
  };
  return (
    <>
      <Space onClick={showModal}>
        <HighlightOutlined />
        <spam>Firmar Documento</spam>
      </Space>
      <Modal
        title="Firmar Documento"
        visible={show}
        // open={show}
        onCancel={handleCancel}
        destroyOnClose
        closable={!loading}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancelar
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={() => form.submit()}
            loading={loading}>
            Firmar
          </Button>,
        ]}>
        <p>
          ¿Está seguro que desea firmar este documento? Una vez firmado, el
          trabajador recibirá una notificación en su correo con la información
          del documento.
        </p>
        <Form layout="vertical" onFinish={handleSubmit} form={form}>
          <Form.Item
            name="ofertaTrabajador"
            id="ofertaTrabajador"
            label="Oferta - Trabajador"

            /* rules={[
                {
                  required: true,
                },
              ]} */
          >
            <Input
              id="ofertaTrabajador"
              name="ofertaTrabajador"
              type="text"
              disabled
              placeholder={`#${item.pais_consecutivoOferta} - ${item.nombreTrabajador}`}
            />
          </Form.Item>
          <Form.Item
            name="tipoDocumentoEstado"
            id="tipoDocumentoEstado"
            label="Tipo de Documento - Estado"

            /* rules={[
                {
                  required: true,
                },
              ]} */
          >
            <Input
              id="tipoDocumentoEstado"
              name="tipoDocumentoEstado"
              type="text"
              disabled
              placeholder={`${tipodeDocumento} - ${item.estadoActualDocumento}`}
            />
          </Form.Item>{' '}
          <Form.Item
            name="representanteLegal"
            id="representanteLegal"
            label="Nombre del Representante Legal o Firmante"
            rules={[
              {
                required: true,
                message:
                  'Por favor, agregue el nombre del representante legal o Firmante',
              },
            ]}>
            <Input
              id="representanteLegal"
              name="representanteLegal"
              type="text"
              placeholder="Ingrese el nombre del representante legal o Firmante"
            />
          </Form.Item>
          <Form.Item
            name="rutRepresentanteLegal"
            id="rutRepresentanteLegal"
            label="Rut del Representante Legal o Firmante"
            rules={[
              {
                required: true,
                message:
                  'Por favor, agregue el rut del representante legal o Firmante',
              },
            ]}>
            <Input
              id="rutRepresentanteLegal"
              name="rutRepresentanteLegal"
              type="text"
              placeholder="Ingrese el rut del representante legal o Firmante"
            />
          </Form.Item>
          <Form.Item
            name="correoRepresentanteLegal"
            id="correoRepresentanteLegal"
            label="Correo del Representante Legal o Firmante"
            rules={[
              {
                required: true,
                message:
                  'Por favor, agregue el correo del representante legal o Firmante',
                type: 'email',
              },
            ]}>
            <Input
              id="correoRepresentanteLegal"
              name="correoRepresentanteLegal"
              type="email"
              placeholder="Ingrese el correo del representante legal o Firmante"
            />
          </Form.Item>
          <Form.Item
            name="telefonoRepresentanteLegal"
            id="telefonoRepresentanteLegal"
            label="Teléfono del Representante Legal o Firmante"
            rules={[
              {
                required: true,
                message:
                  'Por favor, agregue el teléfono del representante legal o Firmante',
              },
            ]}>
            <Input
              id="telefonoRepresentanteLegal"
              name="telefonoRepresentanteLegal"
              type="tel"
              placeholder="Ingrese el teléfono del representante legal o Firmante"
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}
