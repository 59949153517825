import { render } from '@testing-library/react';
import getOptions from '../../utils/getOptions';
import { Space } from 'antd';

// import MenuAcciones from '../Xinerlink/MenuAcciones';

export default function TrabajadoresReportesColumns(
  items,
  openedRecord,
  setOpenedRecord
) {
  const ordenados = items.sort(
    (a, b) => parseInt(a.idOferta) - parseInt(b.idOferta)
  );

  return [
    {
      title: 'Nombre del trabajador',
      dataIndex: 'nombres',
      key: 'nombres',
      filterSearch: true,
      filters: getOptions(ordenados, 'nombres'),
      onFilter: (v, record) => record.nombres.indexOf(v) === 0,
      render: (text, record) => (
        <Space>{`${record.nombres} ${record.primerApellido} ${record.segundoApellido}`}</Space>
      ),
    },
    {
      title: 'RUT',
      dataIndex: 'rut',
      key: 'rut',
      filterSearch: true,
      filters: getOptions(ordenados, 'rut'),
      onFilter: (v, record) => record.rut.indexOf(v) === 0,
    },
    {
      title: 'Correo',
      dataIndex: 'key',
      key: 'key',
      filterSearch: true,
      filters: getOptions(ordenados, 'key'),
      onFilter: (v, record) => record.key.indexOf(v) === 0,
      render: (text, record) => <Space>{text.split('-')[0]}</Space>,
    },
  ];
}
