import React, { useState, useEffect } from 'react';
import { Table, Typography, Divider } from 'antd';

import GlosarioCardAsistencia from './components/GlosarioCardAsistencia';
import TablaAsistenciaInfo from './components/TablaAsistenciaInfo';
import BuscadorReportes from '../../components/buscador/BuscadorReportes';
import HeaderReportes from '../../components/headers/HeaderReportes';
import BotonExportarWord from '../../components/botones/BotonExportarWord';
import BotonExportarPdf from '../../components/botones/BotonExportarPdf';
import BotonExportarExcel from '../../components/botones/BotonExportarExcel';
import CartaAsistencia from './components/CartaAsistencia';
import TablaNoExisteReporteAsistencia from './components/TablaNoExisteReporteAsistencia';
import TrabajadoresReportesColumns from '../../components/tablas/TrabajadoresReportesColumns';
import Labels from '../../utils/Labels.json';

// import { downloadExcel } from "../../utils/DownloadExcelFile";

export default function Asistencia() {
  const [firstTable, setFirstTable] = useState([]);
  const [jobsAreLoading, setJobsAreLoading] = useState(true);
  const [currentDate, setCurrentDate] = useState('');
  const [exportarExcel, setexportarExcel] = useState(false);
  const [exportarWord, setexportarWord] = useState(false);
  const [exportarPdf, setexportarPdf] = useState(false);
  const [DatosSeleccionados, setDatosSeleccionados] = useState([]);
  const [reporteVisible, setReporteVisible] = useState(false);
  const { Paragraph } = Typography;

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setDatosSeleccionados(selectedRows);
      setReporteVisible(true);
    },
  };

  /* const expandedRowRender = (record) => {
    return <CartaAsistencia record={record.reporte} />;
  }; */

  useEffect(() => {
    let date = new Date();

    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();

    if (month < 10) {
      setCurrentDate(`${year}-0${month}-${day}`);
    } else {
      setCurrentDate(`${year}-${month}-${day}`);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <HeaderReportes
        name="Reporte de Asistencia"
        setexportarPdf={setexportarPdf}
        setexportarWord={setexportarWord}
        setexportarExcel={setexportarExcel}
        firstTable={firstTable}
        DatosSeleccionados={DatosSeleccionados}
      />
      <BuscadorReportes
        setFirstTable={setFirstTable}
        tipoBuscador="asistencia"
        jobsAreLoading={jobsAreLoading}
        setJobsAreLoading={setJobsAreLoading}
        setDatosSeleccionados={setDatosSeleccionados}
      />
      {exportarExcel && (
        <BotonExportarExcel
          dataArray={DatosSeleccionados}
          formato="asistencia"
          name={`reporte Asistencia_${currentDate}`}
          setexportarExcel={setexportarExcel}
        />
      )}
      {exportarWord && (
        <BotonExportarWord
          dataArray={DatosSeleccionados}
          formato="asistencia"
          name={`reporte Asistencia_${currentDate}`}
          setexportarWord={setexportarWord}
        />
      )}
      {exportarPdf && (
        <BotonExportarPdf
          dataArray={DatosSeleccionados}
          formato="asistencia"
          name={`reporte Asistencia_${currentDate}`}
          setexportarpdf={setexportarPdf}
        />
      )}
      {jobsAreLoading ? (
        <div />
      ) : firstTable.length === 0 ? (
        <div className="mx-auto mt-1" style={{ maxWidth: '70rem' }}>
          <TablaNoExisteReporteAsistencia />
        </div>
      ) : (
        <>
          <Table
            rowSelection={{
              type: 'checkbox',
              ...rowSelection,
            }}
            rowKey="rut"
            pagination={{ showSizeChanger: true }}
            locale={Labels['tableLabels']}
            dataSource={firstTable}
            columns={TrabajadoresReportesColumns(firstTable)}
            scroll={{ x: 'max-content' }}
          />
          {reporteVisible && DatosSeleccionados.length > 0 ? (
            <div>
              <div
                style={{
                  marginBottom: '10px',
                  display: 'flex',
                  justifyContent: 'center',
                }}>
                <div>
                  <GlosarioCardAsistencia />
                </div>
              </div>

              {DatosSeleccionados.map((record) => {
                return (
                  <div
                    key={record.key}
                    style={{ textAlign: 'center', marginTop: '10px' }}>
                    <TablaAsistenciaInfo items={record} />
                    <CartaAsistencia record={record.reporte} />
                    <Divider />
                  </div>
                );
              })}
            </div>
          ) : null}
        </>
      )}
    </div>
  );
}
