import React, { useEffect, useState } from 'react';
import { Card, Image, Spin, Button, Input, Form, message } from 'antd';
import PageFooter from '../../components/footer/PageFooter';
import PageHeader from '../../components/headers/PageHeader';
import logoApp from '../../assets/Xinerlink.png';
import dtService from '../../services/dtService';
import useAuth from '../../hooks/useAuth';
import BotonSalir from '../../components/botones/BotonSalir';

export default function SelectCompanies({ setSelectCompanies }) {
  const { setEmpresaID, user, empresaID } = useAuth();
  const [empresa, setEmpresas] = useState([]);
  const [login, setLogin] = useState(true);
  const [searchRut, setSearchRut] = useState('');
  const [filteredEmpresas, setFilteredEmpresas] = useState(empresa);

  const notificarEmpresa = async (registro) => {
    await dtService.NotificarFiscalizacionEmpresa(registro);
  };

  const cargarEmpresa = async (item) => {
    setEmpresaID(item);
    setSelectCompanies(false);

    if (user.rol === 'dt') {
      const registro = {
        correo: user.correo,
        empresaId: item.empresaId,
        fechaInicioFiscalizacion: new Date().toISOString(),
        rutEmpresa: item.rut,
      };

      try {
        const results = await Promise.allSettled([
          notificarEmpresa(registro),
          registerInspectorLogin(
            user.correo,
            item.empresaId,
            item.razonSocial,
            item.rut
          ),
        ]);

        results.forEach((result, index) => {
          if (result.status === 'rejected') {
            console.error(`Error en la petición ${index + 1}:`, result.reason);
            message.error('Error inesperado al cargar la empresa');
          }
        });
      } catch (error) {
        console.error('Error inesperado al cargar la empresa:', error);
        message.error('Error inesperado al cargar la empresa');
      }
    }
  };

  const registerInspectorLogin = async (
    emailInspector,
    empresaId,
    razonSocial,
    rutEmpresa
  ) => {
    const correoFiscalizador = emailInspector;

    await dtService.RegistrarInicioSesionFiscalizador({
      correoFiscalizador: correoFiscalizador,
      empresaId: empresaId,
      razonSocial: razonSocial,
      rutEmpresa: rutEmpresa,
    });
  };

  const renderPageHeader = (user) => {
    let headText = 'Plataforma para la dirección de trabajo';
    let titleText = 'Ingresando Trabajador';

    if (user.rol === 'dt') {
      headText = 'Ingreso exclusivo de la Dirección de Trabajo';
      titleText = 'Seleccionar Empresa';
    } else if (user.rol === 'empresa') {
      headText = 'Plataforma para la dirección de trabajo';
      titleText = 'Ingresando como Empresa';
    } else if (user.rol === 'est') {
      headText = 'Plataforma para la dirección de trabajo';
      titleText = 'Ingresando como EST / Subcontratista';
    } else if (user.rol === 'trabajador') {
      headText = 'Plataforma para la dirección de trabajo';
      titleText = 'Seleccione empresa';
    }

    return <PageHeader children={''} head={headText} title={titleText} />;
  };

  const Verobjetos = async () => {
    const [res, err] = await dtService.consultarinformacionBuscador('dt');

    if (!err) {
      if (user.rol === 'dt') {
        setEmpresas(res?.data?.empresas || []);
      }

      const responseEmpresas = res?.data?.empresas;

      if (user.rol === 'empresa' || user.rol === 'est') {
        const currentEmpresa = responseEmpresas.find(
          (item) => item.empresaId === user.empresaId
        );
        setEmpresaID(currentEmpresa);
        setSelectCompanies(false);
        setLogin(false);
      }

      if (user.rol === 'trabajador') {
        setLogin(true);
        const [res, err] = await dtService.consultarinformacionBuscador(
          'trabajador',
          '',
          user.correo
        );

        if (!err) {
          const empresas = res.data.empresas;
          setEmpresas(empresas);
          setLogin(false);
        } else {
          console.log(err);
        }
      }
    } else {
      console.log(err);
    }
    setLogin(false);
  };

  const handleSearchChangeRut = (e) => {
    setSearchRut(e.target.value);
  };

  useEffect(() => {
    if (empresa.length === 0) Verobjetos();

    setFilteredEmpresas(
      empresa.filter(
        (empresa) =>
          empresa.rut.toLowerCase().includes(searchRut.toLowerCase()) ||
          empresa.razonSocial.toLowerCase().includes(searchRut.toLowerCase())
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchRut, empresa]);

  return (
    <div>
      <div className="bg-[#F0F2F5] min-h-screen flex items-center">
        <div className="w-screen px-5">
          <div className="max-w-md mx-auto">
            <div className="w-56 mx-auto mb-4">
              <Image src={logoApp} preview={false} alt="Logo App" />
            </div>

            <Card>
              {renderPageHeader(user)}
              {login && (user.rol === 'dt' || user.rol === 'trabajador') ? (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    // height: '100vh',
                    height: '30vh',
                  }}>
                  <Spin />
                </div>
              ) : user.rol === 'dt' || user.rol === 'trabajador' ? (
                <>
                  <div>
                    <Form>
                      <Form.Item label="Busqueda por RUT o Razón Social: ">
                        <Input
                          type="text"
                          value={searchRut}
                          onChange={handleSearchChangeRut}
                          placeholder="Ingrese un Rut o una Razón Social"
                          style={{ marginBottom: '10px' }}
                        />
                      </Form.Item>
                    </Form>
                  </div>
                  <div>
                    {filteredEmpresas.map((item, index) => (
                      <Button
                        key={index}
                        onClick={() => cargarEmpresa(item)}
                        block
                        style={{ height: 'auto', margin: '5px' }}>
                        <p style={{ marginBottom: '0' }}>{item.razonSocial}</p>
                        <p style={{ marginBottom: '0' }}>{item.rut}</p>
                      </Button>
                    ))}
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'end',
                        alignItems: 'end',
                      }}>
                      <BotonSalir />
                    </div>
                  </div>
                </>
              ) : (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    // height: '100vh',
                    height: '30vh',
                  }}>
                  <Spin />
                </div>
              )}
            </Card>
          </div>
        </div>
      </div>

      <PageFooter />
    </div>
  );
}
