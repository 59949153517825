import React from 'react';
import PageHeaderReportes from '../../components/headers/PageHeaderReportes';
import TablaTrabajadores from './components/TablaTrabajadores';
import TablaDocumentosTrabajador from './components/TablaDocumentosTrabajador';
import { Tabs, Card } from 'antd';

export default function Documentos() {
  return (
    <>
      <PageHeaderReportes title="Documentos" head="" />
      <Card>
        <Tabs defaultActiveKey="1" type="card" size={'small'}>
          <Tabs.TabPane tab="Crear Documentos" key="1">
            <TablaTrabajadores />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Firmar Documentos" key="2">
            <TablaDocumentosTrabajador />
          </Tabs.TabPane>
        </Tabs>
      </Card>
    </>
  );
}
