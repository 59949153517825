import dayjs from 'dayjs';

export default function FormatDateV2(fechaUTC, tipo = 'general') {
  // Crear un objeto de fecha a partir de la fecha UTC

  if (!fechaUTC) {
    const mensaje = 'T No se registró';
    if (tipo === 'marca' || tipo === 'jornada') {
      const mensajeMarca = 'T ';
      return mensajeMarca;
    } else {
      return mensaje;
    }
  } else {
    let fecha;
    fecha =
      fechaUTC.length > 19
        ? new Date(fechaUTC.slice(0, -1))
        : new Date(fechaUTC);

    // Convertir la fecha a la hora local
    const fechaLocal = new Date(
      fecha.getTime() - fecha.getTimezoneOffset() * 60000
    );

    // Formatear la fecha local en el formato solicitado
    const year = fechaLocal.getFullYear().toString().slice(2);

    const mes = ('0' + (fechaLocal.getMonth() + 1)).slice(-2);
    const dia = ('0' + fechaLocal.getDate()).slice(-2);
    const hora = ('0' + fechaLocal.getHours()).slice(-2);
    const minutos = ('0' + fechaLocal.getMinutes()).slice(-2);
    const segundos = ('0' + fechaLocal.getSeconds()).slice(-2);
    if (tipo === 'excel') {
      const fechaFormateadaExcel =
        year +
        '-' +
        mes +
        '-' +
        dia +
        ' ' +
        hora +
        ':' +
        minutos +
        ':' +
        segundos;

      // Retornar la fecha formateada
      return fechaFormateadaExcel;
    } else if (tipo === 'jornada') {
      const fechaFormateadaMarca =
        year +
        '-' +
        mes +
        '-' +
        dia +
        'T' +
        hora +
        ':' +
        minutos +
        ':' +
        segundos;

      // Retornar la fecha formateada
      return fechaFormateadaMarca;
    } else {
      const fechaFormateada =
        dia +
        '/' +
        mes +
        '/' +
        year +
        'T' +
        hora +
        ':' +
        minutos +
        ':' +
        segundos;
      // Retornar la fecha formateada
      return fechaFormateada;
    }
  }
}
