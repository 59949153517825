import React from 'react';
import { Card, Image, Typography } from 'antd';
import imgenLoad from '../../assets/DocumentosDt.svg';
const { Title } = Typography;

export default function Home() {
  return (
    <div>
      <div className="site-card-wrapper">
        <Card>
          <div className="my-16 text-center">
            <Title level={2}>Bienvenido al Portal Fiscalización DT</Title>
            <div className="mx-auto mb-4 max-w-lg overflow-hidden">
              <Image
                alt="XinerlinkExpress"
                preview={false}
                src={imgenLoad}
                height="100"
                width="100"
                layout="responsive"
                objectFit="contain"
                quality="100"
              />
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
}
