import React from 'react';
import useAuth from '../../hooks/useAuth';

import { Button } from 'antd';
export default function BotonSalir() {
  const { logoutUser } = useAuth();

  const handleLogout = () => {
    logoutUser();
  };

  return (
    <Button
      type="danger"
      htmlType="submit"
      className="mr-4 mt-4"
      onClick={() => handleLogout()}>
      Salir
    </Button>
  );
}
