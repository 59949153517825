import React from 'react';
import { Divider } from 'antd';
import Title from 'antd/lib/typography/Title';
import CosultaResolucion from '../../utils/CosultaResolucion';
export default function PageHeader({ children, title = '', head = '' }) {
  return CosultaResolucion(window.outerWidth) ? (
    <div>
      <div className="md:flex justify-start flex-col">
        <div className="mb-2 md:mb-8 text-center">
          <span className="text-sm md:text-base ">{head}</span>{' '}
          {/* Tamaño de texto más pequeño en todas las pantallas */}
        </div>
        <div className="flex justify-center items-center flex-col">
          <Title level={5} className="text-sm md:text-base">
            {title}
          </Title>{' '}
          {/* Tamaño de texto más pequeño en todas las pantallas */}
        </div>
        <div>{children}</div>
      </div>
      <Divider />
    </div>
  ) : (
    <div>
      <div className="md:flex justify-start flex-col">
        <div className="text-center" style={{ marginBottom: '8px' }}>
          <span>{head}</span>
        </div>
        <div className="flex justify-center items-center flex-col">
          <Title level={3}>{title}</Title>
        </div>
        <div>{children}</div>
      </div>
      <Divider />
    </div>
  );
}
