import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import moment from "moment";
import "moment/locale/es";
import "antd/dist/antd.variable.min.css";
import Amplify from "aws-amplify";
import awsExports from "./aws-exports";
Amplify.configure(awsExports);

moment.locale("es");
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);
