import React from 'react';
import { useState } from 'react';
import { Card, Col, Form, Row } from 'antd';
import PageHeader from '../../components/headers/PageHeader';
import { useLocation } from 'react-router-dom';
import FormCrearDocumentos from '../../components/formularios/FormCrearDocumentos';
import BotonGoBack from '../../components/botones/BotonGoBack';

export default function AnexosContrato() {
  const location = useLocation();
  const { trabajador } = location.state || {};

  return (
    <div>
      <PageHeader head="" title="Anexos de Contrato" />
      <BotonGoBack path={`/documentos`} />
      <FormCrearDocumentos
        trabajador={trabajador}
        titulo={'Anexos de Contrato'}
        tipo={'anexo'}
      />
    </div>
  );
}
