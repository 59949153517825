import React from 'react';
import { Button, Space, Tooltip } from 'antd';
import {
  FileWordOutlined,
  FilePdfOutlined,
  FileExcelOutlined,
} from '@ant-design/icons';
import PageHeaderReportes from './PageHeaderReportes';
import CosultaResolucion from '../../utils/CosultaResolucion';

export default function HeaderReportes({
  name = 'Reporte',
  firstTable = [],
  setexportarPdf,
  setexportarWord,
  setexportarExcel,
  DatosSeleccionados = [],
}) {
  return (
    <PageHeaderReportes title={name} head="">
      <Space hidden={!CosultaResolucion(window.outerWidth) || false}>
        <Tooltip title="Descargar Excel">
          <Button
            size="small"
            type="text"
            onClick={() => setexportarExcel(true)}
            disabled={DatosSeleccionados.length === 0}>
            <FileExcelOutlined />
          </Button>
        </Tooltip>
        <Tooltip title="Descargar Word">
          <Button
            size="small"
            type="text"
            onClick={() => setexportarWord(true)}
            disabled={DatosSeleccionados.length === 0}>
            <FileWordOutlined />
          </Button>
        </Tooltip>
        <Tooltip title="Descargar Pdf">
          <Button
            size="small"
            type="text"
            onClick={() => setexportarPdf(true)}
            disabled={DatosSeleccionados.length === 0}>
            <FilePdfOutlined />
          </Button>
        </Tooltip>
      </Space>

      <Button
        hidden={CosultaResolucion(window.outerWidth) || false}
        className="mr-2"
        icon={<FileExcelOutlined />}
        onClick={() => setexportarExcel(true)}
        disabled={DatosSeleccionados.length === 0}
        // disabled={firstTable.length === 0}
      >
        Exportar a Excel
      </Button>
      <Button
        hidden={CosultaResolucion(window.outerWidth) || false}
        className="mr-2"
        icon={<FileWordOutlined />}
        onClick={() => setexportarWord(true)}
        disabled={DatosSeleccionados.length === 0}
        // disabled={firstTable.length === 0}
      >
        Exportar a word
      </Button>
      <Button
        hidden={CosultaResolucion(window.outerWidth) || false}
        className="mr-2"
        icon={<FilePdfOutlined />}
        onClick={() => setexportarPdf(true)}
        disabled={DatosSeleccionados.length === 0}
        // disabled={firstTable.length === 0}
      >
        Exportar a pdf
      </Button>
    </PageHeaderReportes>
  );
}
