import React, { useState } from 'react';
import { Button, Modal, Form, Input, message } from 'antd';
import dtService from '../../services/dtService';
import SelectorHoras from '../selector/SelectorHoras';
import dayjs from 'dayjs';

const { TextArea } = Input;

export default function BotonMarcarAsistencia({
    tipo = 'Entrada',
    record,
    buscar,
    key,
}) {
    const [form] = Form.useForm();
    const [JustificacionText, setJustificacionText] = useState('');
    const [visible, setVisible] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [nuevaHora, setNuevaHora] = useState('');
    const [validadorHora, setvalidadorHora] = useState(false);

    const asistenciaMarcar = async (registro) => {
        const [, err] = await dtService.MarcarAsistencia(registro);

        if (!err) {
            message.info('Solicitud Realizada');
            buscar();
        } else {
            console.log(err);
            message.error('Solicitud Rechazada');
        }
        setVisible(false);
        setConfirmLoading(false);
        // setCargando(true); 
    };

    const showModal = () => {
        setVisible(true);
    };

    const changeinfoJustificacion = (e) => {
        setJustificacionText(e.target.value);
    };

    const marcarSalida = () => {
        if (nuevaHora.length === 0) {
            setvalidadorHora(true);
        } else {
            setvalidadorHora(false);
            setConfirmLoading(true);

            // Crea una nueva fecha utilizando los componentes de la hora local
            const horaLocal = new Date(
                `${record.salidaUtc.split('T')[0]}T${nuevaHora}:00`
            );

            // Obtener la diferencia de tiempo entre la hora local y UTC en minuto
            const horaLocalString = horaLocal.toISOString();
            const registro = {
                tipo: 'salida',
                empresaId: record.empresaId,
                pais_consecutivoOferta: record.pais_consecutivoOferta,
                trabajadorId: record.correo,
                correoEmpresa: record.correoEmpresa,
                fechaEntrada: record.entradaUtc,
                fecha: horaLocalString,
                turno: record.turno,
                justificacion: JustificacionText,
            };

            asistenciaMarcar(registro);
        }
    };

    const marcarEntrada = () => {
        if (nuevaHora.length === 0) {
            setvalidadorHora(true);
        } else {
            setvalidadorHora(false);
            setConfirmLoading(true);

            // Crea una nueva fecha utilizando los componentes de la hora local
            const horaLocal = new Date(
                `${record.salidaUtc.split('T')[0]}T${nuevaHora}:00`
            );

            // Obtener la diferencia de tiempo entre la hora local y UTC en minuto
            const horaLocalString = horaLocal.toISOString();

            const registro = {
                tipo: 'entrada',
                empresaId: record.empresaId,
                pais_consecutivoOferta: record.pais_consecutivoOferta,
                trabajadorId: record.correo,
                fecha: horaLocalString,
                correoEmpresa: record.correoEmpresa,
                fechaEntrada: record.entradaUtc,
                turno: record.turno,
                justificacion: JustificacionText,
            };

            asistenciaMarcar(registro);
        }
    };

    const onFinishFailed = () => {
        message.error('Submit failed!');
    };

    const handleCancel = () => {
        setVisible(false);
    };

    if (tipo === 'Entrada')
        return (
            <div key={key}>
                {record.estadoEntrada === 'registrada' && (
                    <Button
                        type="primary"
                        onClick={showModal}
                        style={{
                            height: 'auto',
                            lineHeight: '0.8',
                            paddingTop: '10px',
                            /* color: 'white',
                            backgroundColor: '#409E9D', */
                        }}
                    >
                        <div>
                            Hora de marcado
                            {record.fechaEntradaTrabajador.length !== 0 && (
                                <p style={{ marginTop: '10px' }}>
                                    {dayjs(record.fechaEntradaTrabajador).format('HH:mm:ss')}
                                </p>
                            )}
                            {record.fechaEntradaTrabajador.length === 0 && (
                                <p style={{ marginTop: '10px' }}>Pendiente</p>
                            )}
                        </div>
                    </Button>
                )}
                {record.estadoEntrada !== 'registrada' && (
                    <Button
                        type="danger"
                        onClick={showModal}
                        style={{
                            height: 'auto',
                            lineHeight: '0.8',
                            paddingTop: '10px',
                            /*  color: 'white',
                             backgroundColor: '#E76868', */
                        }}
                    >
                        <div>
                            Hora de marcado
                            {record.fechaEntradaTrabajador.length !== 0 && (
                                <p style={{ marginTop: '10px' }}>
                                    {dayjs(record.fechaEntradaTrabajador).format('HH:mm:ss')}
                                </p>
                            )}
                            {record.fechaEntradaTrabajador.length === 0 && (
                                <p style={{ marginTop: '10px' }}>Pendiente</p>
                            )}
                        </div>
                    </Button>
                )}
                <Modal
                    title="Justificación de Marcación Entrada"
                    visible={visible}
                    confirmLoading={confirmLoading}
                    footer={false}
                    onCancel={handleCancel}
                    key={key}
                >
                    <Form.Item name="HoraModificada" label="Hora Modificada">
                        <SelectorHoras
                            setValueHora={setNuevaHora}
                            tipo="entrada"
                            record={record}
                        />
                        {validadorHora && <p style={{ color: 'red' }}>hora requerida </p>}
                    </Form.Item>
                    <Form
                        form={form}
                        layout="vertical"
                        onFinish={marcarEntrada}
                        onFinishFailed={onFinishFailed}
                        autoComplete="off"
                    >
                        <Form.Item
                            name="justificacion"
                            label="Justificación"
                            rules={[
                                { required: true, message: 'La justificacion es requerida' },
                            ]}
                        >
                            <TextArea
                                rows={4}
                                placeholder="Introduce la justificación de la marcación"
                                onChange={(event) => changeinfoJustificacion(event)}
                            />
                        </Form.Item>

                        <Button
                            loading={confirmLoading}
                            type="primary"
                            onClick={() => marcarEntrada()}
                            block
                            disabled={JustificacionText.length === 0}
                        >
                            Marcar Asistencia
                        </Button>
                    </Form>
                </Modal>
            </div>
        );
    else if (tipo === 'Salida')
        return (
            <div key={key}>
                {record.estadoSalida === 'registrada' && (
                    <Button
                        onClick={showModal}
                        type="primary"
                        style={{
                            height: 'auto',
                            lineHeight: '0.8',
                            paddingTop: '10px',
                            //  color: 'white',
                            width: '150px',
                            //  backgroundColor: '#409E9D',
                            textAlign: 'center',
                        }}
                    >
                        <div >
                            Hora de marcado
                            {record.fechaSalidaTrabajador.length !== 0 && (
                                <p style={{ marginTop: '10px' }}>
                                    {dayjs(record.fechaSalidaTrabajador).format('HH:mm:ss')}
                                </p>
                            )}
                            {record.fechaSalidaTrabajador.length === 0 && (
                                <p style={{ marginTop: '10px' }}>pendiente</p>
                            )}
                        </div>
                    </Button>
                )}
                {record.estadoSalida !== 'registrada' && (
                    <Button
                        type="danger"
                        onClick={showModal}
                        style={{
                            height: 'auto',
                            lineHeight: '0.8',
                            paddingTop: '10px',
                            //  color: 'white',
                            width: '150px',
                            // backgroundColor: '#E76868',
                            textAlign: 'center',
                        }}
                    >
                        <div style={{ textAlign: 'center' }}>
                            Hora de marcado
                            {record.fechaSalidaTrabajador.length !== 0 && (
                                <p style={{ marginTop: '10px' }}>
                                    {dayjs(record.fechaSalidaTrabajador).format('HH:mm:ss')}
                                </p>
                            )}
                            {record.fechaSalidaTrabajador.length === 0 && (
                                <p style={{ marginTop: '10px' }}>pendiente</p>
                            )}
                        </div>
                    </Button>
                )}

                <Modal
                    title="Justificación de Marcación Salida"
                    visible={visible}
                    confirmLoading={confirmLoading}
                    footer={false}
                    onCancel={handleCancel}
                    key={key}
                >
                    <Form
                        form={form}
                        layout="vertical"
                        onFinish={marcarSalida}
                        onFinishFailed={onFinishFailed}
                        autoComplete="off"
                    >
                        <Form.Item name="HoraModificada" label="Hora Modificada">
                            <SelectorHoras
                                setValueHora={setNuevaHora}
                                tipo="salida"
                                record={record}
                            />
                            {validadorHora && <p style={{ color: 'red' }}>hora requerida </p>}
                        </Form.Item>
                        <Form.Item
                            name="justificacion"
                            label="Justificación"
                            rules={[
                                { required: true, message: 'La justificacion es requerida' },
                            ]}
                        >
                            <TextArea
                                rows={4}
                                placeholder="Introduce la justificación de la marcación"
                                onChange={(event) => changeinfoJustificacion(event)}
                            />
                        </Form.Item>
                        <Button
                            type="primary"
                            htmlType="submit"
                            block
                            onClick={() => marcarSalida()}
                            loading={confirmLoading}
                            disabled={JustificacionText.length === 0}
                        >
                            Marcar Asistencia
                        </Button>
                    </Form>
                </Modal>
            </div>
        );
}
