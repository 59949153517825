import React from 'react';
// import { Typography, Row, Col, Space } from 'antd';
import TablaReporteFestivosMensuales from './TablaReporteFestivosMensuales';
import dayjs from 'dayjs';
import TablaTotalFestivos from './TablaTotalFestivos';

//const { Text } = Typography;

export default function TablaReporteFestivos({ items, desde, hasta }) {

  const total = `${calcularSumaLongitudArrays(items)} Domingos y/o Festivos`;
  const descripcion = `Total domingos y/o festivos laborados del ${dayjs(
    desde
  ).format('DD/MM/YY')} al ${dayjs(hasta).format('DD/MM/YY')}`;

  function calcularSumaLongitudArrays(reportes) {
    return reportes
      .map((reporteActual) =>
        Array.isArray(reporteActual)
          ? reporteActual.filter((item) => item.asistencia)
          : []
      )
      .flat().length;
  }

  return (
    <div>
      {items.map((itemTrabajos) => (
        <TablaReporteFestivosMensuales
          data={itemTrabajos}
          desde={desde}
          hasta={hasta}
        />
      ))}

      <TablaTotalFestivos descripcion={descripcion} total={total} />
    </div>
  );
}
