import React from 'react';
import { Button, Dropdown, Menu } from 'antd';
import { MoreOutlined } from '@ant-design/icons';

import FirmarDocumentos from '../../pages/documentos/components/extra/FirmarDocumentos';
import Notificar from '../../pages/documentos/components/extra/Notificar';
export default function MenuAccionesFirmar({ record }) {
  const items = [
    {
      key: 0,
      label: <FirmarDocumentos item={record} />,
    },
    {
      key: 1,
      label: <Notificar item={record} />,
    },
  ];

  const menu = <Menu items={items} />;
  return (
    <Dropdown overlay={menu} trigger={['hover']}>
      <Button type="text" icon={<MoreOutlined />} />
    </Dropdown>
  );
}
