import React from 'react';
import PageHeaderReportes from './PageHeaderReportes';
import { Space, Tooltip, Button } from 'antd';
import CosultaResolucion from '../../utils/CosultaResolucion';
import html3pdf from 'html3pdf';
import { DownloadOutlined, FilePdfOutlined } from '@ant-design/icons';
import logoApp from '../../assets/Xinerlink.png';

export default function HeaderIncidentes({ incidenteTable }) {
  const generatePDF = (document) => {
    const name = 'IncidentesTecnicos';
    const options = {
      filename: `${name}.pdf`,
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
    };

    const htmlToAdd = `<div
    style="
      display: flex;
      justify-content: flex-end;
    ">
    <picture>
      <img
        src="${logoApp}"
        alt="logo App"
        style="
          max-width: 200px;
          height: auto;
          margin-right: 20px;
        "
      />
    </picture>
    <br />
  </div>`;

    const htmlDom = document.innerHTML;
    const html = `${htmlToAdd}${htmlDom}`;

    html3pdf().set(options).from(html).save();
  };
  return (
    <PageHeaderReportes head="" title="Reporte de incidentes técnicos">
      <Space hidden={!CosultaResolucion(window.outerWidth) || false}>
        <Tooltip title="Descargar Reporte de Incidentes Técnicos">
          <Button
            size="small"
            type="text"
            onClick={() => generatePDF(incidenteTable)}
            disabled={!incidenteTable || incidenteTable.length === 0}>
            <FilePdfOutlined />
          </Button>
        </Tooltip>
      </Space>
      <Button
        hidden={CosultaResolucion(window.outerWidth) || false}
        className="mr-2"
        icon={<DownloadOutlined />}
        onClick={() => generatePDF(incidenteTable)}
        disabled={!incidenteTable || incidenteTable.length === 0}>
        Descargar Reporte
      </Button>
    </PageHeaderReportes>
  );
}
