import React from 'react';
import { Table, Space } from 'antd';
import getOptions from '../../../utils/getOptions';
import FormatDateV2 from '../../../utils/FormatDateV2';

export default function TablaReporteIncidentesTecnicos({ items }) {
  const baseColumns = [
    {
      title: <span style={{ color: 'blue' }}>Fecha/Hora Inicio</span>,
      dataIndex: 'fechaInicioIncidente',
      key: 'fechaInicioIncidente',
      align: 'center',
      width: 100,
      filters: getOptions(items, 'fechaInicioIncidente'),
      onFilter: (v, record) => record.fechaInicioIncidente.indexOf(v) === 0,
      render: (text, record) => (
        <Space>
          <span>
            {`${FormatDateV2(record.fechaInicioIncidente).split('T')[0]} ${
              FormatDateV2(record.fechaInicioIncidente).split('T')[1]
            }` || 'pendiente'}
          </span>
        </Space>
      ),
    },
    {
      title: <span style={{ color: 'blue' }}>Fecha/Hora Termino</span>,
      dataIndex: 'fechaFinIncidente',
      key: 'fechaFinIncidente',
      width: 100,
      filterSearch: true,
      align: 'center',
      filters: getOptions(items, 'fechaFinIncidente'),
      onFilter: (v, record) => record.fechaFinIncidente.indexOf(v) === 0,
      render: (text, record) => (
        <Space>
          <span>
            <span>
              {`${FormatDateV2(record.fechaFinIncidente).split('T')[0]} ${
                FormatDateV2(record.fechaFinIncidente).split('T')[1]
              }` || 'pendiente'}
            </span>
          </span>
        </Space>
      ),
    },

    {
      title: <span style={{ color: 'blue' }}>Descripción de Incidente</span>,
      dataIndex: 'descripcion',
      key: 'descripcion',
      width: 150,
      align: 'center',
    },
    {
      title: <span style={{ color: 'blue' }}>Estado del Incidente</span>,
      dataIndex: 'estadoIncidencia',
      key: 'estadoIncidencia',
      width: 100,
      align: 'center',
      filterSearch: true,
      filters: getOptions(items, 'estadoIncidencia'),
      onFilter: (v, record) => record.estadoIncidencia.indexOf(v) === 0,
      render: (text, record) => (
        <Space>
          {record.estadoIncidencia === 'Resuelta' ? (
            <span style={{ color: 'green' }}>{record.estadoIncidencia}</span>
          ) : (
            <span tyle={{ color: 'orange' }}>{record.estadoIncidencia}</span>
          )}
        </Space>
      ),
    },
  ];

  return (
    <Table
      rowKey="id"
      dataSource={items}
      columns={baseColumns}
      scroll={{ x: 'max-content' }}
      style={{ textAlign: 'center' }}
    />
  );
}
