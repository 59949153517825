import React, { useState, useEffect } from 'react';
import { Table, message } from 'antd';
import PageHeader2 from '../../../components/headers/PageHeader2';
import DocumentosTrabajadoresColumns from './DocumentosTrabajadoresColumns';
import Labels from '../../../utils/Labels';
import dtService from '../../../services/dtService';
import useAuth from '../../../hooks/useAuth';
import BotonDescargarDocumento from '../../../components/botones/BotonDescargarDocumento';

export default function TablaDocumentosTrabajador() {
  const [loadingDocumentos, setLoadingDocumentos] = useState(true);
  const [documentosTrabajador, setDocumentosTrabajador] = useState([]);
  const [openedRecord, setOpenedRecord] = useState(null);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const { empresaID } = useAuth();

  const rowSelection = {
    onChange: (selectedRows) => {
      setSelectedRowKeys(selectedRows);
    },
  };

  const Verobjetos = async (empresaId) => {
    setLoadingDocumentos(true);
    const tipo = 'consultarDocumentos';
    try {
      const [res, err] = await dtService.buscarDocumentosTrabajadorByEmpresaId(
        empresaId,
        tipo
      );

      if (!err) {
        const documentos = res.data.data || [];

        if (documentos.existe) {
          const splitOferta = documentos.registro.map((doc) => {
            const NroOferta = doc.pais_consecutivoOferta.split('#')[1];
            doc.pais_consecutivoOferta = NroOferta;

            if (doc.estadoActualDocumento === 'DocumentoSinFirmar') {
              doc.estadoActualDocumento = 'Sin Firmar';
            } else if (
              doc.estadoActualDocumento === 'DocumentoFirmadoXTrabajador'
            ) {
              doc.estadoActualDocumento = 'Documento Firmado';
            } else if (
              doc.estadoActualDocumento === 'DocumentoFirmadoXRepLegal'
            ) {
              doc.estadoActualDocumento = 'Documento Firmado por Empleador';
            }

            return { ...doc, key: doc.id };
          });

          setDocumentosTrabajador(splitOferta);
          setLoadingDocumentos(false);
        } else {
          setDocumentosTrabajador([]);
          setLoadingDocumentos(false);
        }
      } else {
        console.log(err);
        setLoadingDocumentos(false);
      }
    } catch (err) {
      message.error(
        'Error en la función buscadorDeDocumentosTrabajadorByEmpresaId:',
        err
      );
    }
  };

  useEffect(() => {
    Verobjetos(empresaID?.empresaId || '');

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <PageHeader2 head="" title="Firmar Documentos y Notificar Trabajador">
        <BotonDescargarDocumento
          selectedRowKeys={selectedRowKeys}
          tipo="Documentos"
        />
      </PageHeader2>
      <Table
        loading={loadingDocumentos}
        rowKey="pathDocumento"
        rowSelection={{
          type: 'checkbox',
          ...rowSelection,
        }}
        pagination={{ showSizeChanger: true }}
        locale={Labels['tableLabels']}
        dataSource={documentosTrabajador}
        columns={DocumentosTrabajadoresColumns(
          documentosTrabajador,
          openedRecord,
          setOpenedRecord
        )}
        scroll={{ x: 'max-content' }}
      />
    </div>
  );
}
