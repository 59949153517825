import { Navigate, Outlet } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import LoginSelector from '../../pages/auth/LoginSelector';

import LoginPage from '../../pages/auth/LoginPage';
import FiscalizacionDT from '../../pages/auth/FiscalizacionDT';
import PageFooter from '../footer/PageFooter';

export default function PublicLayout() {
  const { user, currentUserRole } = useAuth();

  if (user) return <Navigate to="/" replace />;
  // console.log(validationIp);

  return (
    <div>
      <div className="bg-[#F0F2F5] min-h-screen flex items-center">
        <div className="w-screen px-5">
          {/* <Outlet /> */}

          {
            /* validationIp ? (
          <FiscalizacionDT />
        ) : */ currentUserRole === null ? (
              <LoginSelector />
            ) : /* validationIp && currentUserRole === 'dt' ? (
          <FiscalizacionDT userDefault={userDefault} />
            ) : */  (
                <LoginPage currentUserRole={currentUserRole} />
              )
          }
        </div>
      </div>
      <PageFooter />
    </div>
  );
}
