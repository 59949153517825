import { useEffect, useState } from 'react';
import HeaderIncidentes from '../../components/headers/HeaderIncidentes';

import { Typography } from 'antd';
import { Card, Col, Spin } from 'antd';
import BuscarIncidentesTecnicos from '../../components/buscador/BuscarIncidentesTecnicos';
import TablaReporteIncidentesTecnicos from './components/TablaReporteIncidentesTecnicos';
import PageHeader from '../../components/headers/PageHeaderReportes';
import logoApp from '../../assets/Xinerlink.png';
import CosultaResolucion from '../../utils/CosultaResolucion';

const { Text } = Typography;

export default function IncidentesTecnicos() {
  const [incidenteTable, setIncidenteTable] = useState([]);
  const [incidenteAreLoading, setIncidenteAreLoading] = useState(false);
  const [exportable, setExportable] = useState(null);

  useEffect(() => {
    if (incidenteTable != null && incidenteTable.length > 0) {
      const element = document.getElementById('pdf-content');
      setExportable(element);
    } else {
      setExportable(null);
    }
  }, [incidenteTable]);

  return (
    <div>
      <HeaderIncidentes incidenteTable={exportable} />
      <BuscarIncidentesTecnicos
        incidenteAreLoading={incidenteAreLoading}
        setIncidenteAreLoading={setIncidenteAreLoading}
        setIncidenteTable={setIncidenteTable}
      />
      {incidenteAreLoading ? (
        <div className="flex items-center justify-center h-full">
          <Spin />
        </div>
      ) : !incidenteTable ? (
        <Col span={10} offset={6} style={{ marginTop: '20px' }}>
          <Card
            title={'Registro de incidentes técnicos'}
            bordered={false}
            headStyle={{ textAlign: 'center' }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
              }}>
              <Text justify="center" align="middle">
                No existen registro de incidentes para el rango de fechas
                ingresado
              </Text>
            </div>
          </Card>
        </Col>
      ) : incidenteTable.length === 0 ? (
        <></>
      ) : (
        <>
          <div className="p-10" id="pdf-content">
            <PageHeader title="Reporte de Incidentes Técnicos" />
            <div className="flex items-center justify-center h-full">
              <TablaReporteIncidentesTecnicos items={incidenteTable} />
            </div>
          </div>
        </>
      )}
    </div>
  );
}
