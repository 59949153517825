import React from 'react';
import { Table } from 'antd';
// import { Table, Col, Row } from 'antd';
import TablaAsistenciaReporte from './TablaAsistenciaReporte';
// import CartaMarcacion from '../../jornadaDiaria/components/CartaMarcacion';
// import CosultaResolucion from '../../../utils/CosultaResolucion';
import consultaJustificacion from '../../jornadaDiaria/funtions/consultaJustificacion';

export default function CartaAsistencia({ record }) {
  const customMessage = {
    filterSearchPlaceholder: 'Filtrar',
    filterReset: 'Limpiar',
  };

  const getRowClassName = (record) => {
    // Cambiar el color de fondo de la fila según el resultado de consultaJustificacion
    return consultaJustificacion(record) ? '' : 'red-row';
  };

  /*   const expandedRowRender = (datos) => {
    return (
      <div>
        <Row gutter={20}>
          <Col
            span={2}
            hidden={CosultaResolucion(window.outerWidth) || false}
          />
          <CartaMarcacion
            items={datos?.marcaje?.entrada || null}
            title="Entrada"
          />
          <CartaMarcacion
            items={datos?.marcaje?.justificacionEntrada || null}
            title="Justificación Entrada"
          />
          <Col
            span={2}
            hidden={CosultaResolucion(window.outerWidth) || false}
          />
        </Row>
        <Row gutter={20} style={{ marginTop: '16px' }}>
          <Col
            span={2}
            hidden={CosultaResolucion(window.outerWidth) || false}
          />
          <CartaMarcacion
            items={datos?.marcaje?.salida || null}
            title="Salida"
          />
          <CartaMarcacion
            items={datos?.marcaje?.justificacionSalida || null}
            title="Justificación Salida"
          />
          <Col
            span={2}
            hidden={CosultaResolucion(window.outerWidth) || false}
          />
        </Row>
      </div>
    );
  }; */

  return (
    <div className="mx-auto mt-1 mb-6">
      <TablaAsistenciaReporte items={record} />
    </div>
  );
}
