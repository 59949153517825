import React from "react";
import { Card, Image, Spin } from "antd";
import PageHeader from "../../components/headers/PageHeader";
import logoApp from "../../assets/Xinerlink.png";
import ConfirmNewPasswordForm from "./components/ConfirmNewPasswordForm";
import { useParams } from "react-router-dom";

export default function ConfirmNewPasswordPage() {
  const { email } = useParams();
  return (
    <div className='max-w-md mx-auto'>
      <div className='w-56 mx-auto mb-4'>
        <Image src={logoApp} preview={false} alt='Logo App' />
      </div>
      <Card>
        <PageHeader
          children={""}
          head='Plataforma para la dirección de trabajo'
          title='Confirmar contraseña'
        />
        <ConfirmNewPasswordForm key={"email"} email={email} />
      </Card>
    </div>
  );
}
