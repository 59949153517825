import React, { useEffect, useState } from 'react';
import { Card, Image, Button, message, Spin, Typography } from 'antd';
import CosultaResolucion from '../../utils/CosultaResolucion';
import PageHeader from '../../components/headers/PageHeader';
import dtService from '../../services/dtService';
import useAuth from '../../hooks/useAuth';
import logoApp from '../../assets/Xinerlink.png';


const { Paragraph } = Typography;

export default function LoginSelector() {
  const { setCurrentUserRole, loginUser, setUserDefault, userDefault } = useAuth();


  const [isSubmit, setIsSubmit] = useState(false);
  const [isIpLoading, setIsIpLoading] = useState(true);
  const [isValidatedIp, setIsValidatedIp] = useState(false);

  /*  const [responseIP, setResponseIP] = useState({
     exist: false,
     email: '',
     password: '',
   }); */

  const cambiarRol = (item) => {
    setCurrentUserRole(item);
  };

  const handleLogin = (loginType) => {
    cambiarRol(loginType);
  };



  /*   const requestIP = async () => {
      try {
  
        const [res, err] = await dtService.ValidationIP();
  
        if (err) {
          console.error(err);
        } else {
  
          setUserDefault({
            username: res?.data?.message?.email,
            password: res?.data?.message?.password,
          });
  
          setResponseIP({
            ...responseIP,
            exist: res?.data?.message?.exist,
            email: res?.data?.message?.email,
            password: res?.data?.message?.password,
          });
        }
  
        setTimeout(() => {
          if (res?.data?.message?.exist) {
            setIsValidatedIp(true);
          }
          setIsIpLoading(false);
        }, 500);
  
  
      } catch (error) {
        message.error('Error validando dirección IP');
        console.error(error);
      }
    }; */

  const loadingMessage = (
    /** @type {string} */ message,
  ) => {
    return (
      <div className="flex justify-center p-10 text-center flex-col items-center">
        <Spin />
        <Paragraph className="mt-2">{message}</Paragraph>
      </div>
    );
  };

  /*   useEffect(() => {
      isIpLoading && requestIP();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); */

  /* const handleLoginUserDT = async () => {

    await authUser(userDefault.username, userDefault.password);

    //  loginUser({
    // username: userDefaultDt.username,
    // password: userDefaultDt.password,
    // }); 
    setCurrentUserRole("dt");
  }; */

  /*  const authUser = async (username, password) => {
     let passwordDecrypted;
     setIsSubmit(true);
     try {
       const [res, err] = await dtService.AuthenticateUser(password);
       if (err) {
         message.error('Error autenticando usuario');
         console.error(err);
       }
 
       passwordDecrypted = res?.data?.message?.registro?.password;
       const role = 'dt'
       await loginUser({
         username: username,
         password: passwordDecrypted,
         typeRole: role
       });
 
       // navigate('/');
       setIsSubmit(false);
     } catch (error) {
       message.error('Error autenticando usuario');
       console.error(error);
       setIsSubmit(false);
     }
   }; */

  return <div>
    <div className="w-56 mx-auto mb-4 ">
      <Image src={logoApp} preview={false} alt="Logo App" />
    </div>
    <div className="site-card-wrapper">

      <Card >
        {/* <div className="flex justify-end mb-4">
          <Button
            type="primary"
            className="mr-4 mt-4"
            onClick={() => handleLoginUserDT()}
            style={{ display: isSubmit ? 'none' : isValidatedIp ? 'block' : 'none' }} >
            Fiscalización DT
          </Button>
        </div> */}
        <Card>

          {
            /* responseIP.exist && isIpLoading ? (
              loadingMessage('Autenticando...')
            ) : isIpLoading ? (
              loadingMessage('Validando dirección IP...')
            ) : ( */
            /* isSubmit ? (
              <>
                <PageHeader
                  children={''}
                  head="Plataforma para la dirección de trabajo"
                  title="Ingresando como Fiscalizador DT"
                />
                {loadingMessage('Ingresando...')}
              </>
            ) : */ (
              CosultaResolucion(window.outerWidth) ? (
                <>
                  <PageHeader
                    children={''}
                    head="Plataforma para la dirección de trabajo"
                    title="Selecciona el rol para comenzar"
                  />
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Button
                      type="primary"
                      onClick={() => handleLogin('dt')}
                      className="mb-2 w-full"
                      style={{ display: !isValidatedIp ? 'block' : 'none' }}
                    >
                      Solicitar clave DT
                    </Button>
                    <Button type="primary" onClick={() => handleLogin('empresa')} className="mb-2 w-full">
                      Empresa
                    </Button>
                    <Button type="primary" onClick={() => handleLogin('trabajador')} className="mb-2 w-full">
                      Trabajador
                    </Button>
                    <Button type="primary" onClick={() => handleLogin('est')} className="w-full">
                      EST / Subcontratistas
                    </Button>
                  </div>
                </>
              ) : (
                <>
                  <PageHeader
                    children={''}
                    head="Plataforma para la dirección de trabajo"
                    title="Selecciona el rol para comenzar"
                  />
                  <div style={{ display: 'flex', justifyContent: 'center', gap: '10px' }}>
                    <Button type="primary" onClick={() => handleLogin('dt')} style={{ display: !isValidatedIp ? 'block' : 'none' }}>
                      Solicitar clave DT
                    </Button>
                    <Button type="primary" onClick={() => handleLogin('empresa')}>
                      Empresa
                    </Button>
                    <Button type="primary" onClick={() => handleLogin('trabajador')}>
                      Trabajador
                    </Button>
                    <Button type="primary" onClick={() => handleLogin('est')}>
                      EST / Subcontratistas
                    </Button>
                  </div>
                </>
              )
            )
            // )
          }
          {isValidatedIp && !isSubmit ? <div className="text-center mt-4" style={{ marginTop: '40px' }}>
            <Paragraph >
              Recuerda que para acceder al sistema como Fiscalizador, debes utilizar el botón 'Fiscalización DT'.
            </Paragraph>
          </div> : <></>}
        </Card>
      </Card>
    </div >
  </div >
}