import { useState } from 'react';
import PageHeaderReportes from '../../components/headers/PageHeaderReportes';
import BuscadorPorHash from '../../components/buscador/BuscadorPorHash';
import { Typography } from 'antd';
import { Card, Row, Col, Image } from 'antd';
import TablaHash from './components/TablaHash';
import CartaHash from './components/CartaHash';
import CosultaResolucion from '../../utils/CosultaResolucion';
import svgDocumentos from '../../assets/document.svg';

const { Text } = Typography;

export default function ValidadorHash() {
  const [hashTable, setHashTable] = useState([]);
  const [hashAreLoading, setHashAreLoading] = useState(true);

  return (
    <div>
      <PageHeaderReportes head="" title="Validador de Hash" />
      <BuscadorPorHash
        hashAreLoading={hashAreLoading}
        setHashAreLoading={setHashAreLoading}
        setHashTable={setHashTable}
      />
      {hashAreLoading ? (
        <div />
      ) : !hashTable ? (
        <Col span={10} offset={6} style={{ marginTop: '20px' }}>
          <Card
            title={'Marca'}
            bordered={false}
            headStyle={{ textAlign: 'center' }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
              }}>
              <Image
                justify="center"
                align="middle"
                width={100}
                height={50}
                src={svgDocumentos}
                preview={false}
                style={{ marginBottom: '10px' }}
              />

              <Text justify="center" align="middle">
                El Hash no existe en los registros de marcación
              </Text>
            </div>
          </Card>
        </Col>
      ) : (
        <>
          <TablaHash record={hashTable} />
          {console.log(hashTable[0])}
          <Row gutter={20}>
            <Col
              span={2}
              hidden={CosultaResolucion(window.outerWidth) || false}
            />
            {hashTable[0].reporte?.marcaje?.entrada ? (
              <>
                <CartaHash
                  marca={hashTable[0].reporte?.marcaje?.entrada || null}
                  title="Entrada"
                  justificacion={
                    hashTable[0].reporte?.marcaje?.justificacionEntrada || null
                  }
                />
              </>
            ) : (
              <>
                <CartaHash
                  marca={hashTable[0].reporte?.marcaje?.salida || null}
                  title="Salida"
                  justificacion={
                    hashTable[0].reporte?.marcaje?.justificacionSalida || null
                  }
                />
              </>
            )}
            <Col
              span={2}
              hidden={CosultaResolucion(window.outerWidth) || false}
            />
          </Row>
          {/*  <CartaHash
            items={firstTable[0].reporte?.marcaje?.entrada || null}
            title="Entrada"
          />
          <CartaHash
            items={firstTable[0].reporte?.marcaje?.justificacionEntrada || null}
            title="Justificación Entrada"
          />
          <CartaHash
            items={firstTable[0].reporte?.marcaje?.salida || null}
            title="Salida"
          />
          <CartaHash
            items={firstTable[0].reporte?.marcaje?.justificacionSalida || null} 
          title="Justificación Salida" />*/}
        </>
      )}
    </div>
  );
}
