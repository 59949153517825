const customFetch = async (callback) => {
    try {
        const res = await callback();
        return [res, null];
    } catch (e) {
        console.log("🚀 error", e);
        return [null, e];
    }
};

export default customFetch;
