import React, { useCallback, useEffect } from 'react';
import { utils, writeFileXLSX } from 'xlsx';
import styles from './components/jsonStyles.json';
import FormatDateV2 from '../../utils/FormatDateV2';
import sumarRestarHorasExtraordinarias from '../../utils/sumarRestarHorasExtraordinarias';
import dayjs from 'dayjs';

export default function BotonExportarExcel({
  name = 'jornada',
  formato = 'jornada',
  dataArray = [],
  setexportarExcel,
}) {
  const exportFile = useCallback(() => {
    const table = document.getElementById('my-table');

    let workbook = utils.book_new();
    let worksheet = utils.table_to_sheet(table);
    const nameFIle = `${name}.xlsx`;

    utils.book_append_sheet(workbook, worksheet, 'Datos');
    const wb = utils.table_to_book(table);
    writeFileXLSX(wb, nameFIle);

    setexportarExcel(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // let filter = { validadoXEST: { eq: true } };
    exportFile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (formato === 'jornada') {
    const cacularTotal = (record) => {
      const arrayreportesemana = [];

      if (record.length !== 0) {
        record.forEach((item) => {
          const nexElement = item;

          if (
            item.jornadaReal.entrada !== null &&
            item.jornadaReal.salida !== null
          ) {
            let h1 = item.jornadaReal.salida.split('T')[1].trim();
            let h2 = item.jornadaReal.entrada.split('T')[1].trim();

            let totalHora = sumarRestarHorasExtraordinarias.restarHoras(h1, h2);

            if (totalHora.charAt(0) === '-') {
              nexElement.diferenciaHoras =
                sumarRestarHorasExtraordinarias.sumarHoras(
                  totalHora,
                  '23:59:99'
                );
            } else {
              nexElement.diferenciaHoras = totalHora;
            }
          } else {
            nexElement.diferenciaHoras = '00:00:00';
          }

          arrayreportesemana.push(nexElement);
        });
      }

      const totalDiferenciasHoras =
        sumarRestarHorasExtraordinarias.sumarRestarHorasExtraordinariasArray(
          arrayreportesemana
        );

      let totalSuma = '00:00:00';
      let [h1, m1, s1] = totalDiferenciasHoras.split(':').map(Number);

      if (h1 >= 45) {
        h1 = h1 - 45;

        totalSuma = sumarRestarHorasExtraordinarias.formatoHora(h1, m1, s1);
        // console.log(sumarRestarHorasExtraordinarias.formatoHora(h1, m1, s1));
      }

      return totalSuma;
    };

    return (
      <div style={{ display: 'none' }}>
        <table id="my-table" style={styles.table}>
          {dataArray.map((item) => (
            <div key={item.key} className="contenedor">
              <table style={styles.table}>
                <tr>
                  <th />
                  <th />
                  <th />
                  <th />
                </tr>
                <tr
                  style={{
                    backgroundColor: '#C1CCAB',
                  }}
                >
                  <th>
                    {' '}
                    <ul> Nombre Trabajador</ul>
                  </th>
                  <th>
                    <ul>Rut</ul>
                  </th>

                  <th>
                    {' '}
                    <ul> Cargo</ul>
                  </th>
                  <th>
                    <ul>Empresa</ul>
                  </th>
                </tr>
                <tr
                  style={{
                    backgroundColor: '#C1CCAB',
                  }}
                >
                  <th>
                    {' '}
                    <ul>
                      {item.nombres} {item.primerApellido}{' '}
                      {item.segundoApellido}
                    </ul>
                  </th>
                  <th>
                    <ul> {item.rut}</ul>
                  </th>

                  <th>
                    {' '}
                    <ul> {item.cargo}</ul>
                  </th>
                  <th>
                    <ul>{item.empresaRut}</ul>
                  </th>
                </tr>
                <tr
                  style={{
                    backgroundColor: 'DARKblue',
                    color: 'white',
                    border: '1px solid',
                  }}
                >
                  <th style={styles.ts2}>Fecha</th>
                  <th style={styles.ts2}>Jornada</th>
                  <th style={styles.ts2}>Jornada Real</th>
                  <th style={styles.ts2}>Colación</th>
                  <th style={styles.ts2}>Colación Real</th>
                  <th style={{ border: '1px solid', width: '20%' }}>
                    Anomalias
                  </th>
                  <th style={styles.ts2}>Horas Extraordinarias</th>
                  <th style={styles.ts2}>Observaciones</th>
                </tr>
                {item.reporte.map((task, index) => (
                  <>
                    {task.map((datos, index) => (
                      <tr
                        key={datos.jornada}
                        style={{ border: '1px solid', height: '50px' }}
                      >
                        <td style={styles.ts2}>
                          {dayjs(datos.key).format('DD/MM/YYYY')}
                        </td>
                        <td style={styles.ts2}>
                          <div>
                            {datos.jornada !== null ? (
                              <p>
                                {FormatDateV2(datos.jornada.entrada, 'jornada')
                                  .split('T')[1]
                                  .slice(0, 5)}{' '}
                                -{' '}
                                {FormatDateV2(datos.jornada.salida, 'jornada')
                                  .split('T')[1]
                                  .slice(0, 5)}
                              </p>
                            ) : (
                              <p> - </p>
                            )}
                          </div>
                        </td>
                        <td style={styles.ts2}>
                          {' '}
                          <div>
                            <p>
                              {
                                FormatDateV2(
                                  datos.jornadaReal.entrada,
                                  'jornada'
                                ).split('T')[1]
                              }{' '}
                              -{' '}
                              {
                                FormatDateV2(
                                  datos.jornadaReal.salida,
                                  'jornada'
                                ).split('T')[1]
                              }
                            </p>
                          </div>
                        </td>
                        <td style={styles.ts2}>{datos.colacion || ''}</td>
                        <td style={styles.ts2}> {datos.colacionReal || ''}</td>
                        <td style={styles.ts2}>
                          {' '}
                          <div>
                            <p>
                              Ingreso:
                              {
                                FormatDateV2(datos.anomalias.entrada).split(
                                  'T'
                                )[1]
                              }
                            </p>

                            <p>
                              Salida:
                              {
                                FormatDateV2(datos.anomalias.salida).split(
                                  'T'
                                )[1]
                              }
                            </p>
                          </div>
                        </td>
                        <td style={styles.ts2}>
                          {datos.horasExtraordinarias || ''}
                        </td>

                        <td style={styles.ts2}>{datos.comentarios || ''}</td>
                      </tr>
                    ))}
                    <tr style={{ height: '60px' }}>
                      <td colspan="6" style={styles.tdtotales}>
                        Total Horas Extraordinarias Semanales:
                      </td>
                      <td colspan="2" style={styles.td2}>
                        {cacularTotal(task)}
                      </td>
                    </tr>
                    <tr style={{ height: '60px' }}>
                      <td colspan="6" style={styles.tdtotales}>
                        Compensación:
                      </td>
                      <td colspan="2" style={styles.td2}>
                        {cacularTotal(task) === '00:00:00' ? <>No</> : <>Si</>}
                      </td>
                    </tr>
                    <tr>
                      <td />
                    </tr>
                  </>
                ))}
              </table>
            </div>
          ))}
        </table>
      </div>
    );
  } else if (formato === 'asistencia') {
    return (
      <div style={{ display: 'none' }}>
        <table id="my-table" style={styles.table}>
          {dataArray.map((item) => (
            <div key={item.key} className="contenedor">
              <table style={styles.table}>
                <tr>
                  <th />
                  <th />
                  <th />
                  <th />
                </tr>

                <tr >
                  <th style={styles.td2} >Razón Social del Empleador</th>
                  <th style={styles.td2}>
                    <ul>
                      {item.empresaRut.razonSocial}
                    </ul>
                  </th>
                </tr>

                <tr style={styles.td2}>
                  <th style={styles.td2}>Rut Empleador</th>
                  <th style={styles.td2}>
                    <ul>{item.empresaRut.rutEmpresa}</ul>
                  </th>
                </tr>

                <tr style={styles.td2}>
                  <th style={styles.td2}>Nombre del Trabajador</th>
                  <th style={styles.td2}>
                    <ul>{item.nombres} {item.primerApellido} {item.segundoApellido}</ul>
                  </th>
                </tr>

                <tr style={styles.td2} >
                  <th style={styles.td2}>Rut</th>
                  <th style={styles.td2}>
                    <ul>{item.rut}</ul>
                  </th>
                </tr>

                <tr>
                  <th style={styles.td2}>Cargo</th>
                  <th>
                    <ul style={styles.td2}>{item.cargo}</ul>
                  </th>

                </tr>

                <tr>
                  <th style={styles.td2}>Lugar de prestación de los Servicios</th>
                  <th>
                    <ul style={styles.td2}>{item.ubicacion}</ul>
                  </th>
                </tr>

                <tr>
                  <th style={styles.td2}>Periodo Consultado del</th>
                  <th style={styles.td2}>
                    <ul>{dayjs(item.fechaConsultaReporte.desde).format('DD/MM/YY')} al
                      {dayjs(item.fechaConsultaReporte.hasta).format('DD/MM/YY')}</ul>
                  </th>
                </tr>


                <tr
                  style={{
                    backgroundColor: ' #C1CCAB',
                  }}
                >

                </tr>
                <tr
                  style={{
                    backgroundColor: 'DARKblue',
                    color: 'white',
                    border: '1px solid',
                  }}
                >
                  <th style={styles.ts2}>Fecha</th>
                  <th style={styles.ts2}>Asistencia</th>
                  <th style={styles.ts2}>Ausencia</th>
                  <th style={styles.ts2}>Observaciones</th>
                </tr>
                {item.reporte.map((task) => (
                  <tr
                    key={task.jornada}
                    style={{
                      border: '1px solid',
                      height: '50px',
                      padding: '2px',
                    }}
                  >
                    <td style={styles.ts2}>
                      {dayjs(task.key).format('DD/MM/YYYY')}
                    </td>
                    <td style={styles.ts2}>{task.asistencia || ''}</td>
                    <td style={styles.ts2}> {task.ausencia || ''}</td>

                    <td style={styles.ts2}>{task.observaciones || ''}</td>
                  </tr>
                ))}
              </table>
            </div>
          ))}
        </table>
      </div>
    );
  } else if (formato === 'festivos') {
    function calcularSumaLongitudArrays(arrayDeArrays) {
      let sumaLongitud = 0;

      for (let i = 0; i < arrayDeArrays.length; i++) {
        const arrayActual = arrayDeArrays[i];

        if (Array.isArray(arrayActual)) {
          const asistencias = arrayActual.filter(
            (item) => item.asistencia === true
          );
          sumaLongitud += asistencias.length;
        }
      }

      return sumaLongitud;
    }

    return (
      <div style={{ display: 'none' }}>
        <table id="my-table" style={styles.table}>
          {dataArray.map((item) => (
            <div key={item.key} className="contenedor">
              <table style={styles.table}>
                <tr>
                  <th />
                  <th />
                  <th />
                  <th />
                </tr>
                <tr
                  style={{
                    backgroundColor: ' #C1CCAB',
                  }}
                >
                  <th>
                    {' '}
                    <ul> Nombre Trabajador</ul>
                  </th>
                  <th>
                    <ul>Rut</ul>
                  </th>

                  <th>
                    {' '}
                    <ul> Cargo</ul>
                  </th>
                  <th>
                    <ul>Empresa</ul>
                  </th>
                </tr>
                <tr
                  style={{
                    backgroundColor: ' #C1CCAB',
                  }}
                >
                  <th>
                    {' '}
                    <ul>
                      {item.nombres} {item.primerApellido}{' '}
                      {item.segundoApellido}
                    </ul>
                  </th>
                  <th>
                    <ul> {item.rut}</ul>
                  </th>

                  <th>
                    {' '}
                    <ul> {item.cargo}</ul>
                  </th>
                  <th>
                    <ul>{item.empresaRut}</ul>
                  </th>
                </tr>
                {item.reporte.map((task) => (
                  <table
                    style={{
                      border: '1px solid',
                      textAlign: 'center',
                      width: '800px',
                    }}
                  >
                    <tr
                      style={{
                        backgroundColor: 'DARKblue',
                        color: 'white',
                        border: '1px solid',
                      }}
                    >
                      <th style={styles.ts2}>
                        Beneficiarios de descansos o dominicales adicionales
                      </th>
                      <th style={styles.ts2}>Domingo y/o festivos</th>
                      <th style={styles.ts2}>Asistencia</th>
                      <th style={styles.ts2}>Ausencia</th>
                      <th style={styles.ts2}>Observaciones</th>
                    </tr>
                    {task.map((data) => (
                      <tr key={task.jornada} style={styles.ts2}>
                        <td style={styles.ts2}> {data.beneficiario || ''}</td>
                        <td style={styles.ts2}>
                          {dayjs(data.fecha).format('DD/MM/YYYY') || ''}
                        </td>
                        <td style={styles.ts2}>{data.asistencia || ''}</td>
                        <td style={styles.ts2}>{data.ausencia || ''}</td>
                        <td style={styles.ts2}>{data.observaciones || ''}</td>
                      </tr>
                    ))}

                    <tr>
                      <td
                        colspan="3"
                        style={{
                          border: '1px solid',
                          backgroundColor: 'DARKblue',
                          color: 'white',
                        }}
                      >
                        TOTAL DOMINGO Y FESTIVO
                      </td>

                      <td colspan="2" style={styles.ts2}>
                        {' '}
                        {task.filter((item) => item.asistencia === 'SI')
                          .length || 0}
                      </td>
                    </tr>
                  </table>
                ))}

                <table
                  style={{
                    border: '1px solid',
                    textAlign: 'center',
                    marginTop: '10px',
                  }}
                >
                  <tr>
                    <td
                      colspan="3"
                      style={{
                        border: '1px solid',
                        backgroundColor: 'DARKblue',
                        color: 'white',
                      }}
                    >
                      TOTAL DOMINGO Y FESTIVO GLOBAL:
                    </td>
                    <td colSpan="2" style={styles.ts2}>
                      {calcularSumaLongitudArrays(item.reporte)}
                    </td>
                  </tr>
                </table>
              </table>
            </div>
          ))}
        </table>
      </div>
    );
  } else if (formato === 'modificaciones') {
    return (
      <div style={{ display: 'none' }}>
        <table id="my-table" style={styles.table}>
          {dataArray.map((item) => (
            <div key={item.key} className="contenedor">
              <table style={styles.table}>
                <tr>
                  <th />
                  <th />
                  <th />
                  <th />
                </tr>
                <tr
                  style={{
                    backgroundColor: ' #C1CCAB',
                  }}
                >
                  <th>
                    {' '}
                    <ul> Nombre Trabajador</ul>
                  </th>
                  <th>
                    <ul>Rut</ul>
                  </th>

                  <th>
                    {' '}
                    <ul> Cargo</ul>
                  </th>
                  <th>
                    <ul>Empresa</ul>
                  </th>
                </tr>
                <tr
                  style={{
                    backgroundColor: ' #C1CCAB',
                  }}
                >
                  <th>
                    {' '}
                    <ul>
                      {item.nombres} {item.primerApellido}{' '}
                      {item.segundoApellido}
                    </ul>
                  </th>
                  <th>
                    <ul> {item.rut}</ul>
                  </th>

                  <th>
                    {' '}
                    <ul> {item.cargo}</ul>
                  </th>
                  <th>
                    <ul>{item.empresaRut}</ul>
                  </th>
                </tr>
                <tr
                  style={{
                    backgroundColor: 'DARKblue',
                    color: 'white',
                    border: '1px solid',
                    height: 'auto',
                  }}
                >
                  <th style={styles.ts2}>Fecha Asignacion de Turno</th>
                  <th style={styles.ts2}>Hora turno</th>
                  <th style={styles.ts2}>Frecuencia (Tipo de turno)</th>
                  <th style={styles.ts2}>Fecha Asignacion de Nuevo turno</th>
                  <th style={styles.ts2}>Horario Nuevo Turno</th>
                  <th style={styles.ts2}>Solicitado</th>
                </tr>
                {item.reporte.map((task) => (
                  <tr
                    key={task.jornada}
                    style={{
                      border: '1px solid',
                      height: '50px',
                      padding: '2px',
                    }}
                  >
                    <td style={styles.ts2}>
                      {' '}
                      {FormatDateV2(task.fechaAsignacion, 'excel')}
                    </td>
                    <td style={styles.ts2}>
                      <div>
                        <p>
                          {' '}
                          {FormatDateV2(
                            task.jornadaAsignacion.entrada,
                            'jornada'
                          )
                            .split('T')[1]
                            .slice(0, 5)}{' '}
                          -{' '}
                          {FormatDateV2(
                            task.jornadaAsignacion.salida,
                            'jornada'
                          )
                            .split('T')[1]
                            .slice(0, 5)}
                        </p>
                      </div>
                    </td>
                    <td style={styles.ts2}>{task.frecuencia || ''}</td>
                    <td style={styles.ts2}>
                      {' '}
                      {FormatDateV2(task.fechaNuevaAsignacion, 'excel')}
                    </td>
                    <td style={styles.ts2}>
                      <div>
                        <p>
                          {' '}
                          {FormatDateV2(
                            task.jornadaNuevaAsignacion.entrada,
                            'jornada'
                          )
                            .split('T')[1]
                            .slice(0, 5)}{' '}
                          -{' '}
                          {FormatDateV2(
                            task.jornadaNuevaAsignacion.salida,
                            'jornada'
                          )
                            .split('T')[1]
                            .slice(0, 5)}
                        </p>
                      </div>
                    </td>
                    <td style={styles.ts2}>{task.solicitado || ''}</td>
                  </tr>
                ))}
              </table>
            </div>
          ))}
        </table>
      </div>
    );
  } else <div style={{ display: 'none' }}></div>;
}
