import React, { useState } from "react";
import useAuth from "../../../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import { Button, Form, Input, message, InputNumber } from "antd";
import { UserOutlined, LockOutlined, UnlockOutlined } from "@ant-design/icons";
import dtService from "../../../services/dtService";

export default function ConfirmNewPasswordForm({ email }) {
  const [secondPasswordVisible, setsecondPasswordVisible] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const { confirmNewPassword } = useAuth();
  const navigate = useNavigate();

  const onFinish = async (
    /** @type {{ password: any; confirmPassword: any; code: any; }} */ values
  ) => {
    const password = values.password;
    const secondPassword = values.confirmPassword;
    const code = String(values.code);
    if (password !== secondPassword) {
      message.error("Las contraseñas no coinciden");
      return;
    }
    try {
      const requestConfirmNewPassword = await confirmNewPassword({
        email,
        code,
        password,
      });
      console.log(requestConfirmNewPassword);
      message.success("Contraseña actualizada con exito");
      await dtService.RegisterNewPassword(email, password);
      navigate("/auth");
    } catch (error) {
      message.error("Ha ocurrido un error actualizando la contraseña");
      console.error(error);
    }
    return;
  };

  return (
    <Form
      name='normal_login'
      initialValues={{
        email,
        password: "",
        confirmPassword: "",
        code: "",
      }}
      onFinish={onFinish}
      size={"large"}
    >
      <Form.Item
        name='email'
        rules={[
          {
            required: true,
            message: "Ingrese correo",
          },
        ]}
      >
        <Input
          disabled
          prefix={<UserOutlined />}
          placeholder='Nombre de usuario'
        />
      </Form.Item>
      <Form.Item
        name='password'
        rules={[
          {
            required: true,
            message: "Ingrese Contraseña",
          },
        ]}
      >
        <Input.Password
          prefix={<LockOutlined />}
          type='password'
          placeholder='Contraseña'
          visibilityToggle={{
            visible: passwordVisible,
            onVisibleChange: setPasswordVisible,
          }}
        />
      </Form.Item>
      <Form.Item
        name='confirmPassword'
        rules={[
          {
            required: true,
            message: "Confirma tu contraseña",
          },
        ]}
      >
        <Input.Password
          prefix={<LockOutlined />}
          type='password'
          placeholder='Confirma tu contraseña'
          visibilityToggle={{
            visible: secondPasswordVisible,
            onVisibleChange: setsecondPasswordVisible,
          }}
        />
      </Form.Item>
      <Form.Item
        name='code'
        rules={[
          {
            required: true,
            message: "Ingresa el código que te enviamos al correo",
          },
        ]}
      >
        <InputNumber
          prefix={<UnlockOutlined />}
          size='small'
          style={{
            width: "100%",
            fontSize: "16px",
          }}
          placeholder='000000'
          visibilityToggle={{
            visible: secondPasswordVisible,
            onVisibleChange: setsecondPasswordVisible,
          }}
        />
      </Form.Item>
      <Form.Item>
        <Button type='primary' htmlType='submit'>
          Actualizar contraseña
        </Button>
      </Form.Item>
    </Form>
  );
}
